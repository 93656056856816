import * as download from "downloadjs";
import * as Papa from "papaparse";
import * as ko from "knockout";
import * as _ from "lodash";
import * as moment from "moment";
import { DialogSize } from "components/confirmation-dialog/Model/Enums";
import { Disposable } from "common/iHostCommonInterfaces";
import GlobalEvents from "app/Models/Events/GlobalEvents";
import ViewArgs from "app/Interfaces/ViewArgs";
import DateTimeFormatter from "./DateTimeFormatter";

export class DialogHelper {
    /**
     * Gets the class name for the given dialog size.
     */
    public static getDialogSizeClass(size: DialogSize) {
        switch (size) {
            case DialogSize.Small:
                return "modal-sm";
            case DialogSize.Large:
                return "modal-lg";
            default:
                return "";
        }
    }
}

export namespace ObjectHelper {
    /**
     * Takes an object and returns its keys as an array.
     * Similar to Object.keys, but retains type information.
     * @param value The object.
     * @returns The keys as an array.
     */
    export function getKeys<T extends object>(value: T): KeyOf<T>[] {
        return Object.keys(value) as KeyOf<T>[];
    }

    /**
     * Takes an object and returns its values as an array.
     * Similar to Object.values, but works with any TypeScript target and retains type information.
     * @param value The object.
     * @returns The values as an array.
     */
    export function getValues<T extends object>(value: T): ValueOf<T>[] {
        return getKeys(value).map(key => value[key]);
    }

    /**
     * Takes an object and returns an array of tuples:
     * - The first value is the key
     * - The second value is the value
     * Similar to Object.entries, but works with any TypeScript target and retains type information.
     * @param value The object.
     * @returns The array of tuples.
     */
    export function getEntries<T extends object>(value: T): [KeyOf<T>, ValueOf<T>][] {
        return getKeys(value).map(key => [key, value[key]] as [KeyOf<T>, ValueOf<T>]);
    }
}

export type KeyOf<T> = keyof T;
export type ValueOf<T> = T[keyof T];

export namespace ArrayHelper {
    /**
     * Converts an array into an array of tuples:
     * - The first value being the original value
     * - The second value being the index
     * Useful with tuple destructuring for looping over an array when you want the index.
     * @param values The array.
     * @returns The array of tuples.
     */
    export function withIndex<T>(values: T[]): [T, number][] {
        return values.map((a, i) => [a, i] as [T, number]);
    }

    /**
     * Joins two arrays together, using a common key.
     * Very similar to the C# method Enumerable.Join
     * @param first The first array.
     * @param second The second array.
     * @param firstKeySelector A function that selects the key to use for each item in the first array.
     * @param secondKeySelector A function that selects the key to use for each item in the second array.
     * @param resultSelector A function that combines items of each array into a single result.
     * @returns The joined result array.
     */
    export function join<TFirst, TSecond, TKey extends string | number, TResult>(
        first: TFirst[],
        second: TSecond[],
        firstKeySelector: (first: TFirst) => TKey,
        secondKeySelector: (second: TSecond) => TKey,
        resultSelector: (first: TFirst, second: TSecond) => TResult
    ) {
        const keyToSecond = _.keyBy(second, x => secondKeySelector(x));
        return first
            .map(firstValue => {
                const firstKey = firstKeySelector(firstValue);
                if (firstKey in keyToSecond) {
                    return resultSelector(firstValue, keyToSecond[firstKey as string | number]);
                }
                else {
                    return null;
                }
            })
            .filter(x => x !== null);
    }

    /**
     * Converts an array into a lookup (a dictionary of arrays).
     * Very similar to the Enumerable.ToLookup method.
     * @param values The array.
     * @param keySelector A function that selects the key for each item in the array.
     * @param elementSelector A function that selects the result for each item in the array.
     * @returns The result lookup.
     */
    export function toLookup<TValue, TKey extends string | number, TElement>(
        values: TValue[],
        keySelector: (value: TValue) => TKey,
        elementSelector: (value: TValue) => TElement
    ): _.Dictionary<TElement[]>;

    /**
     * Converts an array into a lookup (a dictionary of arrays).
     * Very similar to the Enumerable.ToLookup method.
     * @param values The array.
     * @param keySelector A function that selects the key for each item in the array.
     * @returns The result lookup.
     */
    export function toLookup<TValue, TKey extends string | number>(
        values: TValue[],
        keySelector: (value: TValue) => TKey
    ): _.Dictionary<TValue[]>;

    export function toLookup<TValue, TKey extends string | number, TElement>(
        values: TValue[],
        keySelector: (value: TValue) => TKey,
        elementSelector?: (value: TValue) => TElement
    ): _.Dictionary<TElement[]> {
        if (elementSelector === void 0) {
            elementSelector = x => x as any as TElement;
        }

        const result: _.Dictionary<TElement[]> = {};
        for (const value of values) {
            const key = keySelector(value);
            const element = elementSelector(value);
            if (key in result) {
                result[key as string | number].push(element);
            }
            else {
                result[key as string | number] = [element];
            }
        }
        return result;
    }
}

export namespace KnockoutHelper {
    /**
     * Updates a view (element with knockout bindings) with new HTML and a new view model.
     * @param selectorOrView The CSS selector or element for the view model.
     * @param newHtml The new HTML, as a string, null for no HTML or undefined for the same HTML.
     * @param newViewModel The new view model, null for no view model or undefined for the same view model.
     */
    export function updateView(selectorOrView: string | Element, newHtml?: string, newViewModel?: any): void {
        // Clean existing view.
        const view = typeof selectorOrView === "string"
            ? document.querySelector(selectorOrView)
            : selectorOrView;
        if (newViewModel !== void 0 && ko.contextFor(view)) {
            ko.cleanNode(view);
        }

        // Load new view.
        if (newHtml !== void 0) {
            view.innerHTML = newHtml;
        }
        if (newViewModel !== void 0 && newViewModel !== null) {
            ko.applyBindings(newViewModel, view);
        }
    }

    export function wrap<T>(instance: T | KnockoutObservable<T>, wrapUndefined: boolean = false): KnockoutObservable<T> {
        if (typeof instance === "undefined") {
            return wrapUndefined ? ko.observable<T>(instance) : instance;
        }
        else {
            return ko.isObservable<T>(instance) ? instance : ko.observable<T>(instance);
        }
    }

    export function wrapArray<T>(instance: T[] | KnockoutObservableArray<T>, wrapUndefined: boolean = false): KnockoutObservableArray<T> {
        if (typeof instance === "undefined") {
            return wrapUndefined ? ko.observableArray<T>(instance) : instance;
        }
        else {
            return ko.isObservable<T[]>(instance) ? instance : ko.observableArray<T>(instance);
        }
    }
}

export namespace PromiseHelper {
    /**
     * Wraps a promise so it throws a PromiseCancelationError if an observable notifies its subscribers before it completes.
     * @param promise The promise.
     * @param cancelationSubscribable The subscribable to watch for cancelation.
     * @returns The wrapped promise.
     */
    export async function cancelOnNotify<T>(promise: Promise<T>, cancelationSubscribable: KnockoutSubscribable<any>): Promise<T> {
        let hasCanceled: boolean = false;
        let subscription = cancelationSubscribable.subscribe(() => {
            hasCanceled = true;
            subscription?.dispose();
            subscription = null;
        });
        try {
            const result = await promise;
            if (hasCanceled)
                throw new PromiseCancelationError();
            return result;
        }
        finally {
            subscription?.dispose();
            subscription = null;
        }
    }

    export class PromiseCancelationError extends Error {
        public constructor() {
            super("Promise was canceled.");
        }
    }
}

export namespace ImageHelper {
    export const ViewableImageFormats = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];

    export function loadImage(path: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.addEventListener("load", function (this: HTMLImageElement, event: Event) {
                resolve(this);
            });
            img.addEventListener("error", function (this: HTMLImageElement, event: ErrorEvent) {
                reject(event.error);
            });
            img.src = path;
        });
    }

    export function getMimeTypeFromFileExtension(extension: string): string | null {
        switch (extension?.toLowerCase()) {
            case "jpg":
            case "jpeg":
                return "image/jpeg";
            case "png":
                return "image/png";
            case "gif":
                return "image/gif";
            case "bmp":
                return "image/bmp";
            default:
                return null;
        };
    }
}

export namespace UrlHelper {
    export function getOrigin(): string {
        let origin = document.location.origin;
        // will be null in IE as origin not supported
        if (!origin) {
            origin = `${document.location.protocol}//${document.location.hostname}${(window.location.port ? ':' + window.location.port : '')}`;
        }
        return origin;
    }

    export function getPageUrl(webRootPath: string, viewName: string, hashParams: string = null, queryParams: string[] = null): string {
        return `${UrlHelper.getOrigin()}${webRootPath}/Pages/#!${viewName}/${(hashParams ? hashParams : "")}${queryParams?.length > 0 ? "?" + queryParams.join("&") : ""}`;
    }

    export function getPagesUrl(viewName: string, hashParams: string | number = null, queryParams: string[] = null): string {
        return getPageUrl(window.appSettings.webUiPath, viewName, hashParams?.toString(), queryParams);
    }
}

export namespace PathHelper {
    export const InvalidFileNameChars = [">", "<", "|", "\"", "\\", "/", "?", "*", ":"];
    export const DirectorySeparatorChar = "\\";
    export const AltDirectorySeparatorChar = "/";
    export const ParentDirectoryAlias = "..";
    export const InvalidPathChars = [">", "<", "|", "?", "*"];
    export const MaximumExtensionDotCount: number = 2;
    export const MaxExtensionPartLength: number = 5;

    export function pathValid(path: string): boolean {
        if (!_.trim(path)) {
            return false;
        }
        return InvalidPathChars.every(x => path.indexOf(x) === -1);
    }

    export function fileNameValid(fileName: string): boolean {
        return fileName &&
            fileName.trim() !== "" &&
            !InvalidFileNameChars.some(invalidChar => fileName.indexOf(invalidChar) !== -1);
    };

    export function replaceInvalidPathCharacters(path: string, replacementCharacter: string = "_"): string {
        if (!path) {
            return path;
        }

        let newPath: string = path;
        for (const invalidPathCharacter in InvalidFileNameChars) {
            newPath = path.replace(invalidPathCharacter, replacementCharacter);
        }
        return newPath;
    }

    export function combine(...paths: string[]): string {
        return _.trim(paths.join(DirectorySeparatorChar), DirectorySeparatorChar);
    }

    export function getParentDirectory(path: string): string {
        if (!path) {
            return null;
        }

        const directories = path.split(PathHelper.DirectorySeparatorChar);
        return directories.slice(0, directories.length - 1).join(DirectorySeparatorChar) || null;
    }

    // Port of the C# FileSystemHelper.GetFileExtension method.
    export function getExtension(path: string, excludeLeadingDot = true): string {
        if (path === null || path === void 0) {
            return null;
        }
        else if (path === "" || path.endsWith('.')) {
            return "";
        }

        let dotCount = 0;
        let lastDotIndex = path.length;
        let partHasAsciiLetter = false;
        for (let i = path.length - 1; i >= 0; --i) {
            const c = path[i];
            if (c === ".") {
                // Ignore part if doesn't contain alpha character as unlikely to be an extension part.
                if (dotCount > 0 && !partHasAsciiLetter)
                    break;
                ++dotCount;
                lastDotIndex = i;
                partHasAsciiLetter = false;
                // Stop processing if the new dot count is too large.
                if (dotCount >= MaximumExtensionDotCount)
                    break;
            }
            // Ignore part if contains any non-alphanumeric characters as unlikely to be an extension part.
            else if (!isAsciiLetterOrDigit(c)) {
                break;
            }
            else {
                if (isAsciiLetter(c))
                    partHasAsciiLetter = true;
                const partLength = lastDotIndex - i;
                // Stop processing if the current extension part is too long.
                if (partLength > MaxExtensionPartLength)
                    break;
            }
        }

        // Return "" if there is no extension
        if (lastDotIndex === path.length)
            return "";

        return excludeLeadingDot
            ? path.substring(lastDotIndex + 1)
            : path.substring(lastDotIndex);
    }

    export function getFilename(path: string): string {
        if (path === null || path === void 0) {
            return null;
        }

        const index = Math.max(path.lastIndexOf(DirectorySeparatorChar), path.lastIndexOf(AltDirectorySeparatorChar));
        if (index > -1) {
            return path.substring(index + 1);
        }

        return path;
    }

    // Port of the C# FileSystemHelper.GetFileNameWithoutExtension method.
    export function getFilenameWithoutExtension(path: string): string {
        if (path === null || path === void 0) {
            return null;
        }
        else if (path === "") {
            return "";
        }

        const extension = getExtension(path, false);
        return path.substring(0, path.length - extension.length);
    }

    export function isAsciiLetterOrDigit(c: string): boolean {
        return isAsciiLetter(c) || (c >= "0" && c <= "9");
    }

    export function isAsciiLetter(c: string): boolean {
        return (c >= "a" && c <= "z") || (c >= "A" && c <= "Z");
    }
}

export namespace IconHelper {
    export function getFaIconClassFromFileExtension(fileExtension: string): string {
        switch (_.last(fileExtension?.split('.'))?.toLowerCase()) {
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
            case "bmp":
                return "fa-file-image-o"
            case "txt":
            case "json":
            case "xml":
            case "csv":
                return "fa-file-text-o"
            case "zip":
            case "rar":
            case "7z":
            case "gz":
            case "tgz":
                return "fa-file-archive-o"
            case "pdf":
                return "fa-file-pdf-o";
            case "doc":
            case "docx":
                return "fa-file-word-o";
            case "pptx":
            case "ppt":
                return "fa-file-powerpoint-o";
            case "xls":
            case "xlsx":
                return "fa-file-excel-o";
            default:
                return "fa-file-o";
        }
    }
}

export namespace BytesHelper {
    /**
     * Format bytes as a string e.g. (1024) => "1 KB", (1048576) => "1 MB"
     */
    export function formatBytes(bytes: number, decimals = 2): string {
        // source https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Convert bytes to kilobytes.
     */
    export function convertToKb(bytes: number): number {
        return parseFloat((bytes / 1024).toFixed(2));
    }

    /**
     * Convert bytes to megabytes.
     */
    export function convertToMb(bytes: number): number {
        return parseFloat(((bytes / 1024) / 1024).toFixed(2));
    }

    /**
     * Convert bytes to gigabytes.
     */
    export function convertToGb(bytes: number): number {
        return parseFloat(((bytes / 1024) / 1024 / 1024).toFixed(2));
    }
}

export namespace MathHelper {
    /**
     * Converts degrees to radians.
     */
    export function degreesToRadians(value: number) {
        return value * (Math.PI / 180);
    }

    /**
     * Converts radians to degrees.
     */
    export function radiansToDegrees(value: number) {
        return value * (180 / Math.PI);
    }

    /*
     * Rotates a vector.
     * See https://en.wikipedia.org/wiki/Rotation_matrix#In_two_dimensions for more information.
     */
    export function rotateVector(vector: [number, number], radians: number): [number, number] {
        const sinRadians = Math.sin(radians);
        const cosRadians = Math.cos(radians);
        return [
            // x' = (x * cos(θ)) − (y * sin(θ))
            (vector[0] * cosRadians) - (vector[1] * sinRadians),
            // y' = (x * sin(θ)) + (y * cos(θ))
            (vector[0] * sinRadians) + (vector[1] * cosRadians)
        ]
    }
}

export namespace DownloadHelper {
    // cannot read xhr.responseText when requesting a blob, which contains the error message when download fails
    export interface DownloadError {
        xhr: XMLHttpRequest;
        responseText?: string;
    };

    export function downloadFromUrl(url: string, filename?: string, mimeType = "application/octet-stream"): Promise<any> {
        const getFilename = (xhr: XMLHttpRequest, filename?: string): string | undefined => {
            if (filename) {
                return filename;
            }
            const match = xhr.getResponseHeader("Content-Disposition")?.match(/filename\*?=(?:(?:"((?:\\"|[^"])*)")|([^;]*))/);
            let decodedFilename;
            if (match) {
                const encodedFilename = match[1] || match[2];
                if (encodedFilename.startsWith("=?utf-8?B?") && encodedFilename.endsWith("?=")) {
                    const base64Text = encodedFilename.substring(10, encodedFilename.length - 2);
                    const binaryData = Uint8Array.from(window.atob(base64Text), c => c.charCodeAt(0));
                    const decoder = new TextDecoder("utf-8");
                    decodedFilename = decoder.decode(binaryData);
                }
                else {
                    decodedFilename = encodedFilename;
                }
            }

            return decodedFilename;
        };

        return new Promise<void>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";

            xhr.onload = () => {
                if (xhr.status === 200) {
                    download(xhr.response, getFilename(xhr, filename), mimeType);
                    resolve();
                } else {
                    const fileReader = new FileReader();
                    fileReader.onload = () => reject(<DownloadError>{ xhr, responseText: fileReader.result as string });
                    fileReader.onerror = () => reject(<DownloadError>{ xhr });
                    fileReader.onabort = () => reject(<DownloadError>{ xhr });
                    fileReader.readAsText(xhr.response);
                }
            };
            xhr.onerror = () => {
                reject(<DownloadError>{ xhr });
            };
            xhr.send();
        });
    }

    export function downloadBlobTextFromUrl(url: string, readAsBase64: boolean = false): Promise<string> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";

            xhr.onload = () => {
                if (xhr.status == 200) {
                    const fileReader = new FileReader();
                    fileReader.onload = () => resolve(fileReader.result as string);
                    fileReader.onerror = () => reject(<DownloadError>{ xhr });
                    fileReader.onabort = () => reject(<DownloadError>{ xhr });
                    if (readAsBase64) {
                        fileReader.readAsDataURL(xhr.response);
                    }
                    else {
                        fileReader.readAsText(xhr.response);
                    }
                } else {
                    const fileReader = new FileReader();
                    fileReader.onload = () => reject(<DownloadError>{ xhr, responseText: fileReader.result as string });
                    fileReader.onerror = () => reject(<DownloadError>{ xhr });
                    fileReader.onabort = () => reject(<DownloadError>{ xhr });
                    fileReader.readAsText(xhr.response);
                }
            };
            xhr.onerror = () => reject(<DownloadError>{ xhr });
            xhr.onabort = () => reject(<DownloadError>{ xhr });
            xhr.send();
        });
    }

    /**
     * Forces a URL that returns inline content to be downloaded instead.
     * Filename is taken from the Content-Disposition header.
     * For IE11, will still open inline but in a new window.
     */
    export function forceInlineDownload(url: string): void {
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "";
        anchor.target = "_blank"; // Fallback to opening in new window.
        anchor.style.display = "none";
        document.head.appendChild(anchor);
        try {
            anchor.click();
        }
        finally {
            document.head.removeChild(anchor);
        }
    }

    export function createFile(filename: string, content: string | Blob, lastModified?: number, type = "text/plain"): File {
        try {
            return new File([content], filename, { lastModified: lastModified, type: type });
        }
        catch {
            // IE/Edge does not support File constructor but supports blob.
            // Manually set name property so it matches a File.
            const file = new Blob([content], { type: type }) as File;
            (file as any).name = filename;
            (file as any).lastModified = lastModified;
            return file;
        }
    }

    export function renameFile(file: File, newName: string): File {
        try {
            return new File([file], newName, { lastModified: file.lastModified, type: file.type });
        }
        catch {
            // IE/Edge does not support File constructor but supports blob.
            // Manually set name property so it matches a File.
            const newFile = new Blob([file], { type: file.type }) as File;
            (newFile as any).name = newName;
            (newFile as any).lastModified = file.lastModified;
            return newFile;
        }
    }
}

export namespace RtuUserDataHelper {
    export function getNominalVoltageDescr(nominalVoltage: number): { voltage: number; descr: string } {
        if (nominalVoltage === -1)
            return { voltage: nominalVoltage, descr: "Unknown" };
        else if (nominalVoltage < 1000)
            return { voltage: nominalVoltage, descr: `${nominalVoltage} V` };
        else if (nominalVoltage < 1000000)
            return { voltage: nominalVoltage, descr: `${nominalVoltage / 1000} kV` };
        else
            return { voltage: nominalVoltage, descr: `${nominalVoltage / 1000000} MV` };
    }
}

export namespace JQueryHelper {
    export function isXHR(value: any): value is JQueryXHR {
        return typeof value === "object" &&
            typeof value.getAllResponseHeaders === "function" &&
            typeof value.then === "function";
    }
}

export namespace CsvHelper {
    export function downloadCsv(filename: string, headers: any[], data: any[][]): void {
        download(Papa.unparse({
            "fields": headers,
            "data": data
        }), PathHelper.replaceInvalidPathCharacters(filename), "text/csv");
    }
}

export namespace BrowserHelper {
    export function isUnsupportedBrowser(): boolean {
        return window.navigator.userAgent.indexOf('MSIE 6') !== -1 ||
            window.navigator.userAgent.indexOf('MSIE 7') !== -1 ||
            window.navigator.userAgent.indexOf('MSIE 8') !== -1 ||
            window.navigator.userAgent.indexOf('MSIE 9') !== -1 ||
            window.navigator.userAgent.indexOf('MSIE 10') !== -1;
    }

    export function isIEBrowser(): boolean {
        return window.navigator.userAgent.indexOf('MSIE ') !== -1 || window.navigator.userAgent.indexOf('Trident/') !== -1;
    }
}

export namespace DisposeHelper {
    /**
     * Disposes all of the specified objects while swallowing errors.
     * @param disposables The objects to be disposed.
     */
    export function dispose(...disposables: Disposable[] | Disposable[][]): void {
        for (const disposable of _.flattenDeep(disposables)) {
            if (disposable != null) {
                try {
                    disposable.dispose();
                }
                catch { }
            }
        }
    }
}

export namespace ModalHelper {
    export function hideModal(selector: string) {
        $(selector).modal('hide');
        $('.modal-backdrop').remove();
        $(document.body).removeClass("modal-open");
        $(document.body).css({ "padding-right": "" });
    }
}

export namespace RequestHelper {
    /**
     * Performs a request (followed by any success-only logic) using our standard request boilerplate.
     * This will handle displaying the refresh spinner and any request errors.
     * @param callback The request to invoke (followed by any success-only logic).
     * @param args The arguments for the current view.
     * @param options.refreshView Whether to refresh the view on success (default false).
     * @return The value fetched by the request.
     */
    export async function request<T>(callback: () => Promise<T>, args: ViewArgs, options?: { refreshView?: boolean; }): Promise<T> {
        GlobalEvents.refreshing();
        try {
            const result = await callback();
            args.clearError();
            if (options?.refreshView) {
                args.refreshData();
            }
            return result;
        }
        catch (err) {
            args.setError(err);
            return undefined;
        }
        finally {
            GlobalEvents.refreshing(false);
        }
    }

    /**
     * Performs a request (and any success logic) using our standard request boilerplate for a modal dialog.
     * This will handle displaying the refresh spinner and any request errors.
     * @param callback The request to invoke (followed by any success-only logic).
     * @param args The arguments for the current view.
     * @param dialogArgs The arguments for the dialog.
     * @param options.dialogButtonSelector The optional CSS selector for the clicked button that caused the request.
     * @param options.refreshView Whether to refresh the view on success (default false).
     * @param options.closeDialogOnError Whether to close the dialog on error (default false).
     * @return The value fetched by the request.
     */
    export async function requestDialog<T>(
        callback: () => Promise<T>,
        args: ViewArgs,
        dialogArgs: { id: string;  errorText: KnockoutObservable<string> },
        options?: {
            dialogButtonSelector?: string;
            dialogButtonLoadingText?: string;
            refreshView?: boolean;
            closeDialogOnError?: boolean;
            leaveDialogOpenOnSuccess?: boolean;
        }
    ): Promise<T> {
        GlobalEvents.refreshing();
        if (options?.dialogButtonSelector) {
            if (options?.dialogButtonLoadingText) {
                $(`#${dialogArgs.id} ${options.dialogButtonSelector}`).data('loading-text', options.dialogButtonLoadingText);
            }
            $(`#${dialogArgs.id} ${options.dialogButtonSelector}`).button('loading');
        }
        try {
            const result = await callback();
            args.clearError();
            dialogArgs.errorText(null);
            if (!options?.leaveDialogOpenOnSuccess) {
                $(`#${dialogArgs.id} .modal`).modal('hide');
            }
            if (options?.refreshView) {
                await args.refreshData();
            }
            return result;
        }
        catch (xhr) {
            args.setError(xhr, dialogArgs.errorText as KnockoutObservable<string | string[]>);
            if (options?.closeDialogOnError) {
                $(`#${dialogArgs.id} .modal`).modal('hide');
            }
            return undefined;
        }
        finally {
            if (options?.dialogButtonSelector) {
                $(`#${dialogArgs.id} ${options.dialogButtonSelector}`).button('reset');
            }
            GlobalEvents.refreshing(false);
        }
    }
}

export namespace NumberHelper {
    export const Int32Max = 2147483647;
    export const Int32Min = -2147483648;
    export const UInt32Max = 4294967295;
    export const UInt32Min = 0;

    /**
     * Converts a number to a default value if it is not finite.
     * @param value The number value.
     * @return The number value or the default value.
     */
    export function toFiniteOrDefault(value: number, defaultValue: number): number {
        return _.isFinite(value) ? value : defaultValue;
    }
}

export class CustomerHelper {
    public static getArchiveText(archivePeriodHours: number): string {
        return archivePeriodHours > 0 ? `Older than ${CustomerHelper.humaniseArchiveDuration(archivePeriodHours)}` : "Immediately";
    }

    public static getArchiveCompressionText(archiveCompressPeriodDays: number): string {
        return archiveCompressPeriodDays != null && archiveCompressPeriodDays > 0 ? `Compressed after ${archiveCompressPeriodDays} days` : "Compression Disabled";
    }

    public static humaniseArchiveDuration(archivePeriodHours: number): string {
        if (archivePeriodHours % 8760 === 0) {
            const years = archivePeriodHours / 8760;
            return `${years} year${years > 1 ? "s" : ""}`;
        } else if (archivePeriodHours === 720) {
            const months = archivePeriodHours / 720;
            return `${months} month${months > 1 ? "s" : ""}`;
        } else if (archivePeriodHours % 168 === 0) {
            const weeks = archivePeriodHours / 168;
            return `${weeks} week${weeks > 1 ? "s" : ""}`;
        } else {
            return DateTimeFormatter.humaniseDuration(moment.duration({ hours: archivePeriodHours }));
        }
    }

    public static splitArchiveDuration(archivePeriodHours: number): { [key: string]: string } {
        if (archivePeriodHours === 0) 
            return null;

        const durationUnits = [
            { "name": "year", "hours": 365 * 24 },
            { "name": "month", "hours": 30 * 24 },
            { "name": "week", "hours": 7 * 24 },
            { "name": "day", "hours": 24 },
            { "name": "hour", "hours": 1 }
        ];
    
        let result: { [key: string]: string } = null;

        // first see if it fits exactly into any of the units (not counting hours)
        for (const durationUnit of durationUnits) {
            if (durationUnit.name == "hour") 
                break;

            const unitCount = archivePeriodHours / durationUnit.hours;
            if (unitCount === Math.floor(unitCount)){
                if (result === null) {
                    result = {};
                }
                result[durationUnit.name] = unitCount.toString();
                break;
            }
        }
        if (result === null) {
            for (const durationUnit of durationUnits) {
                const unitCount = Math.floor(archivePeriodHours / durationUnit.hours);
                if (unitCount > 0) {
                    if (result === null) {
                        result = {};
                    }
                    result[durationUnit.name] = unitCount.toString();
                }
                archivePeriodHours -= unitCount * durationUnit.hours;
            }
        }
        return result;
    }
}
