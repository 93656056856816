export const enum Quality {
    Normal = 0,
    Offline = 1,
    Restart = 2,
    CommsLost = 4,
    RemoteForced = 8,
    LocalForced = 16,
    OverRange = 32,
    Suspect = 64,
    Invalid = 128,
}

export const enum TaskManagerStatus
{
    Online = 10,
    Offline = 20,
    Suspicious = 30,
    Unknown = 40
}

export const enum TaskModuleState
{
    Stopped = 10,
    Idle = 20,
    Running = 30,
    Suspicious = 40,
    Disabled = 50
}

export const enum PointDisplayType {
    Alarm = 10,
    Status = 20
}

export const enum CsmType{
    iHostCsm = 10,
    iHostXmlGateway = 50
}

export const enum CsmStatus{
    Online = 10,
    Offline = 20,
    Suspicious = 30,
    Unknown = 40
}

export const enum CsmLineType {
    GsmModem = 10,
    PstnModem = 20,
    TcpIpPort = 30,
    DirectSerialConnection = 40,
    UdpIpPort = 50,
    Soap = 60,
    Dnp3Master = 80,
    DialupLine = 90,
    SmsHttpGateway = 100,
    NetworkPing = 110,
    DeviceApi = 120,
    Iccp = 130,
    VigilApi = 140,
    Iec104Master = 150,
    DeepGridApi = 160,
    ModbusTcpMaster = 170,
    MqttServer = 180,
    EConnectApi = 190,
    SelAscii = 200,
    ION = 210,
    FlexiblePowerApi = 220,
    MqttSparkplugApplication = 240
}

export const enum CsmLineStatus {
    Online = 10,
    Offline = 20,
    Suspicious = 30,
    Unknown = 40,
    Failed = 50
}

export const enum AnalogueThresholdZone {
    BelowLowLow = 0,
    LowLowToLow = 1,
    Normal = 2,
    HighToHighHigh = 3,
    AboveHighHigh = 4
}

export const enum MainsStatusState {
    UnknownOrNotFitted,
    On,
    Off
}

export const enum PluginType {
    Mimic = 1,
    Overview = 2,
    App = 2
}

export const enum RtuCommsType {
    None = 0,
    CircuitSwitchedData = 10,
    DirectSerial = 20,
    TcpIp = 30,
    UdpIp = 40,
    SMS = 50,
    TcpIpSms = 60,
    Soap = 70,
    TcpIpRing = 80,
    Dialup = 90,
    Icmp = 100
}

export const enum RtuCommsStatus {
    Undefined = 0,
    NoContact = 10,
    LookingForFreeLine = 20,
    PollRequestQueued = 30,
    Successful = 40,
    Dialling = 50,
    Answering = 60,
    Connected = 70,
    Connecting = 71,
    Disconnected = 72,
    Disconnecting = 73,
    IdentifyingRTU = 80,
    GettingBinaryLogs = 90,
    GettingCounterLogs = 100,
    GettingAnalogueLogs = 110,
    GettingDataLoggingLogs = 120,
    SendingConfiguration = 130,
    HangingUp = 140,
    NoChannelAvailable = 150,
    NoDialTone = 160,
    RTUBusy = 170,
    NoAnswer = 180,
    ProtocolFailure = 190,
    PacketTimedOut = 200,
    CallTimeout = 210,
    WrongUnitAnsweredCall = 220,
    CallFailed = 230,
    RequestingPosition = 240,
    TimedOutNoResponse = 250,
    LiveTracking = 260,
    AwaitingConfirmation = 270,
    GettingSignatureLogs = 280,
    SMSSendFailed = 290,
    AutoLiveTracking = 300,
    CommsFailed = 310,
    InsufficientCredit = 320,
    GettingSystemLogs = 330,
    NoCarrier = 340,
    GettingPositionLogs = 350,
    SendingSMS = 360,
    SMSSentAwaitingResponse = 370,
    AwaitingLogon = 380,
    GettingSettings = 390,
    ReceivingIndexFile = 400,
    ReceivingEventFiles = 410,
    ReceivingACCheckFile = 420,
    RingBackRequested = 430,
    CallIdle = 440,
    ReceivingTestFile = 450,
    PingingRTU = 460,
    ConnectionCancelled = 470,
    ConnectionFailed = 480,
    ConnectionTimeout = 490,
    UploadingFirmware = 500,
    UpgradingFirmware = 510,
    RequestingConfiguration = 520,
    Unknown = 530
}

export const enum RtuType {
    Unknown = 0,
    Dataset = 1,
    /**
     * @deprecated since version 2.55
     */
    Messenger61 = 10,
    /**
     * @deprecated since version 2.55
     */
    Messenger62 = 20,
    Nexus10 = 30,
    SteppGSMGPS = 40,
    Pod = 50,
    Nexus20 = 60,
    BowdenP360 = 70,
    /**
     * @deprecated since version 2.55
     */
    Signature = 80,
    Nexus30 = 90,
    Nexus21 = 100,
    Nexus22 = 110,
    BowdenFaultWatch = 120,
    GridSenseLT40PAC = 130,
    Kehui = 140,
    Nexus41 = 150,
    Nexus42 = 160,
    Nexus43 = 165,
    Nexus44 = 166,
    Nexus45 = 167,
    /**
     * @deprecated since version 2.55
     */
    Nexus46 = 170,
    Nexus50 = 180,
    /**
     * @deprecated since version 2.55
     */
    Nexus25 = 190,
    BowdenPowerWatch = 200,
    Nexus11 = 210,
    KelmanRezap = 220,    
    /**
     * @deprecated since version 2.55
     */
    SoapXml = 230,
    BowdenMK10 = 240,
    VistaSmartDisc = 250,
    NexusEnvoy = 260,
    Subnet = 270,
    Nexus52 = 280,
    BowdenSigma = 290,
    EnvoyDnp3 = 300,
    GenericDnp3 = 310,
    GridKeyStatisticalMcu = 320,
    /**
     * @deprecated since version 2.55
     */
    GenericNX = 330,
    /**
     * @deprecated since version 2.55
     */
    NortrollLT3100 = 340,
    /**
     * @deprecated since version 2.55
     */
    NortrollLTR400D = 350,
    /**
     * @deprecated since version 2.55
     */
    NortrollLTR110C = 360,
    /**
     * @deprecated since version 2.43
     */
    Nexus81 = 370,
    PQube = 380,
    GridKeyInstantaneousMcu = 390,
    LandisGyrMeter = 400,
    Nexus12 = 410,
    /**
     * @deprecated since version 2.55
     */
    IPECPDAlarm = 420,
    ANMScheme = 430,
    SMS = 440,
    IccpServer = 450,
    IccpClient = 460,
    GridKeyLVCMMcu = 470,
    LVCAPDevice = 480,
    LVCAPApplication = 490,
    Comms = 500,
    NavigatorRS = 510,
    Nexus22Dnp3 = 520,
    PQMonitor = 530,
    Iec104Outstation = 540,
    Nexus14 = 550,
    EneidaDtvig = 560,
    ModbusSlave = 570,
    MqttDevice = 580,
    RX10 = 590,
    EConnectEVChargingStation = 600,
    SEL735 = 610,
    ION7650 = 620,
    FlexiblePowerDispatchGroup = 630,
    MqttSparkplugDevice = 640,
    MqttSparkplugNode = 650,
    PoleMaster = 660,
    PoleMasterRail = 670,
    SmartReceiverDnp3 = 680,
    VisnetHub = 690,
    HAYSYSFeederNet = 700,
    VTElectronScavenger = 710,
    SEL734 = 720
}

export const enum Protocol {
    None = 0,
    BowdenFPI = 10,
    Nexus = 20,
    Kehui = 60,
    /**
     * @deprecated since version 2.55
     */
    iHostSOAPXML = 70,
    KelmanSOAPXML = 80,
    SubNet = 100,
    DNP3 = 110,
    GridKey = 120,
    /**
     * @deprecated since version 2.55
     */
    Nortroll = 130,
    /**
     * @deprecated since version 2.55
     */
    IPECPDAlarmSMS = 140,
    Iccp = 150,
    Iec104 = 160,
    DeepGrid = 170,
    ModbusTcp = 180,
    Mqtt = 190,
    EConnectApi = 200,
    SelAscii = 210,
    ION = 220,
    FlexiblePowerApi = 230,
    MqttSparkplug = 240
}

// see case 19700 to auto-generate
export const enum EventType {
    OtherUnknown = 0,
    CommsDeviceFailedtoRespond = 1,
    NetworkUnavailable = 2,
    HostBusy = 3,
    NoCarrierOnCommsdevice = 4,
    DialToConnectTimerElapsed = 5,
    PacketWaitTimerElapsed = 6,
    CatchAllTimerElapsed = 7,
    ErrorReadingSimCard = 9,
    WatchdogTimerReset = 10,
    BinaryLogsBufferOverrun = 16,
    BinaryInputClosed = 17,
    BinaryInputOpen = 18,
    FlutterDetectionAutoDisable = 19,
    FlutterDetectionAutoEnable = 20,
    AnalogueLogsBufferoverrun = 32,
    AnalogueThresholdCrossedRising = 33,
    AnalogueThresholdCrossedFalling = 34,
    AnalogueThresholdCrossed = 35,
    RoutineAnalogueLevelReading = 36,
    RoutineAnalogueLevelHostReading = 37,
    LowLowThresholdCrossedRising = 38,
    LowThresholdCrossedRising = 39,
    HighThresholdCrossedRising = 40,
    HighHighThresholdCrossedRising = 41,
    LowLowThresholdCrossedFalling = 42,
    LowThresholdCrossedFalling = 43,
    HighThresholdCrossedFalling = 44,
    HighHighThresholdCrossedFalling = 45,
    InputCalibratedNewReading = 46,
    CounterLogsBufferOverrun = 48,
    CounterThresholdReached = 49,
    CounterValueReceived = 50,
    RtuLogBufferOverrun = 64,
    DataLoggingBufferOverrun = 80,
    Counter30MinuteDatalogging = 81,
    OutputClosed = 97,
    OutputOpened = 98,
    PositionReceived = 113,
    PositionRequested = 114,
    LiveTrackingEnabled = 115,
    LiveTrackingDisabled = 116,
    LiveTrackingAutoDisabled = 117,
    LiveTrackingAutoEnabled = 118,
    ProtocolError = 243,
    RtuTemperatureReport = 244,
    RtuLate = 245,
    ManualPollCallOK = 246,
    ManualPollCallFailed = 247,
    AutoPollCallOk = 248,
    AutoPollCallFailed = 249,
    ColdStart = 250,
    RtuInitiatedCall = 251,
    RtuBatteryOk = 252,
    RtuBatteryLow = 253,
    PowerFailed = 254,
    PowerRestored = 255,
    PodUndervoltage = 256,
    PodOverVoltage = 257,
    PodUndervoltageRestored = 258,
    PodOverVoltageRestored = 259,
    CommunicationRestored = 260,
    CallFailed = 261,
    DialoutFailedToConnect = 262,
    TransientInstantaneousFault = 263,
    TransientSEFFault = 264,
    PermanentInstantaneousFault = 265,
    PermanentSefFault = 266,
    FaultReset = 267,
    CommissioningCompleted = 268,
    BinaryOutputRequestSent = 269,
    BinaryOutputRequestFailed = 270,
    SignatureLogBufferOverrun = 271,
    SignatureLogReceived = 272,
    BinaryOutputPatternSet = 273,
    InsufficientEeprom = 274,
    BitPatternSetReadMismatchOnPL1 = 275,
    SmsSendingFailed = 276,
    BinaryInputStatusOpened = 277,
    BinaryInputStatusClosed = 278,
    SignalStrengthReceived = 279,
    IgnitionOn = 280,
    IgnitionOff = 281,
    TcpConnectionDroppedUnexpectedly = 288,
    InsufficientCredit = 289,
    NoAnswerFromRtu = 290,
    RtuBusy = 291,
    NoCarrierConnectingToRtu = 292,
    NoDialToneConnectingToRtu = 293,
    NoChannelAvailable = 294,
    PositionLogBufferOverrun = 295,
    StartedMoving = 296,
    StoppedMoving = 297,
    ModemRebooted = 298,
    PdpInvalidContext = 299,
    PdpAccountInvalid = 300,
    PdpShutdownFailure = 301,
    PdpSetupCancelled = 302,
    PdpTooManyActiveAccounts = 303,
    PdpConflictWithHigherPriorityAccount = 304,
    PdpTooManyActiveUsers = 305,
    PdpNonExistentAccount = 306,
    PdpStopAtUserRequest = 307,
    PdpAuthenticationFailed = 308,
    PdpBearerFailedConnect = 309,
    PdpRemoteServerBusy = 310,
    PdpRemoteServerRefused = 311,
    PdpBearerBusy = 312,
    PdpLineBusy = 313,
    PdpUnknownError = 314,
    PdpInvalidParameters = 315,
    IPNoConnection = 316,
    IPConnecting = 317,
    IPConnected = 318,
    IPConnectionBusy = 319,
    IPOtherError = 320,
    WarmStart = 321,
    SmsSentToRtu = 322,
    SMSResponseTimerElapsed = 323,
    BinarySignatureLogReceived = 324,
    RtuDiagnostic = 325,
    CsdConnectionDroppedUnexpectedly = 326,
    GprsConnectionNotSupported = 327,
    SmsMessageReceived = 328,
    ErrorWithModemSmsMemory = 329,
    CallbackRequested = 330,
    StxReceivedDuringIncomingMessage = 331,
    CorruptIncomingMessageIgnored = 332,
    UnspecifiedConnectionError = 333,
    RingBackInitiated = 334,
    RtuParked = 335,
    RtuUnparked = 336,
    SiteNameChanged = 337,
    GroupChanged = 338,
    SerialNumberChanged = 339,
    UserData1Changed = 340,
    UserData2Changed = 341,
    UserData3Changed = 342,
    UserData4Changed = 343,
    UserData5Changed = 344,
    UserData6Changed = 345,
    LastMaintainedDateReset = 346,
    CallCountersReset = 347,
    AnalogueAverage = 348,
    RingBackResponseTimerElapsed = 349,
    BinaryInputClosedManualOverride = 350,
    BinaryInputOpenedManualOverride = 351,
    RtuBatteryOkManualOverride = 352,
    ConnectionTimeout = 400,
    NoDataReceived = 401,
    ConnectionAuthenticationError = 402,
    EstablishConnectionInterrupted = 403,
    ConnectionToDeviceLost = 404,
    DisconnectedForCallBack = 405,
    DisconnectedForNewAlarm = 406,
    PdpActivationFailure = 407,
    IPConnectionFailure = 408,
    EepromBufferOverrun = 409,
    AnalogueInputInvalid = 413,
    AnalogueInputValid = 414,
    IODeviceOffline = 415,
    IODeviceOnline = 416,
    ScheduledBinaryOutputQueueFailed = 417,
    CommsSettingsSentToDevice = 420,
    AggressiveCallInModeEnabled = 421,
    AggressiveCallInModeDisabled = 422,
    AggressiveCallInModeManuallyDisabled = 423,
    SMSNotificationsDisabled = 424,
    SMTPNotificationsDisabled = 425,
    SMSNotificationsEnabled = 426,
    SMTPNotificationsEnabled = 427,
    SMSNotificationsLimitReached = 428,
    /**
     * @deprecated since version 2.56
     */
    EmailReceivedFromUnit = 429,
    ModemFailedtoShutdown = 430,
    ColdstartCallDelayed = 431,
    RtuOffline = 440,
    RtuOnline = 441,
    Connected = 442,
    Disconnected = 443,
    BufferOverflow = 444,
    DeviceTrouble = 445,
    Connecting = 446,
    CounterDeltaValue = 447,
    PollRequested = 448,
    BinaryOutputRequestCompleted = 449,
    PollSuccessful = 450,
    PollFailed = 451,
    Disconnecting = 452,
    ConnectionReset = 453,
    ConnectionActivityTimeout = 454,
    UnexpectedSerialNumber = 455,
    BinaryOutputCommandTimeout = 456,
    BinaryOutputCommandRejected = 457,
    DeviceHealthy = 458,
    /**
     * @deprecated since version 2.50
     */
    DisturbanceXMLParsingFailed = 459,
    DeadbandCrossed = 460,
    AnalogueOutputLevelReading = 461,
    AnalogueOutputCommandTimeout = 462,
    AnalogueOutputCommandRejected = 463,
    AnalogueOutputRequestSent = 464,
    AnalogueOutputRequestFailed = 465,
    AnalogueOutputRequestCompleted = 466,
    AnalogueOutputCommandSuccessful = 467,
    BinaryOutputCommandSuccessful = 468,
    ProcessingFile = 469,
    FileImportSuccessful = 470,
    FileImportFailed = 471,
    InvalidDeviceIdentity = 472,
    WaitingForValidSerialNumber = 473,
    SerialNumberValidated = 474,
    DeviceConfigFileImported = 475,
    DeviceConfigFileImportFailed = 476,
    StartupCompleted = 477,
    StartupFailed = 478,
    NeedTime = 479,
    SmsReceivedFromRtu = 480,
    RtuCreated = 481,
    /**
     * @deprecated since version 2.43
     */
    RtuAclResendRequested = 482,
    /**
     * @deprecated since version 2.43
     */
    RtuAclCleared = 483,
    /**
     * @deprecated since version 2.43
     */
    RtuAclEntryAdded = 484,
    /**
     * @deprecated since version 2.43
     */
    RtuAclEntryRemoved = 485,
    /**
     * @deprecated since version 2.43
     */
    RtuAclEntryStored = 486,
    RingFromAuthorisedPhoneNumber = 487,
    RingFromUnauthorisedPhoneNumber = 488,
    SmsFromAuthorisedPhoneNumber = 489,
    SmsFromUnauthorisedPhoneNumber = 490,
    /**
     * @deprecated since version 2.43
     */
    RtuAclEntryCleared = 491,
    NoValidConfigFound = 492,
    RtuLocked = 493,
    RtuUnlocked = 494,
    RtuNoteAdded = 495,
    RtuNoteEdited = 496,
    RtuNoteDeleted = 497,
    RingBackRequested = 498,
    RtuFirmwareAssigned = 499,
    RtuFirmwareUploadStarted = 500,
    RtuFirmwareUploadSucceeded = 501,
    RtuFirmwareUploadFailed = 502,
    RtuFirmwareUpgradeFailed = 503,
    RtuFirmwareUpgradeSucceeded = 504,
    RtuFirmwareUpgradeConfigurationFailed = 505,
    RtuCertificateCreated = 506,
    RtuCertificateDeleted = 507,
    InvalidRtuCertificate = 508,
    RtuCertificateUploaded = 509,
    AnalogueOutputLevelHostReading = 510,
    AnmResultsFile = 511,
    DisconnectedLocalClose = 512,
    OutputStatusClosed = 513,
    OutputStatusOpened = 514,
    LogicInitialised = 515,
    LogicRunCompletedControlModuleDisabled = 516,
    LogicRunCompletedRemoteControlNotAvailable = 517,
    LogicRunCompletedNoViolations = 518,
    LogicRunCompletedLatched = 519,
    LogicRunCompletedSetpointsCalculated = 520,
    LogicRunCompletedSetpointsDispatched = 521,
    LogicRunCompletedControlRetry = 522,
    LogicError1MinimumDatasetNotAvailable = 523,
    LogicError2UnexpectedConfiguration = 524,
    LogicError3StateEstimationFailure = 525,
    LogicError4StateEstimationMismatch = 526,
    LogicError5NoSolutionFound = 527,
    LogicError6SetpointControlFailure = 528,
    LogicFailed = 529,
    LogicGracefulShutdown = 530,
    StringValueReceived = 531,
    StringValueChanged = 532,
    PostFirmwareUpgradeExtendedConfigurationFailed = 533,
    RtuFailedToSendSms = 534,
    LocalFirmwareUpdateStarted = 535,
    LocalFirmwareUpdateTimedOut = 536,
    RemoteFirmwareCheckStarted = 537,
    RemoteFirmwareCheckTimedOut = 538,
    RemoteFirmwareFileUnavailable = 539,
    RemoteFirmwareSameAsCurrentlyRunning = 540,
    FirmwareFileInvalid = 541,
    NewFirmwareLoadingOrVerificationFailed = 542,
    NewFirmwareLoadingOrVerificationComplete = 543,
    NewFirmwareFirstBootTemporary = 544,
    NewFirmwareTransitionComplete = 545,
    NXBFieldNotSupported = 546,
    TemplateSyncEnabled = 547,
    TemplateSyncDisabled = 548,
    TemplateSyncCompleted = 549,
    TemplateSyncFailed = 550,
    BrownoutReset = 551,
    PQEvent = 552,
    PQIncompleteEvent = 553,
    DeviceApiLate = 554,
    DeviceApiCommsRestored = 555,
    PQPendingEvent = 556,
    FirmwareVersionChanged = 557,
    RtuConfigurationDownloadedByRtu = 558,
    RtuConfigurationUploadedByRtu = 559,
    RtuConfigurationImportedFromRtu = 560,
    RtuConfigurationFailedImport = 561,
    RtuConfigurationFailedExport = 562,
    RtuConfigurationDownloadRequestedByRtu = 563,
    RtuConfigurationNoChangesFoundForDownloadByRtu = 564,
    RtuConfigurationMetadataRequestedByRtu = 565,
    AnalogueRoutineDataloggingFailed = 566,
    AnalogueRoutineDataloggingRestored = 567,
    FileImportError = 568,
    FileImportWarning = 569,
    PushNotificationsDisabled = 570,
    PushNotificationsEnabled = 571,
    DecommissioningCompleted = 572,
    DoubleBitInputIntermediate = 573,
    DoubleBitInputOpen = 574,
    DoubleBitInputClosed = 575,
    DoubleBitInputIndeterminate = 576,
    InService = 577,
    OutOfService = 578,
    InstallationDateChanged = 579
}

export const enum SortOrder {
    Ascending = 0,
    Descending = 1
}

export const enum EventSortColumn {
    EventId = 0,
    Timestamp = 1
}

export const enum PointGrouping {
    RtuId
}

export const enum FolderContentType {
    Zip
}

export const enum AggregationMode {
    First,
    Last,
    Min,
    Max,
    Avg,
    Median,
    Sum,
    Range,
    Diff,
    Count,
    SumSquares,
    Var,
    StdDev,
    Iqr,
    Mult,
    Div,
    Integral,
    StepIntegral,
    Percentile95,
    Percentile5,
    Percentile
}

export const enum InterpolationMode {
    Linear
}

export const enum CompressionAlgorithm {
    ConvergingWindow,
    Deadband
}

export const enum FindMode {
    First,
    Last,
    Min,
    Max
}

export const enum BoundaryOptions {
    Exact = 0,
    Before = 1,
    After = 1 << 1
}

export const enum CredentialType {
    Unknown = 0,
    AuthorisedPhoneNumber = 1
}

export const enum OutputOperationType {
    Undefined = 0,
    Energise = 10,
    DeEnergise = 20,
    EnergisePulse = 30,
    DeEnergisePulse = 40,
    SetIntegerValue = 50
}

export const enum OueuedOperationStatus {
    Pending = 0,
    Completed = 1,
    Unknown = 2,
    Failed = 3,
    Cancelled = 4
}

export const enum AlarmSummary {
    Healthy = 1,
    AckedAlarm = 2,
    UnackedAlarm = 3,
    Late = 4,
    Parked = 5
}

export const enum AlarmConditions {
    None = 0,
    BatteryLow = 1,
    BatteryUnknown = 2,
    MainsOff = 4,
    MainsUnknown = 8,
    CommsLate = 16,
    UnstableConnection = 32,
    DeviceTrouble = 64,
    MaintenanceDue = 128,
    ApiCommsLate = 256,
    ConfigSyncError = 512,
    RoutineDataloggingFailed = 1024
}

export const enum CoordinateSystem {
    LatLong = 0,
    Bng = 1
}

export const enum LatLongPosition {
    DecimalDegrees = 0,
    DecimalDegreesHemisphere = 1,
    DegreesDecimalMinutes = 2,
    DegreesMinutesSeconds = 3
}

export const enum BngPosition {
    EastingNorthing = 0,
    GridReference = 1
}

/**
 * Common IO References and IO Reference Offsets using in iHost
 */
export const enum IOReferences {
    /**
     * shared between binary inputs and counter inputs
     */
    DigitalInputOffset = 1,
    /**
     * shared between binary inputs and counter inputs
     */
    DigitalInputMax = 1000,
    BinaryInputOffset = 1,
    BinaryInputMax = 1000,
    AnalogueInputOffset = 1001,
    AnalogueInputMax = 2000,
    AnalogueInput16BitOffset = 1009,
    AnalogueInput32BitOffset = 1017,
    VirtualAnalogueInputOffset = 1901,
    Temperature = 1901,
    SystemPointOffset = 2001,
    SystemPointMax = 3000,
    EventSource = 2001,
    Battery = 2002,
    ACSupply = 2003,
    Modem = 2004,
    PodUnderVoltage = 2005,
    PodOverVoltage = 2006,
    FpiStatus = 2007,
    Position = 2008,
    Ignition = 2009,
    BinaryOutputOffset = 3001,
    BinaryOutputMax = 4000,
    CounterInputOffset = 4001,
    CounterInputMax = 5000,
    StringInputOffset = 5001,
    StringInputMax = 6000,
    AnalogueOutputOffset = 6001,
    AnalogueOutputMax = 7000,
    DoubleBitInputOffset = 7001,
    DoubleBitInputMax = 8000
}

export const enum PointType {
    Digital = 10,
    Analogue = 20,
    System = 30,
    String = 40
}

export const enum PointSubType {
    BinaryInput = 10,
    BinaryOutput = 15,
    CounterInput = 20,
    AnalogueInput = 30,
    AnalogueOutput = 35,
    RtuBattery = 40,
    RtuPower = 50,
    Rssi = 60,
    RtuEventSource = 70,
    GpsPosition = 80,
    RtuStatus = 90,
    RtuTemperature = 100,
    PodVoltagePoint = 110,
    FpiStatus = 120,
    Signature = 130,
    Ignition = 140,
    StringInput = 150,
    DoubleBitInput = 160
}

export const enum VirtualPointType {
    BinaryInput = 10,
    BinaryOutput = 15,
    CounterInput = 20,
    AnalogueInput = 30,
    AnalogueOutput = 35, 
    StringInput = 40,
    DoubleBitInput = 45,
}

export const enum VirtualPointVariation {
    Float = 1,
    Normalised = 2,
    Scaled = 3,
    StepPosition = 4,
    Integer32 = 5,
    Double = 6
}

export const enum LogicFlags {
    TriggerOnInterval = 1,
    TriggerOnNewCall = 2,
    TriggerOnImport = 4,
    QueueOutputOperationOnlyOnChange = 8,
    TriggerOnNewData = 16,
    HighPriority = 128
}

export const enum RtuFileFormat {
    None = 0,
    EnvoyCsvDataLogs = 1,
    CsvEvents = 2,
    CsvRtuTemplate = 3,
    /**
     * @deprecated since version 2.54
     */
    SubnetCsvDataLogs = 4,
    PqubeCsvDataLogs = 5,
    /**
     * @deprecated since version 2.50
     */
    LandisGyrDataLogs = 6,
    /**
     * @deprecated since version 2.54
     */
    SubnetHarmonicTrendEmail = 7,
    /**
     * @deprecated since version 2.54
     */
    SubnetFlickerTrendEmail = 8,
    LVCapJsonDataLogs = 9,
    CommissioningDatalog = 10,
    PQDataArchivePQube3 = 11,
    CsvPQDataLogsAeberle = 12,
    EventRecordingComtradeWaveform = 13,
    CsvPQDataLogsPQube3 = 14,
    XmlPQEventPQube3 = 15,
    EventRecordingPQube3CsvWaveform = 16,
    CsvPQEventsSiemens = 17,
    PqdifPQDataLogsSiemensQ200 = 18,
    EventRecordingPQube3CsvRms = 19,
    EventRecordingComtradeRms = 20,
    EventRecordingArchiveComtrade = 21,
    PQDataArchiveSiemensQ200 = 22,
    CsvPQEventsAeberle = 23,
    CsvRtuEvents = 24,
    NgesoPerfmonV1 = 25,
    RtuDataArchive = 26,
    NexusBinaryStatus = 27,
    PQDataArchiveSiemensQ100 = 28,
    PqdifPQDataLogsSiemensQ100 = 29,
    NgesoAvailabilityRedeclarationV1 = 30,
    NgesoBaselineDeclarationV1 = 31,
    PQDataArchiveSiemensSiprotec7ke85 = 32,
    SmartNavigator2CsvWaveform = 33,
    NgesoPerfmonV1Resubmission = 34,
    Unrecognised = 4294967295
}

export const enum RtuFileValueFormat {
    None = 0,
    Protocol = 1,
    Real = 2,
    Raw = 3
}

export const enum RtuFileSource {
    Unknown = 0,
    Internal = 1,
    Device = 2,
    User = 3,
    FileArchive = 4
}

export const enum IsoDayOfWeek {
    All = 0,
    Mon = 1,
    Tue = 2,
    Wed = 3,
    Thu = 4,
    Fri = 5,
    Sat = 6,
    Sun = 7
}

export const enum NetworkNodeType {
    Rtu = 1,
    Junction = 2,
    LinkedNetwork = 3,
    Point = 4,
    Bus = 5,
    Infeeder = 6,
    Generator = 7,
    Load = 8
}

export const enum NetworkNodeDisplayType {
    Icon = 1,
    Shape = 2
}

export const enum NetworkEdgeType {
    Line = 1,
    Switch = 2,
    Transformer = 3,
    Cable = 4
}

export const enum NetworkGroupType {
    Group = 1,
    Feeder = 2
}

export const enum FileContentType {
    Raw,
    Zip,
    UnZip,
    Json,
    Svg
}

export const enum CertificateType {
    Trusted = 1,
    Signing = 2,
    Server = 3,
    RTU = 4
}

export const enum SignatureAlgorithm {
    Sha1 = 0,
    Sha256 = 1,
    Unknown = 2
}

export const enum CertificateStatus {
    Valid = 0,
    Renew = 1
}

export const enum DataSource {
    Main,
    Archive,
    MainArchive
}

export const enum Smoothing {
    Interpolated,
    Step
}

export const enum Indicator {
    None,
    Circle,
    Square,
    Diamond,
    Triangle,
    Cross
}

export const enum LineStyle {
    Solid,
    Dashed,
    Dotted
}

export const enum TimeSeriesFilterAction {
    None,
    Filter,
    Cap
}

export const enum RtuFileSortField {
    RtuFileId = 1,
    DateCreated = 2,
    DateAdded = 3,
    DateProcessed = 4
}

export const enum TimeUnits {
    Milliseconds,
    Seconds,
    Minutes,
    Hours,
    Days,
    Weeks,
    Months,
    Years
}

export const enum RtuGroupTreeType {
    Rtu,
    RtuGroup,
    Customer
}

export const enum ScadaCommsType {
    Serial = 0,
    TcpIp = 4
}

export const enum ScadaProtocol {
    IEC_60870_5_101 = 1,
    IEC_60870_5_104 = 3,
    DNP3 = 4,
    IEC_61850 = 5,
    Kafka = 6,
    FlexiblePowerApi = 7,
    MqttSparkplug = 8
}

export const enum SystemBackupType {
    Full = 0,
    Partial = 1
}

export const enum SystemBackupStatus {
    Successful = 0,
    Failed = 1,
    InProgress = 2
}

export const enum PointGroupType {
    General = 1,
    PhysicalCard = 2,
    DownstreamDevice = 3,
    HVBusbar = 4,
    HVFeeder = 5,
    LVBusbar = 6,
    LVFeeder = 7,
    HVNetwork = 8,
    LVNetwork = 9
}

export const enum MetricType {
    System = 1,
    Customer = 2
}

export const enum NotificationMethod {
    Undefined = 0,
    SMS = 10,
    SMTPEmail = 20,
    Push = 30
}

export const enum SystemLogType {
    Undefined = 0,
    CSMStarted = 10,
    CSMFailed = 11,
    CSMAdded = 12,
    CSMDeleted = 13,
    CSMSettingsChanged = 14,
    CSMLineAdded = 15,
    CSMLineDeleted = 16,
    CSMLineSettingsChanged = 17,
    CSMShutdown = 20,
    TMStarted = 30,
    TMShutdown = 40,
    TMAdded = 41,
    TMDeleted = 42,
    TMTaskDeleted = 43,
    TMSettingsChanged = 44,
    TMTaskSettingsChanged = 45,
    CallfromUnknownRTU = 50,
    RTUSerialNoMismatch = 60,
    NewUserAdded = 70,
    UserAccountToAddressBookEntryLinkFailed = 75,
    UserDeleted = 80,
    NewRTUCreated = 90,
    RTUDeleted = 100,
    ProtocolError = 110,
    LineFailed = 120,
    LineRestored = 130,
    DatabaseFailed = 140,
    DatabaseRestored = 150,
    CSMStartupFailed = 160,
    SendConfigPacketFailed = 170,
    ScadaInterfaceStarted = 180,
    ScadaInterfaceStopped = 190,
    ScadaInterfaceFailed = 195,
    ScadaLinkOK = 200,
    ScadaLinkDown = 210,
    TaskLate = 220,
    FailedCallfromUnknownCallerLineID = 230,
    ConfigQueueEntryAdded = 240,
    ConfigQueueEntryCancelled = 250,
    SMSmessageFromUnknownNumber = 260,
    NoDataReceivedOnLine = 270,
    TaskManagerTaskExecutionFailure = 280,
    TaskManagerFailed = 290,
    NewCustomerCreated = 305,
    CustomerDeleted = 310,
    AssignedUserGroupChanged = 315,
    SMSCommandReceived = 320,
    ModemReset = 330,
    ModemFailedToReset = 340,
    MessagingLimitExceeded = 350,
    MessagingDisabled = 360,
    MessagingAutoDisabled = 365,
    MessagingEnabled = 370,
    MessagingSettingsUpdated = 380,
    MessagingLimitsUpdated = 390,
    SMSNotificationsDisabled = 420,
    SMTPNotificationsDisabled = 430,
    SMSNotificationsEnabled = 440,
    SMTPNotificationsEnabled = 450,
    CallCounterThresholdExceeded = 460,
    SMSNotificationsLimitReached = 470,
    /**
     * @deprecated since version 2.56
     */
    EmailFromUnknownSourceReceived = 480,
    CustomerSettingsChanged = 490,
    ArchivedEvents = 500,
    /**
     * @deprecated since version 2.56
     */
    MailboxPollFailure = 510,
    /**
     * @deprecated since version 2.50
     */
    DisturbanceProcessingFailed = 520,
    CsmVersionChanged = 530,
    TmVersionChanged = 540,
    ScadaVersionChanged = 550,
    UserPasswordChanged = 580,
    ForcedPasswordExpiry = 590,
    /**
     * @deprecated since version 2.43
     */
    CredentialAdded = 600,
    /**
     * @deprecated since version 2.43
     */
    CredentialUpdated = 610,
    /**
     * @deprecated since version 2.43
     */
    CredentialDeleted = 620,
    UserImpersonationStarted = 630,
    UserVerificationCodeSent = 640,
    UserVerified = 650,
    UserRejected = 660,
    CsmHostMachineChanged = 670,
    TmHostMachineChanged = 680,
    ScadaHostMachineChanged = 690,
    RTULocked = 700,
    RTUUnlocked = 710,
    UserAccessChanged = 720,
    RtuFirmwareUploaded = 730,
    RtuFirmwareDeleted = 740,
    NetworkAdded = 750,
    NetworkSnapshotCreated = 751,
    NetworkDeleted = 760,
    NetworkSnapshotDeleted = 761,
    SystemCertificateUploaded = 770,
    SystemCertificateDeleted = 780,
    SystemCertificateCreated = 790,
    RtuCertificateCreated = 800,
    RtuCertificateDeleted = 810,
    UnknownRTUAddress = 820,
    RtuCertificateUploaded = 830,
    MessageBrokerRestored = 840,
    MessageBrokerFailed = 850,
    RtuRestored = 860,
    NoDataReceivedOnScadaChannel = 870,
    UserMoved = 880,
    CustomerNoteAdded = 890,
    CustomerNoteEdited = 900,
    CustomerNoteDeleted = 910,
    PushNotificationsDisabled = 920,
    PushNotificationsEnabled = 930,
    SystemHealthAlarm = 940,
    UserGroupSettingsChanged = 950,    
    EventStreamingServiceFailed = 980,
    NoDataTransferForEventStreamingProducer = 990,
    EventStreamingServiceStarted = 1000,
    EventStreamingServiceStopped = 1010,
    EventStreamingServiceVersionChanged = 1020,
    EventStreamingServiceHostMachineChanged = 1030,
    EventStreamingServiceAdded = 1040,
    EventStreamingServiceDeleted = 1050,
}

export const enum SystemLogSortColumn {
    SystemLogId,
    Timestamp
}

export const enum SeriesModifier {
    Static
}

export const enum CopyOption {
    Exclude = 0,
    Include = 1
}

export const enum RtuTemplateType {
    DeviceTemplate = 1,
    CommsTemplate = 2
}

export const enum RtuTemplateSyncResult {
    NeverSynced = 0,
    Completed = 1,
    Failed = 2
}

export const enum RtuTemplateSyncStatus {
    InSync = 0,
    NotInSync = 1,
    Failed = 2
}

export const enum OutputOperationSortColumn {
    OutputOperationId = 0,
    QueuedTime = 1
}

export const enum RtuSearchFields {
    RtuSerialNumber = 1 << 0,
    SiteName = 1 << 1,
    UserData1 = 1 << 2,
    UserData2 = 1 << 3,
    UserData3 = 1 << 4,
    UserData4 = 1 << 5,
    UserData5 = 1 << 6,
    UserData6 = 1 << 7
}

export const enum AddVirtualRtuPointsBehaviour {
    AddNew = 1 << 0,
    UpdateExisting = 1 << 1,
    DeleteMissing = 1 << 2
}

export const enum VirtualPointNumberAssignmentBehaviour {
    FillGaps = 1,
    RtuPointNumbers = 2
}

export const enum HighlightMode {
    NearestX = 0,
    NearestXPerLine = 1
}

export const enum SubTrendHighlighting {
    ActiveOnly = 0,
    All = 1
}

export const enum TrendLineStats {
    None = 0,
    Count = 1,
    Min = 1 << 1,
    Max = 1 << 2,
    Avg = 1 << 3,
    Sum = 1 << 4,
    Var = 1 << 5,
    StdDev = 1 << 6,
    Range = 1 << 7,
    Total = 1 << 8,
    StepIntegral = 1 << 9
}

export const enum PhaseArrangement {
    Unknown,
    SinglePhase,
    ThreePhaseWyeStar,
    ThreePhaseDelta
}

export const enum TrendChartType {
    TimeSeries = 1,
    Bar
}

export const enum PQMeasurandSet {
    Frequency = 1,
    Power,
    VoltageRms,
    VoltageHarmonics,
    VoltageInterharmonics,
    VoltageThd,
    VoltageUnbalance,
    Flicker,
    CurrentRms,
    CurrentHarmonics,
    CurrentThd,
    VoltageRmsFastSampling
}

export const enum PQPhase {
    None = 0,
    L1 = 1 << 0,
    L2 = 1 << 1,
    L3 = 1 << 2,
    N = 1 << 3,
    Sum = 1 << 4
}

export const enum PQExtreme {
    Min = 1 << 0,
    Max = 1 << 1
}

export const enum PQTrendLayout {
    SingleTrend,
    OneTrendPerPhase,
    OneTrendPerMeasurement
}

export const enum PQValueScaling {
    DisplayValue,
    PercentageOfFundamental,
    PercentageOfNominal,
    PercentageOfLimit,
    PerUnitOfNominal
}

export const enum PQLimit {
    Upper = 1 << 0,
    Lower = 1 << 1
}

export const enum PQPowerMeasurand {
    ApparentPower = 1 << 0,
    RealPower = 1 << 1,
    ReactivePower = 1 << 2,
    PowerFactor = 1 << 3
}

export const enum PQHarmonic {
    H1 = 1,
    H2 = 2,
    H3 = 3,
    H4 = 4,
    H5 = 5,
    H6 = 6,
    H7 = 7,
    H8 = 8,
    H9 = 9,
    H10 = 10,
    H11 = 11,
    H12 = 12,
    H13 = 13,
    H14 = 14,
    H15 = 15,
    H16 = 16,
    H17 = 17,
    H18 = 18,
    H19 = 19,
    H20 = 20,
    H21 = 21,
    H22 = 22,
    H23 = 23,
    H24 = 24,
    H25 = 25,
    H26 = 26,
    H27 = 27,
    H28 = 28,
    H29 = 29,
    H30 = 30,
    H31 = 31,
    H32 = 32,
    H33 = 33,
    H34 = 34,
    H35 = 35,
    H36 = 36,
    H37 = 37,
    H38 = 38,
    H39 = 39,
    H40 = 40,
    H41 = 41,
    H42 = 42,
    H43 = 43,
    H44 = 44,
    H45 = 45,
    H46 = 46,
    H47 = 47,
    H48 = 48,
    H49 = 49,
    H50 = 50,
    H51 = 51,
    H52 = 52,
    H53 = 53,
    H54 = 54,
    H55 = 55,
    H56 = 56,
    H57 = 57,
    H58 = 58,
    H59 = 59,
    H60 = 60,
    H61 = 61,
    H62 = 62,
    H63 = 63,
    H64 = 64,
    H65 = 65,
    H66 = 66,
    H67 = 67,
    H68 = 68,
    H69 = 69,
    H70 = 70,
    H71 = 71,
    H72 = 72,
    H73 = 73,
    H74 = 74,
    H75 = 75,
    H76 = 76,
    H77 = 77,
    H78 = 78,
    H79 = 79,
    H80 = 80,
    H81 = 81,
    H82 = 82,
    H83 = 83,
    H84 = 84,
    H85 = 85,
    H86 = 86,
    H87 = 87,
    H88 = 88,
    H89 = 89,
    H90 = 90,
    H91 = 91,
    H92 = 92,
    H93 = 93,
    H94 = 94,
    H95 = 95,
    H96 = 96,
    H97 = 97,
    H98 = 98,
    H99 = 99,
    H100 = 100
}

export const enum PQFlickerMeasurand {
    Pst = 1 << 0,
    Plt = 1 << 1
}

export const enum TrendType {
    Interactive = 1,
    PowerQuality
}

export const enum RtuFileProcessStatus {
    Pending = 0,
    InProgress = 1,
    Successful = 2,
    Failed = 3
}

export const enum MapViewType {
    System,
    PowerQuality,
    HeatMap
}

export const enum PQMapViewType {
    PQEvents,
    PQMeasurandLimitAlarms,
    HealthyLostComms
}

// Bit operators in JS only work with 32-bit numbers hence explicitly defined the numbers
export const enum PQEventTypes {
    None = 0,
    VoltageInterruption = 1, /* 1 << 0 */
    VoltageMagnitude = 2, /* 1 << 1 */
    VoltageSwell = 4, /* 1 << 2 */
    VoltageSagDip = 8, /* 1 << 3 */
    RapidVoltageChange = 16, /* 1 << 4 */
    VoltageStepChange = 32, /* 1 << 5 */
    VoltageTransient = 64, /* 1 << 6 */
    VoltageUnbalance = 128, /* 1 << 7 */
    FlickerShortTerm = 256, /* 1 << 8 */
    FlickerLongTerm = 512, /* 1 << 9 */
    VoltageThd = 1024, /* 1 << 10 */
    VoltageHarmonics = 2048, /* 1 << 11 */
    VoltageInterharmonics = 4096, /* 1 << 12 */
    UnderFrequency = 8192, /* 1 << 13 */
    OverFrequency = 16384, /* 1 << 14 */
    RateOfChangeOfFrequency = 32768, /* 1 << 15 */
    PhaseCurrentInrush = 65536, /* 1 << 16 */
    WaveshapeChange = 131072, /* 1 << 17 */
    CrossTrigger = 262144, /* 1 << 18 */
    Other = 281474976710656 /* 1 << 48 */
}

export const enum PQMeasurandLimitAlarms {
    None = 0,
    VoltageRmsPuUnderVoltage = 1,
    VoltageRmsPuOverVoltage = 1 << 1,
    VoltageHarmonics = 1 << 2,
    VoltageThd = 1 << 3,
    VoltageUnbalance = 1 << 4,
    FlickerShortTerm = 1 << 5,
    FlickerLongTerm = 1 << 6
}

export const enum PQTimeRange {
    PreviousDay,
    PreviousSevenDays,
    PreviousThirtyDays
}

export const enum EventRecordingChannelType { 
    Analog,
    Digital
}

export const enum ChannelCategory {
    Other = 0,
    VoltagePP,
    VoltagePh,
    Current,
    Frequency,
    Power,
    Accelerometer
}

export const enum ChannelQuantity {
    Other = 0,
    Voltage,
    Current,
    ActivePower,
    ReactivePower,
    ApparentPower,
    PowerFactor,
    Frequency,
    AccelerometerXYZ,
    AccelerometerData
}

export const enum ChannelPhase {
    None = 0,
    L1N,
    L2N,
    L3N,
    N,
    L12,
    L23,
    L31
}

export const enum EventRecordingType {
    Unknown,
    Waveform,
    Rms
}

export const enum BmuField {
    BmuId = 0,
    ActivePower = 1,
    ReactivePower = 2,
    PowerAvailable = 3
}

export const enum BmuState {
    Healthy = 0,
    Unhealthy = 1
}

export const enum MqttMessagingApiType {
    Default = 0
}

export const enum MqttMessagePayloadFormat {
    Json = 0,
    Protobuf3 = 1
}

export const enum PQEventTimeSummaryType {
    RtuGroup = 1,
    Rtu = 2
}

export const enum PQEventCountOptions {
    PQEvents = 1 << 0,
    EventRecordings = 1 << 1
}

export const enum ReportType {
    CustomReport,
    HttpGetReport,
    JourneyReplay,
    Mileage,
    Plugin 
}

export const enum ReportRepeatInterval {
    None,
    Interval,
    Daily,
    Weekly,
    Monthly
}

export const enum ReportRunStatus {
    Pending,
    Running,
    Success,
    Failed,
    Deleted,
    Processing
}

export const enum ReportQueueSortColumn {
    ReportQueueEntryId,
    Name,
    LastExecution
}

export const enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
}

export const enum ReportFileSortColumn {
    ReportFileId,
    Name,
    DateCreated
}

export const enum ReportParameterType {
    Unknown,
    CustomerId,
    CustomerIds,
    RtuGroupId,
    RtuGroupIds,
    RtuId,
    RtuIds,
    RtuSerialNumber,
    RtuSerialNumbers,
    RtuIoRef,
    RtuIoRefs,
    EventLogTypeId,
    EventLogTypeIds,
    StartDate,
    EndDate,
    StartTime,
    EndTime,
    IntervalMins,
    IntervalHours,
    IntervalDays,
    IntervalMonths,
    Boolean,
    Number,
    Hidden,
    PointSubTypeId,
    PointSubTypeIds,
    Text,
    Enum,
    RtuTypeId,
    RtuTypeIds
}

export const enum CustomerFileSortField {
    CustomerFileId = 1,
    Name = 2,
    Size = 3,
    ModifiedTime = 4,
    AddedTime = 5
}

export const enum CustomerFileOverwriteStrategy {
    Path = 0,
    Id = 1
}

export const enum UserLoginStatus {
    Active = 0,
    Suspended = 1
}

export const enum UserLoginMethod {
    Local = 0,
    SingleSignOn = 1
}

export const enum EventNotificationRetentionBehaviour {
    Delete = 1,
    Disable = 2,
    Keep = 3
}

export const enum RtuNotificationStatus {
    Active = 0,
    Suspended = 1
}

export const enum MessageFormat {
    PlainText = 1,
    Html = 2
}

export const enum NotificationTemplateType {
    Simple = 1,
    Advanced = 2
}

export const enum MessageApiType {
    SmsGateway = 1,
    PushGateway = 2
}

export const enum MessageApiAuthenticationType {
    Anonymous = 0,
    Basic = 1,
    Bearer = 2
}

export const enum NxbFieldDataType {
    BoolFalse = "BoolFalse",
    BoolTrue = "BoolTrue",
    UInt8 = "UInt8",
    UInt16 = "UInt16",
    UInt32 = "UInt32",
    UInt64 = "UInt64",
    Int8 = "Int8",
    Int16 = "Int16",
    Int32 = "Int32",
    Int64 = "Int64",
    Float = "Float",
    Double = "Double",
    DateTime = "DateTime",
    ExtendedDateTime = "ExtendedDateTime",
    ASCIIString = "ASCIIString",
    Blob = "Blob",
    EncryptableASCIIString = "EncryptableASCIIString"
}

export const enum ReportQueueEntryOutputFlags {
    NoCompression = 0,
    ZipCompression = 1,
}

export const enum PQActivityTypes {
    None = 0,
    PQEvents = 1 << 0,
    PQMeasurandLimitAlarms = 1 << 1
}

export const enum EventRecordingFormat {
    ComtradeCombined,
    Csv
}

export const enum EventLogStatus {
    Unacknowledged = 10,
    Acknowledged = 20
}

export const enum MessageType {
    OutgoingSMS = 10,
    IncomingSMS = 20,
    OutgoingSMTP = 30,
    IncomingSMTP = 40,
    OutgoingPush = 50,
    PasswordResetEmail = 60,
    MfaEmail = 70,
    MfaSMS = 80
}

export const enum MessageStatus {
    Pending = 10,
    Sent = 20,
    Failed = 30,
    Received = 40,
    Expired = 50,
    Retrying = 60,
    TimedOut = 70,
    NotificationLimitReached = 80,
    PendingReply = 90,
    Throttled = 100,
    Restricted = 110,
    Delivered = 120
}

export const enum EventStorageType {
    Main = 1,
    Archive = 2
}

export const enum AuthErrorCode {
    CredentialsIncorrect = 1,
    LoginSuspended = 2,
    AccountLocked = 3,
    PasswordExpired = 4,
    PasswordChangeDenied = 5,
    LoginTemporarilyUnavailable = 6,
    OtpInvalid = 7,
    OtpRequired = 8,
    LocalLoginNotSupported = 9,
    SingleSignOnError = 10
}

export const enum OtpType {
    Unknown = 0,
    Totp = 1,
    RecoveryCode = 2,
    Email = 3,
    SMS = 4
}

export const enum ScadaStatus {
    Online = 10,
    Offline = 20,
    Suspicious = 30,
    Unknown = 40,
    Failed = 50
}

export const enum PasswordComposition {
    Any = 0,
    MixOf3Groups = 1,
    MixOf4Groups = 2
}

export const enum NetworkFaultLocationStatus {
    Calculated = 0,
    NoResult,
    Error
}

export const enum MapSourceType {
    Background = 1,
    Feature = 2
}

export const enum MapSourceProtocol {
    OpenStreetMaps = 1,
    BingMaps = 2,
    Wms = 3,    
    Wmts = 4
}

export const enum MapSourceAccessType {
    Direct = 1,
    Proxy = 2
}

export const enum NetworkFaultCategory {
    Indeterminate,
    PostFault,
    PreFault
}

export const enum TlsVersion {
    V1_0 = 1,
    V1_1 = 2,
    V1_2 = 4,
    V1_3 = 8
}

export const enum RtuReplacementBehaviour {
    Delete = 1,
    Move = 2
}

export const enum SystemServiceType {
    Csm = 1,
    TaskManager = 2,
    ScadaInterface = 3,
    EventStreamingService = 4,
}

export const enum LogEventLevel {
    Verbose = 0,
    Debug = 1,
    Info = 2,
    Warning = 3,
    Error = 4,
    Fatal = 5
}

export const enum PollStatus {
    Unknown = 0,
    Idle = 10,
    Retrying = 20,
    Requested = 30,
    Failed = 40,
    Successful = 50
}

export const enum ServiceLogEventsFormat {
    Text = "text",
    TextZip = "textZip",
    Clef = "clef",
    ClefZip = "clefZip"
}

export const enum RtuBulkImportFormat {
    Csv = 0,
    VisnetEnrollmentPackage = 1
}

export const enum MissingRtuOption {
    Skip = 0,
    Create = 1
}

export const enum ExistingRtuConflictOption {
    Skip = 0,
    Update = 1,
    Recreate = 2
}

export const enum OnlineRtuConflictOption {
    Skip = 0,
    Error = 1
}

export const enum RtuBulkImportRtuAction {
    Error = 0,
    Skipped = 1,
    Created = 2,
    Recreated = 3,
    Updated = 4
}

export const enum EventRecordingCalculationMode {
    FaultImpedance = 0,
    EnergyInPreFaultPeck
}

export const enum FaultPhaseType {
    Unknown = 0,
    Earth,
    PhaseToPhase
}

export const enum NetworkFaultCountOptions {
    FaultLocations = 1 << 0,
    Events = 1 << 1,
    EventRecordings = 1 << 2
}

export const enum RtuSortField {
    RtuId = 1,
    SerialNumber = 2,
    SiteName = 3
}

export const enum ConfigSyncStatus {
    Unknown = 0,
    WaitingForImportFromRTU = 1,
    Initialised = 2,
    Error = 3
}

export const enum ConfigSyncFlags {
    ExportSettings = 1,
    ImportSettings = 2
}

export const enum EventStreamingChannelType {
    Kafka = 1
}

export const enum EventStreamingDataModelType {
    LVData = 1
}

export const enum EventStreamingServiceStatus {
    Online = 10,
    Offline = 20,
    Suspicious = 30,
    Unknown = 40
}

export const enum EventStreamingProducerStatus {
    Online = 10,
    Offline = 20,
    Suspicious = 30,
    Unknown = 40,
    Failed = 50
}

export const enum KafkaSecurityProtocol
{
    Plaintext,
    Ssl,
    SaslPlaintext,
    SaslSsl
}

export const enum KafkaSaslMechanism
{
    Gssapi,
    Plain,
    ScramSha256,
    ScramSha512,
    OAuthBearer
}

export const enum KafkaMessagePayloadFormat
{
    Json = 1,
    Avro = 2,
    SchemaRegistryJson = 3,
    SchemaRegistryAvro = 4
}

export const enum KafkaAcks
{
    None = 0,
    Leader = 1,
    All = -1
}

export const enum KafkaPartitioner
{
    Random,
    Consistent,
    ConsistentRandom,
    Murmur2,
    Murmur2Random
}

export const enum KafkaCompressionType
{
    None,
    Gzip,
    Snappy,
    Lz4,
    Zstd
}
