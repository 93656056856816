/* tslint:disable:member-access */
/* tslint:disable:typedef-whitespace */
import * as $ from "jquery";

import * as Auth from "./iHostWebApiInterfaces.Auth";
import * as AddressBookEntry from "./iHostWebApiInterfaces.AddressBookEntry";
import * as Args from "./iHostWebApiInterfaces.Args"
import * as Bmus from "./iHostWebApiInterfaces.Bmus"
import * as Certificates from "./iHostWebApiInterfaces.Certificates";
import * as Csm from "./iHostWebApiInterfaces.Csm";
import * as CustomerFiles from "./iHostWebApiInterfaces.CustomerFiles";
import * as CustomerNotes from "./iHostWebApiInterfaces.CustomerNotes";
import * as Customers from "./iHostWebApiInterfaces.Customer";
import * as CustomerSchedules from "./iHostWebApiInterfaces.CustomerSchedules";
import * as Enums from "./iHostWebApiInterfaces.Enums";
import * as EventQueues from "./iHostWebApiInterfaces.EventQueues";
import * as EventRecordings from "./iHostWebApiInterfaces.EventRecordings";
import * as EventStreamingServices from "./iHostWebApiInterfaces.EventStreamingService";
import * as Events from "./iHostWebApiInterfaces.Events";
import * as IdentityProviders from "./iHostWebApiInterfaces.IdentityProviders";
import * as Inputs from "./iHostWebApiInterfaces.Inputs";
import * as Links from "./iHostWebApiInterfaces.Links";
import * as LVData from "./iHostWebApiInterfaces.LVData"
import * as Metrics from "./iHostWebApiInterfaces.Metrics";
import * as Maps from "./iHostWebApiInterfaces.Maps";
import * as networks from "./iHostWebApiInterfaces.Networks";
import * as networkFaults from "./iHostWebApiInterfaces.NetworkFaults";
import * as Plugins from "./iHostWebApiInterfaces.Plugins";
import * as PQData from "./iHostWebApiInterfaces.PQData";
import * as PQEvents from "./iHostWebApiInterfaces.PQEvents";
import * as Reports from "./iHostWebApiInterfaces.Reports";
import * as RtuNotes from "./iHostWebApiInterfaces.RtuNotes";
import * as Rtus from "./iHostWebApiInterfaces.Rtus";
import * as Scada from "./iHostWebApiInterfaces.Scada";
import * as System from "./iHostWebApiInterfaces.System";
import * as SystemLog from "./iHostWebApiInterfaces.SystemLog";
import * as TaskManager from "./iHostWebApiInterfaces.TaskManager";
import * as TimeSeries from "./iHostWebApiInterfaces.TimeSeries";
import * as Trends from "./iHostWebApiInterfaces.Trends";
import * as Users from "./iHostWebApiInterfaces.Users";
import * as UserGroup from "./iHostWebApiInterfaces.UserGroup";
import * as ShapeTemplates from "./iHostWebApiInterfaces.ShapeTemplates";
import * as Notifications from "./iHostWebApiInterfaces.Notifications";
import * as LoadSurveys from "./iHostWebApiInterfaces.LoadSurveys";
import * as OpsMetering from "./iHostWebApiInterfaces.OpsMetering";
import * as VirtualRtus from "./iHostWebApiInterfaces.VirtualRtus";
import * as Interfaces from "./iHostWebApiInterfaces";

export interface IHostApi {
    urlRoot: string;
    defaultPageNumber: number;
    defaultPageSize: number;
    addRtuGroup(rtuGroup: Rtus.AddRtuGroup): Promise<number>;
    getRtuGroup(rtuGroupId: number): Promise<Rtus.RtuGroup>;
    deleteRtuGroup(rtuGroupId: number): Promise<void>;
    updateRtuGroup(rtuGroupId: number, rtuGroup: Rtus.UpdateRtuGroup): Promise<void>;
    getRtuGroupForRtu(rtuId: number): Promise<Rtus.RtuGroup>;
    updateRtuGroupForRtu(rtuId: number, moveRtuGroupRequest: Rtus.MoveRtuGroupRequest): Promise<void>;
    getRtuGroupPath(rtuGroupId: number): Promise<Rtus.RtuGroup[]>;
    getRtuGroupAllRtus(rtuGroupId: number, paging?: Args.PagingOptions): Promise<Args.PagedCollection<Rtus.Rtu>>;
    getRtuGroupRtus(rtuGroupId: number, paging?: Args.PagingOptions): Promise<Args.PagedCollection<Rtus.Rtu>>;
    getRtu(rtuId: number, optionalArguments?: Args.GetRtuArguments): Promise<Rtus.Rtu>;
    getRtuTemplates(customerIds?: number[], optionalArguments?: Args.RtuTemplatesArguments): Promise<Args.Collection<Rtus.Rtu>>;
    addRtu(rtu: Rtus.AddRtu): Promise<number>;
    deleteRtu(rtuId: number): Promise<void>;
    deleteRtus(request: Rtus.DeleteRtusRequest): Promise<void>;
    replaceRtu(rtuId: number, request: Rtus.ReplaceRtuRequest): Promise<void>;
    updateRtusOnline(request: Rtus.UpdateRtusOnlineRequest): Promise<void>;
    updateRtuOnline(rtuId: number, request: Rtus.UpdateRtuOnlineRequest): Promise<void>;
    lockRtu(rtuId: number, request: Rtus.LockRtuRequest): Promise<void>;
    pollRtu(rtuId: number): Promise<void>;
    getRtuPollStatus(rtuId: number): Promise<Enums.PollStatus>;
    bulkUpdateRtuSettings(request: Rtus.BulkUpdateRtuSettingsRequest): Promise<void>;
    getRtuBySerialNumber(rtuSerialNumber: string, optionalArguments?: Args.GetRtuArguments): Promise<Rtus.Rtu>;
    getRtusBySiteName(siteName: string, optionalArguments?: Args.RtuSiteNameArguments): Promise<Args.Collection<Rtus.Rtu>>;
    getRtuLinks(rtuId: number): Promise<Links.RtuLinks>;
    getRtuCustomLinks(rtuId: number): Promise<Links.RtuCustomLink[]>;
    getRtuUserData(rtuId: number): Promise<Rtus.RtuUserData>;
    getRtuUserDatas(optionalArguments?: Args.AllRtusArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Rtus.RtuUserData>>;
    getRtuStatus(rtuId: number, optionalArguments?: Args.IncludeAlarmStateArguments)
        : Promise<Rtus.RtuStatus>;
    getRtusStatus(optionalArguments?: Args.RtusStatusArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Rtus.RtuStatus>>;
    getRtuLocation(rtuId: number): Promise<Rtus.RtuLocation>;
    getRtuLocations(optionalArguments?: Args.AllRtusArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Rtus.RtuLocation>>;
    updateRtuLocation(rtuId: number, rtuLocation: Rtus.UpdateRtuLocationRequest): Promise<void>;
    getRtuUserDataLabels(rtuId: number): Promise<Customers.UserDataLabels>;
    rtuSearch(
        query: string, 
        customerIds?: number[], 
        rtuGroupIds?: number[], 
        includeSubGroups?: boolean, 
        rtuTypes?: Enums.RtuType[],
        rtuFunctionIds?: string[],
        protocols?: Enums.Protocol[],
        searchFields?: Enums.RtuSearchFields,
        includeLinks?: boolean,
        paging?: Args.PagingOptions): Promise<Args.PagedCollection<Rtus.RtuWithUserData>>;
    getRtusDetails(args?: Rtus.GetRtusDetailsArgs): Promise<Args.Collection<Rtus.RtuDetails>>;
    acknowledgeAllEvents(rtuId: number, ack: boolean): Promise<void>;
    acknowledgeEvents(ackEventsRequest: Args.AcknowledgeEventsRequest): Promise<void>;
    updateEvent(rtuId: number, eventId: number, eventData: Events.UpdateEventRequest): Promise<void>;
    updateArchiveEvent(rtuId: number, archivedEventKey: Events.ArchivedEventKey, request: Events.UpdateEventRequest): Promise<void>;
    deleteEvent(rtuId: number, eventId: number): Promise<void>;
    deleteEvents(eventIds: number[]): Promise<void>;
    getCustomer(customerId: number, optionalArguments?: Args.GetCustomerArguments): Promise<Customers.Customer>;
    getCustomers(customerIds?: number[], optionalArguments?: Args.GetCustomersArguments): Promise<Args.Collection<Customers.Customer>>;
    updateCustomer(customerId: number, request: Customers.UpdateCustomerRequest): Promise<void>;
    updateCustomerAssignedMessageApis(customerId: number, request: Customers.AssignCustomerMessageApiRequest): Promise<void>;
    updateCustomerAssignedIdentityProviders(customerId: number, request: Customers.AssignCustomerIdentityProviderRequest): Promise<void>;
    deleteCustomer(customerId: number): Promise<void>;
    updateCustomerLicense(customerId: number, license: Customers.CustomerLicense): Promise<void>;
    getCustomerForRtu(rtuId: number): Promise<Customers.Customer>;
    getCustomerRtus(customerId: number, optionalArguments?: Args.AllRtusAndStatusAndLinkArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Rtus.Rtu>>;
    getCustomerUserDataLabels(customerId: number): Promise<Customers.UserDataLabels>;
    getCustomerRtuGroups(customerId: number, optionalArguments?: Args.CustomerRtuGroupsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Rtus.RtuGroup>>;
    getCustomerRtuUserData(
        customerId: number,
        optionalArguments?: Args.CustomerRtuUserDataArguments,
        paging?: Args.PagingOptions): Promise<Args.PagedCollection<Rtus.RtuUserData>>;
    getCustomerSettings(customerId: number, keys?: string[]): Promise<{ [key: string]: string }>;
    getCurrentCustomerSettings(keys?: string[]): Promise<{ [key: string]: string }>;
    setCustomerSettings(customerId: number, settings: { [key: string]: string | number }): Promise<void>;
    setCurrentCustomerSettings(settings: { [key: string]: string | number }): Promise<void>;
    getCustomerPriorities(customerId: number): Promise<Args.Collection<Customers.CustomerPriority>>;
    getAllCustomerPriorities(customerIds?: number[]): Promise<Args.Collection<Customers.CustomerPriority>>;
    getCustomerSummary(customerId: number): Promise<Customers.CustomerSummary>;
    getCustomerNotificationStats(customerId: number, createdFrom: string, createdTo: string, createdToInclusive?: boolean): Promise<Args.Collection<Notifications.NotificationStats>>;
    getCustomerPasswordRequirements(customerId: number): Promise<Auth.PasswordRequirements>;

    getAnalogueInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.AnalogueInput>>;
    getAnalogueInput(rtuId: number, ioReference: number): Promise<Inputs.AnalogueInput>;
    getAnalogueInputByPointNumber(rtuId: number, pointNumber: number): Promise<Inputs.AnalogueInput>;
    getAnalogueInputThresholdsForPoint(rtuId: number, ioReference: number): Promise<Inputs.AnalogueThreshold>;
    getAnalogueInputThresholds(rtuId: number, optionalArguments?: Args.IoReferencesArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.AnalogueThreshold>>;
    getAnalogueInputScaleForPoint(rtuId: number, ioReference: number): Promise<Inputs.AnalogueScale>;
    getAnalogueInputScales(rtuId: number, optionalArguments?: Args.IoReferencesArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.AnalogueScale>>;

    getAnalogueOutputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.AnalogueOutput>>;
    getAnalogueOutput(rtuId: number, ioReference: number): Promise<Inputs.AnalogueOutput>;
    getAnalogueOutputByPointNumber(rtuId: number, pointNumber: number): Promise<Inputs.AnalogueOutput>;
    getAnalogueOutputScaleForPoint(rtuId: number, ioReference: number): Promise<Inputs.AnalogueScale>;
    getAnalogueOutputScales(rtuId: number, optionalArguments?: Args.IoReferencesArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.AnalogueScale>>;
    getAnalogueOutputOperation(rtuId: number, ioReference: number, outputOperationId: number)
        : Promise<Inputs.AnalogueOutputOperation>;
    getAnalogueOutputOperations(
        rtuId: number, ioReference: number,
        optionalArguments?: Args.DateArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.AnalogueOutputOperation>>;
    queueAnalogueOutputOperation(rtuId: number, ioReference: number, request: Args.QueueAnalogueOutputOperationRequest)
        : Promise<number>;
    deleteAnalogueOutputOperation(rtuId: number, ioReference: number, outputOperationId: number): Promise<void>;

    getBinaryInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.BinaryInput>>;
    getBinaryInput(rtuId: number, ioReference: number): Promise<Inputs.BinaryInput>;
    getBinaryInputByPointNumber(rtuId: number, pointNumber: number): Promise<Inputs.BinaryInput>;

    getCounterInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.CounterInput>>;
    getCounterInput(rtuId: number, ioReference: number): Promise<Inputs.CounterInput>;
    getCounterInputByPointNumber(rtuId: number, pointNumber: number): Promise<Inputs.CounterInput>;

    getStringInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.StringInput>>;
    getStringInput(rtuId: number, ioReference: number): Promise<Inputs.StringInput>;
    getStringInputByPointNumber(rtuId: number, pointNumber: number): Promise<Inputs.StringInput>;

    getBinaryOutputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.BinaryOutput>>;
    getBinaryOutput(rtuId: number, ioReference: number): Promise<Inputs.BinaryOutput>;
    getBinaryOutputByPointNumber(rtuId: number, pointNumber: number): Promise<Inputs.BinaryOutput>;
    getBinaryOutputOperations(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.BinaryOutputOperation>>;
    getBinaryOutputOperation(rtuId: number, ioReference: number, outputOperationId: number)
        : Promise<Inputs.BinaryOutputOperation>;
    deleteBinaryOutputOperation(rtuId: number, ioReference: number, outputOperationId: number): Promise<void>;
    queueBinaryOutputOperation(rtuId: number, ioReference: number, request: Args.QueueBinaryOutputOperationRequest)
        : Promise<number>;        

    getDoubleBitInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.DoubleBitInput>>;
    getDoubleBitInput(rtuId: number, ioReference: number): Promise<Inputs.DoubleBitInput>;
    getDoubleBitByPointNumber(rtuId: number, pointNumber: number): Promise<Inputs.DoubleBitInput>;

    updatePointLogic(rtuId: number, ioReference: number, pointLogic: Inputs.PointLogic): Promise<void>;

    getAllPoints(optionalArguments?: Args.GetAllPointsArguments): Promise<Args.Collection<Inputs.Input>>;
    getPoints(rtuId: number, optionalArguments?: Args.GetPointsArguments, paging?: Args.PagingOptions) : Promise<Args.PagedCollection<Inputs.Input>>;
    getPointsWithQuality(rtuId: number, qualityFlag: number[], paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.Input>>;
    getPoint(rtuId: number, ioReference: number): Promise<Inputs.Input>;
    getPointsForPointGroup(rtuId: number, pointGroupId: number, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.Input>>;
    getPointGroupsForPoint(rtuId: number, ioReference: number, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.PointGroup>>;
    getPointGroupsForRtu(rtuId: number, paging?: Args.PagingOptions): Promise<Args.PagedCollection<Inputs.PointGroup>>;
    getPointGroupForRtu(rtuId: number, pointGroupId: number): Promise<Inputs.PointGroup>;

    getEvent(rtuId: number, eventId: number): Promise<Events.IHostEvent>;
    getArchiveEvent(rtuId: number, archivedEventKey: Events.ArchivedEventKey): Promise<Events.IHostEvent>;
    getDetailedEvent(rtuId: number, eventId: number, includeEventData?: boolean, includeEventComments?: boolean) : Promise<Events.DetailedEvent | PQEvents.PQEvent>;
    getDetailedArchiveEvent(rtuId: number, archivedEventKey: Events.ArchivedEventKey, includeEventData?: boolean, includeEventComments?: boolean): Promise<Events.DetailedEvent | PQEvents.PQEvent>;

    getEventStatusHistory(rtuId: number, eventId: number): Promise<Args.Collection<Events.EventStatusHistory>>;
    getEventNotificationHistory(rtuId: number, eventId: number): Promise<Args.Collection<Notifications.Message>>;

    getEvents(
        rtuId: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions,
        eventLogTypes?: Enums.EventType[],
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        minPriorityId?: number,
        useSavedFilter?: boolean,
        eventStatus?: Enums.EventLogStatus,
        eventStorageType?: Enums.EventStorageType)
        : Promise<Args.PagedCollection<Events.IHostEvent>>;
    getAllEvents(optionalArguments?: Args.GroupedEventsArguments,
        paging?: Args.PagingOptions,
        sortOrder?: Enums.SortOrder)
        : Promise<Args.Collection<Events.IHostEvent>>;
    getPointEvents(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions,
        eventLogTypes?: Enums.EventType[],
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        minPriorityId?: number,
        useSavedFilter?: boolean,
        eventStatus?: Enums.EventLogStatus,
        eventStorageType?: Enums.EventStorageType)
        : Promise<Args.PagedCollection<Events.IHostEvent>>;
    getDetailedEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions,
        eventLogTypes?: Enums.EventType[],
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        includeEventData?: boolean,
        includeEventComments?: boolean,
        minPriorityId?: number,
        useSavedFilter?: boolean,
        eventStatus?: Enums.EventLogStatus,
        eventStorageType?: Enums.EventStorageType)
        : Promise<Args.PagedCollection<Events.DetailedEvent | PQEvents.PQEvent>>;
    getDetailedEvents(
        rtuId: number,
        optionalArguments?: Args.DetailedEventsArguments,
        paging?: Args.PagingOptions,
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        includeEventData?: boolean,
        includeEventComments?: boolean)
        : Promise<Args.PagedCollection<Events.DetailedEvent | PQEvents.PQEvent>>;
    getAllDetailedEvents(optionalArguments?: Args.GroupedEventsArguments,
        paging?: Args.PagingOptions,
        sortOrder?: Enums.SortOrder)
        : Promise<Args.Collection<Events.DetailedEvent | PQEvents.PQEvent>>;

    getAnalogueInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.AnalogueInputEvent>>;
    getAnalogueInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.AnalogueInputEvent>>;

    getAnalogueOutputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.IHostEvent>>;
    getAnalogueOutputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.AnalogueOutputEvent>>;

    getBinaryInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.BinaryInputEvent>>;
    getBinaryInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.BinaryInputEvent>>;

    getBinaryOutputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.IHostEvent>>;
    getBinaryOutputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.BinaryOutputEvent>>;

    getCounterInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.IHostEvent>>;
    getCounterInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.CounterInputEvent>>;

    getStringInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.IHostEvent>>;
    getStringInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.StringInputEvent>>;

    getDoubleBitInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.IHostEvent>>;
    getDoubleBitInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Events.DoubleBitInputEvent>>;

    getPointLinks(rtuId: number, ioReference: number): Promise<Links.PointLink[]>;
    getRtuPointLinks(rtuId: number, optionalArguments?: Args.IoReferencesArguments): Promise<Links.PointLink[]>;

    getRtuRssi(rtuId: number): Promise<Inputs.RssiPoint>;
    getRtuMainsStatus(rtuId: number): Promise<Inputs.MainsStatus>;
    getRtusMainsStatus(optionalArguments: Args.AllRtusArguments, paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.MainsStatus>>;

    saveAnalogueInputEvents(rtuId: number, eventData: Events.AnalogueInputEvent[]): Promise<number>;
    saveAnalogueInputEventsForPoint(rtuId: number, ioReference: number, eventData: Events.AnalogueInputEvent[])
        : Promise<number>;
    getRtuFiles(rtuId: number, optionalArguments?: Args.FilesArguments): Promise<Rtus.RtuFile[]>;
    getRtuFilesJson<T>(rtuId: number, optionalArguments?: Args.FilesArguments): Promise<Rtus.RtuJsonFile<T>[]>;
    getRtuFile(rtuId: number, fileId: number): Promise<Rtus.RtuFile>;
    getRtuFileJson<T>(rtuId: number, fileId: number): Promise<Rtus.RtuJsonFile<T>>;
    getRtuFileNxb(rtuId: number, fileId: number): Promise<Rtus.NxbFile>;
    updateRtuFile(rtuId: number, fileId: number, file: Pick<Rtus.RtuFile, "RtuFileId" | "RtuId" | "RtuFileFolderId" | "Filename" | "FolderPath" | "Description">): Promise<void>;
    getRtuFolders(rtuId: number, optionalArguments?: Args.FoldersArguments): Promise<Rtus.RtuFileFolder[]>;
    getRtuTopFolders(rtuId: number): Promise<Rtus.RtuFileFolder[]>;
    getRtuFolder(rtuId: number, folderId: number): Promise<Rtus.RtuFileFolder>;
    updateRtuFolder(rtuId: number, folderId: number, folder: Rtus.RtuFileFolder): Promise<void>;
    getRtuFolderSubfolders(rtuId: number, folderId: number): Promise<Rtus.RtuFileFolder[]>;
    getRtuFolderLink(rtuId: number, folderId: number, optionalArguments?: Args.RtuFolderLinkArguments): string;
    getRtuFoldersLink(rtuId: number, folderIds?: number[], downloadFilename?: string): string;
    getRtuTextFileContents(rtuId: number, fileId: number, optionalArguments?: Args.RtuFileContentsArguments)
        : Promise<string>;
    getRtuJsonFileContents<T>(rtuId: number, fileId: number, optionalArguments?: Args.RtuFileContentsArguments)
        : Promise<T>;
    getRtuFileLink(rtuId: number, fileId: number, optionalArguments?: Args.RtuFileLinkArguments): string;
    getRtuFilesLink(rtuId: number, fileIds?: number[], folderIds?: number[], downloadFilename?: string): string;
    uploadRtuFiles(rtuId: number, metadata: Rtus.UploadedRtuFile[], files: FileList | File[], progressHandler?: (event: ProgressEvent) => void): Promise<Rtus.RtuFile[]>;
    overwriteRtuFiles(rtuId: number, metadata: Rtus.UploadedRtuFile[], files: FileList | File[], progressHandler?: (event: ProgressEvent) => void)
        : Promise<Rtus.RtuFile[]>;
    addRtuFileFolder(rtuId: number, name: string, path: string): Promise<number>;
    deleteRtuFile(rtuId: number, rtuFileId: number): Promise<void>;
    deleteRtuFiles(rtuId: number, rtuFileIds: number[]): Promise<void>;
    deleteRtuFileFolder(rtuId: number, rtuFileFolderId: number): Promise<void>;
    deleteRtuFileFolders(rtuId: number, rtuFileFolderIds: number[]): Promise<void>;
    processRtuFiles(rtuId: number, processRequest: Args.RtuFileProcessRequest): Promise<void>;
    reprocessRtuFiles(rtuId: number, reprocessRequest: Args.RtuFileReprocessRequest): Promise<void>;

    startLogin(startLoginRequest: Auth.StartLoginRequest): Promise<Auth.StartLoginResponse>;
    login(credentials: Auth.UserCredentials): Promise<Auth.AuthToken>;
    logout(): Promise<void>;
    getAuthLinks(): Promise<Links.AuthLinks>;
    changePassword(request: Auth.ChangePasswordRequest): Promise<void>;
    generatePasswordResetToken(request: Auth.GeneratePasswordResetRequest): Promise<void>;
    verifyPasswordReset(request: Auth.VerifyPasswordResetRequest): Promise<Auth.VerifyPasswordResetResponse>;
    checkPasswordStrength(request: Auth.PasswordStrengthRequest): Promise<Auth.PasswordStrengthResponse>;
    resetPassword(request: Auth.ResetPasswordRequest): Promise<void>;
    generateOtpAnonymous(request: Auth.GenerateUserOtpRequest): Promise<void>;

    getCurrentUser(
        optionalArguments?: Args.IncludeAppsArguments,
        includePermissions?: boolean,
        includeUserSettings?: boolean,
        includeCustomerSettings?: boolean,
        includeCustomerUserDataLabels?: boolean): Promise<Customers.CurrentUser>;
    getUser(username: string, optionalArguments?: Args.IncludeAppsArguments): Promise<Customers.User>;
    getUsers(customerIds?: number[], userGroupIds?: number[], includeApps?: boolean): Promise<Args.Collection<Customers.User>>;
    getUserAvailableEventFilter(username: string): Promise<Args.Collection<Events.EventLogType>>;
    getCurrentUserRtus(optionalArguments?: Args.AllRtusAndStatusAndLinkArguments, pagingOptions?: Args.PagingOptions, headers?: { [key: string]: string })
        : Promise<Args.PagedCollection<Rtus.Rtu>>;
    getUserSettings(username: string, keys?: string[]): Promise<{ [index: string]: string }>;
    getCurrentUserSettings(keys?: string[]): Promise<{ [index: string]: string }>;
    setUserSettings(username: string, settings: { [index: string]: string | number }): Promise<void>;
    setCurrentUserSettings(settings: { [index: string]: string | number }): Promise<void>;
    bulkSetUserSettings(request: Users.BulkUpdateUserSettingsRequest): Promise<void>;
    getCurrentUserApps(): Promise<{ [index: string]: Plugins.AppPlugin }>;
    getUserApps(username: string): Promise<{ [index: string]: Plugins.AppPlugin }>;
    updateCurrentUserPassword(request: Users.UpdateUserPasswordRequest): Promise<void>;
    updateUserPassword(username: string, request: Users.UpdateUserPasswordRequest): Promise<void>;
    checkCurrentUserPasswordStrength(): Promise<Auth.PasswordStrengthResponse>;
    checkUserPasswordStrength(username: string): Promise<Auth.PasswordStrengthResponse>;
    logoutUser(username: string): Promise<void>;
    updateUserLinkedCustomers(username: string, updateUserLinkedCustomersRequest: Users.UpdateUserLinkedCustomersRequest): Promise<void>;
    
    getUserMfaEnrollments(username: string): Promise<Args.Collection<Users.UserMfaEnrollment>>;
    startUserMfaEnrollment(username: string, request: Users.StartUserMfaEnrollmentRequest): Promise<Users.StartUserMfaEnrollmentResponse>;
    verifyUserMfaEnrollment(username: string, request: Users.VerifyUserMfaEnrollmentRequest): Promise<void>;
    disableUserMfaEnrollment(username: string, request: Users.DisableUserMfaEnrollmentRequest): Promise<void>;
    generateUserMfaRecoveryCodes(username: string): Promise<Users.UserMfaRecoveryCode[]>;
    deleteUserMfaRecoveryCodes(username: string): Promise<void>;
    generateUserOtp(username: string, request: Users.GenerateOtpRequest): Promise<void>;

    getCurrentUserLoginHistory(paging?: Args.PagingOptions): Promise<Args.Collection<Users.UserLoginHistory>>;
    getUserLoginHistory(username: string, paging?: Args.PagingOptions): Promise<Args.Collection<Users.UserLoginHistory>>;

    getCustomerIconSet(customerId: number, iconSetId: number) : Promise<Customers.IconSet>;
    getIconSet(iconSetId: number) : Promise<Customers.IconSet>;
    getCustomerIconSets(customerId: number): Promise<Args.Collection<Customers.IconSet>>;
    getIconSets(customerIds?: number[]) : Promise<Args.Collection<Customers.IconSet>>;
    addIconSet(customerId: number, iconSet: Customers.IconSet): Promise<number>;
    updateIconSet(customerId: number, iconSetId: number, iconSet: Customers.IconSet): Promise<void>;
    deleteIconSet(customerId: number, iconSetId: number): Promise<void>;

    getSystemStatus(detailed?: boolean): Promise<System.SystemStatus>;
    getReleaseNotes(): Promise<string>;
    getSystemTime(): Promise<string>;
    getSystemLinks(): Promise<Links.SystemLinks>;
    getSystemLimits(): Promise<System.SystemLimits>;
    getSystemServices(detailed?: boolean): Promise<Args.Collection<System.SystemService>>;
    getSystemBackups(): Promise<Args.Collection<System.SystemBackup>>;
    getSystemBackup(backupId: string): Promise<System.SystemBackup>;
    getSystemBackupDownloadLink(backupId: string): string;
    createSystemBackup(): Promise<void>;
    restoreSystemBackup(uploadedSystemBackup: System.UploadedSystemBackup, file: File, progressHandler?: (event: ProgressEvent) => void): Promise<void>;
    deleteSystemBackup(backupId: string): Promise<void>;
    getSystemBackupProgress(): Promise<System.SystemBackupProgress>;
    exportToCsv(csvContent: string, filename?: string): boolean;
    getServiceLogEvents(
        serviceType: Enums.SystemServiceType,
        serviceName: string,
        optionalArguments?: Args.ServiceLogEventsArguments
    ): Promise<Args.Collection<System.ServiceLogEvent>>;
    getServiceLogEventsDownloadLink(
        serviceType: Enums.SystemServiceType,
        serviceName: string,
        format: Enums.ServiceLogEventsFormat,
        optionalArguments?: Args.ServiceLogEventsArguments
    ): string;

    getSeries(getSeriesArgs: Args.GetSeriesArguments): Promise<TimeSeries.Series>;
    getSeriesCsvDownloadLink(getSeriesArgs: Args.GetSeriesArguments): string;
    findSeries(findSeriesArgs: Args.GetFindSeriesArguments): Promise<TimeSeries.Series>;
    getDurationCurve(getDurationCurveArgs: Args.GetDurationCurveArguments): Promise<TimeSeries.DurationCurve>;
    getHistogram(getHistogramArgs: Args.GetHistogramArguments): Promise<TimeSeries.Histogram>;
    getFrame(getFrameArgs: Args.GetFrameArguments): Promise<TimeSeries.Frame>;
    getFrameCsvDownloadLink(getFrameArgs: Args.GetFrameArguments): string;
    getSeriesForRtu(rtuId: number, getSeriesForRtuArgs: Args.GetSeriesForRtuArguments): Promise<TimeSeries.Series>;
    getSeriesForRtuCsvDownloadLink(rtuId: number, getSeriesForRtuArgs: Args.GetSeriesForRtuArguments): string;
    findSeriesForRtu(rtuId: number, findSeriesForRtuArgs: Args.GetFindSeriesForRtuArguments): Promise<TimeSeries.Series>;
    getDurationCurveForRtu(rtuId: number, getDurationCurveForRtuArgs: Args.GetDurationCurveForRtuArguments)
        : Promise<TimeSeries.DurationCurve>;
    getHistogramForRtu(rtuId: number, getHistogramForRtuArgs: Args.GetHistogramForRtuArguments): Promise<TimeSeries.Histogram>;
    getFrameForRtu(rtuId: number, getFrameForRtuArgs: Args.GetFrameForRtuArguments): Promise<TimeSeries.Frame>;
    getFrameForRtuCsvDownloadLink(rtuId: number, getFrameForRtuArgs: Args.GetFrameForRtuArguments): string;
    getPQDataTimeSeriesFrame(getPQDataTimeSeriesFrameArguments: Args.GetPQDataTimeSeriesFrameArguments): Promise<TimeSeries.Frame>;
    getPQDataTimeSeriesFrameCsvDownloadLink(getPQDataTimeSeriesFrameArguments: Args.GetPQDataTimeSeriesFrameArguments): string;

    getRtuNotes(rtuId: number, pinned?: boolean, includeDeleted?: boolean): Promise<Args.Collection<RtuNotes.RtuNote>>;
    getRtuNote(rtuId: number, noteId: number, includeDeleted?: boolean): Promise<RtuNotes.RtuNote>;
    getRtuNoteRevisions(rtuId: number, noteId: number): Promise<Args.Collection<RtuNotes.RtuNoteRevision>>;
    addRtuNote(rtuId: number, note: Pick<RtuNotes.RtuNote, "RtuId" | "Title" | "Text">): Promise<number>;
    editRtuNote(
        rtuId: number,
        noteId: number,
        note: Pick<RtuNotes.RtuNote, "RtuNoteId" | "RtuId" | "Title" | "Text" | "Revision">)
        : Promise<void>;
    deleteRtuNote(rtuId: number, noteId: number): Promise<void>;
    pinRtuNote(rtuId: number, noteId: number, pinRtuNoteRequest: RtuNotes.PinRtuNoteRequest): Promise<void>;

    getCustomerFiles(
        customerId: number,
        optionalArguments?: Args.CustomerFilesArguments
    ): Promise<Args.Collection<CustomerFiles.CustomerFile>>;
    getCustomerFilesDownloadLink(customerId: number, customerFileIds?: number[], folderIds?: number[], downloadFilename?: string): string;
    getCustomerFile(customerId: number, customerFileId: number): Promise<CustomerFiles.CustomerFile>;
    getCustomerFileDownloadLink(customerId: number, customerFileId: number, contentType?: Enums.FileContentType): string;
    copyCustomerFile(customerId: number, customerFileId: number, customerFileCopy: { CustomerIds: number[] })
        : Promise<number[]>;
    uploadCustomerFile(customerId: number, uploadedCustomerFile: CustomerFiles.UploadedCustomerFile, file: File, progressHandler?: (event: ProgressEvent) => void): Promise<number>;
    updateCustomerFile(customerId: number, customerFileId: number, customerFile: CustomerFiles.UpdateCustomerFileRequest): Promise<void>;
    deleteCustomerFile(customerId: number, customerFileId: number): Promise<void>;
    deleteCustomerFiles(customerId: number, customerFileIds: number[]): Promise<void>;

    getCustomerFileFolders(customerId: number, folderIds?: number[]): Promise<Args.Collection<CustomerFiles.CustomerFileFolder>>;
    getCustomerFileFolderDownloadLink(customerId: number, folderId: number, downloadFilename?: string): string;
    getCustomerFileFolder(customerId: number, folderId: number): Promise<CustomerFiles.CustomerFileFolder>;
    getCustomerFileFoldersDownloadLink(customerId: number, folderIds?: number[], downloadFilename?: string): string;
    addCustomerFileFolder(customerId: number, customerFileFolder: CustomerFiles.CustomerFileFolder): Promise<number>;
    updateCustomerFileFolder(customerId: number, folderId: number, customerFileFolder: CustomerFiles.CustomerFileFolder): Promise<void>;
    deleteCustomerFileFolder(customerId: number, folderId: number): Promise<void>;
    deleteCustomerFileFolders(customerId: number, folderIds: number[]): Promise<void>;

    getAllCustomerSchedules(args: CustomerSchedules.GetAllCustomerSchedulesArgs): Promise<Args.Collection<CustomerSchedules.CustomerSchedule>>;
    getCustomerSchedules(customerId: number, basicDetails?: boolean): Promise<Args.Collection<CustomerSchedules.CustomerSchedule>>;
    getCustomerSchedule(customerId: number, customerScheduleId: number, basicDetails?: boolean): Promise<CustomerSchedules.CustomerSchedule>;
    addCustomerSchedule(customerId: number, customerSchedule: CustomerSchedules.CustomerSchedule): Promise<number>;
    updateCustomerSchedule(customerId: number, customerScheduleId: number, customerSchedule: CustomerSchedules.CustomerSchedule)
        : Promise<void>;
    deleteCustomerSchedule(customerId: number, customerScheduleId: number): Promise<void>;

    getMapSources(): Promise<Args.Collection<Maps.MapSource>>;
    getMapSource(mapSourceId: number): Promise<Maps.MapSource>;
    addMapSource(request: Maps.AddMapSourceRequest): Promise<number>;
    updateMapSource(mapSourceId: number, request: Maps.UpdateMapSourceRequest): Promise<void>;
    deleteMapSource(mapSourceId: number): Promise<void>;
    deleteMapSources(request: Maps.DeleteMapSourcesRequest): Promise<void>;
    getMapSourceProxyUrl(mapSourceId: number): string;

    getNetworks(optionalArguments?: Args.GetNetworksArguments): Promise<Args.Collection<networks.Network>>;
    getNetwork(networkId: number, optionalArguments?: Args.GetNetworkArguments): Promise<networks.Network>;
    getNetworkDefinitionDownloadLink(networkId: number, optionalArguments?: Args.GetNetworkArguments): string;
    getNetworkBackgroundImageUrl(networkId: number, checksum: string): string;
    addNetwork(addNetworkRequest: networks.AddNetworkRequest): Promise<number>;
    updateNetwork(networkId: number, updateNetworkRequest: networks.UpdateNetworkRequest, partial?: boolean): Promise<void>;
    uploadNetworkDefinition(networkId: number, definition: File, processHandler?: (event: ProgressEvent) => void): Promise<void>;
    updateNetworkBackgroundImage(networkId: number, imageFile: File, processHandler?: (event: ProgressEvent) => void): Promise<void>;
    deleteNetwork(networkId: number): Promise<void>;
    deleteNetworkBackgroundImage(networkId: number): Promise<void>;

    getNetworkFaults(args?: networkFaults.GetNetworkFaultsArgs): Promise<Args.Collection<networkFaults.NetworkFault>>;
    getNetworkFault(networkFaultId: number, args?: networkFaults.GetNetworkFaultArgs): Promise<networkFaults.NetworkFault>;
    getNetworkFaultLocations(networkFaultId: number): Promise<Args.Collection<networkFaults.NetworkFaultLocation>>;
    getNetworkFaultLocation(networkFaultId: number, networkFaultLocationId: number): Promise<networkFaults.NetworkFaultLocation>;
    getNetworkFaultLocationPlotUrl(networkFaultId: number, networkFaultLocationId: number, contentType?: Enums.FileContentType): string;
    calculateNetworkFaultLocation(networkFaultId: number, request: networkFaults.CalculateNetworkFaultLocationRequest): Promise<number>;
    getNetworkFaultNotificationHistory(networkFaultId: number): Promise<Args.Collection<Notifications.Message>>;
    getNetworkFaultTimeSummaries(args: networkFaults.GetNetworkFaultTimeSummariesArgs): Promise<Args.Collection<networkFaults.NetworkFaultTimeSummary>>;
    getNetworkFaultTimeBucket(args: networkFaults.GetNetworkFaultTimeBucketArgs): Promise<networkFaults.NetworkFaultTimeBucket>;

    getNetworkSnapshots(networkId: number): Promise<Args.Collection<networks.NetworkSnapshot>>;
    getNetworkSnapshot(networkId: number, networkSnapshotId: number): Promise<networks.NetworkSnapshot>;
    getNetworkSnapshotDefinitionDownloadLink(networkId: number, networkSnapshotId: number): string;
    createNetworkSnapshot(networkId: number, createNetworkSnapshotRequest: networks.CreateNetworkSnapshotRequest): Promise<number>;
    deleteNetworkSnapshots(networkId: number, deleteNetworkSnapshotsRequest: networks.DeleteNetworkSnapshotsRequest): Promise<void>;
    revertToNetworkSnapshot(networkId: number, networkSnapshotId: number): Promise<void>;

    getNetworkFaultNotifications(networkId: number, includeAddressBookEntries?: boolean): Promise<Args.Collection<networks.NetworkFaultNotification>>;
    addNetworkFaultNotifications(networkId: number, request: networks.AddNetworkFaultNotificationsRequest): Promise<void>;
    bulkAddNetworkFaultNotifications(request: networks.AddNetworkFaultNotificationsRequest): Promise<void>;
    deleteNetworkFaultNotifications(networkId: number, request: networks.DeleteNetworkFaultNotificationsRequest): Promise<void>;
    bulkDeleteNetworkFaultNotifications(request: networks.DeleteNetworkFaultNotificationsRequest): Promise<void>;

    getUserPermissions(username: string): Promise<string[]>;
    getCurrentUserPermissions(): Promise<string[]>;

    getCertificates(certificateTypes?: Enums.CertificateType[], basicDetails?: boolean): Promise<Args.Collection<Certificates.Certificate>>;
    getCertificate(certificateId: number, basicDetails?: boolean): Promise<Certificates.Certificate>;
    getCertificateExportLink(certificateId: number): string;
    getCertificateDownloadLink(certificateId: number): string;
    uploadSystemCertificate(uploadedCertificate: Certificates.UploadedCertificate): Promise<number>;
    generateSystemCertificate(certificateSigningRequest: Certificates.CertificateSigningRequest): Promise<number>;
    deleteCertificate(certificateId: number): Promise<void>;
    getRtuCertificate(rtuId: number, basicDetails?: boolean): Promise<Certificates.Certificate>;
    getRtuCertificateDownloadLink(rtuId: number): string;
    uploadRtuCertificate(rtuId: number, uploadedCertificate: Certificates.UploadedCertificate): Promise<number>;
    generateRtuCertificate(rtuId: number, certificateSigningRequest: Certificates.CertificateSigningRequest)
        : Promise<Certificates.CertificateSigningResponse>;
    updateRtuCertificateStatus(rtuId: number, certificateStatusRequest: Certificates.CertificateStatusRequest): Promise<void>;

    getTrends(customerIds?: number[], trendIds?: number[], trendTypes?: Enums.TrendType[]): Promise<Args.Collection<Trends.Trend>>;
    getTrend(trendId: number): Promise<Trends.Trend>;
    addTrend(trend: Trends.Trend): Promise<number>;
    updateTrend(trendId: number, trend: Trends.Trend): Promise<void>;
    deleteTrend(trendId: number): Promise<void>;

    getRtuGroupTree(
        customerIds?: number[],
        rtuTypes?: Enums.RtuType[],
        protocols?: Enums.Protocol[],
        maxFirmwareVersion?: number,
        includeLocked?: boolean,
        includeOnline?: boolean,
        includeEmptyNodes?: boolean,
        includeSyncedRtus?: boolean)
        : Promise<(Rtus.RtuGroupTreeRtu | Rtus.RtuGroupTreeRtuGroup | Rtus.RtuGroupTreeCustomer)[]>;

    getVirtualRtus(getVirtualRtusArgs?: Args.GetVirtualRtusArguments): Promise<Args.Collection<VirtualRtus.VirtualRtu>>;
    getVirtualRtu(virtualRtuId: number, includeAdvancedSettings?: boolean, includePointSummary?: boolean): Promise<VirtualRtus.VirtualRtu>;
    updateVirtualRtu(virtualRtuId: number, request: VirtualRtus.UpdateVirtualRtuRequest): Promise<void>;
    deleteVirtualRtu(virtualRtuId: number): Promise<void>;
    deleteVirtualRtus(request: VirtualRtus.DeleteVirtualRtusRequest): Promise<void>;
    
    getVirtualRtuAdvancedSettings(virtualRtuId: number): Promise<VirtualRtus.VirtualRtuAdvancedSettings>;
    updateVirtualRtuAdvancedSettings(updateVirtualRtuAdvancedSettingsRequest: VirtualRtus.UpdateVirtualRtuAdvancedSettingsRequest): Promise<void>;
    
    getAllVirtualRtuPoints(getVirtualRtuPointsArgs?: Args.VirtualRtuPointsArguments | null): Promise<Args.PagedCollection<VirtualRtus.VirtualRtuPoint>>;
    getVirtualRtuPoints(virtualRtuId: number, rtuIds?: number[], virtualPointTypes?: Enums.VirtualPointType[], rtuFilter?: string, paging?: Args.PagingOptions, includeTotal?: boolean): Promise<Args.PagedCollection<VirtualRtus.VirtualRtuPoint>>;
    getVirtualRtuPointsForScadaChannel(scadaChannelId: number, virtualPointTypes?: Enums.VirtualPointType[], paging?: Args.PagingOptions, includeTotal?: boolean): Promise<Args.PagedCollection<VirtualRtus.VirtualRtuPoint>>;
    addVirtualRtuPoints(virtualRtuId: number, updateVirtualRtuAdvancedSettingsRequest: VirtualRtus.AddVirtualRtuPointsRequest): Promise<void>;
    deleteVirtualRtuPoints(request: VirtualRtus.DeleteVirtualRtuPointsRequest): Promise<void>;
    deleteVirtualRtuPointsForVirtualRtu(virtualRtuId: number, virtualRtuPointIds?: number[] | null): Promise<void>;
    autoNumberVirtualRtuPoints(virtualRtuId: number, request: VirtualRtus.AutoNumberVirtualRtuPointsRequest): Promise<void>;
    testVirtualRtuPoints(virtualRtuId: number, request: VirtualRtus.TestVirtualRtuPointsRequest): Promise<void>;
    getScadaChannelPointsDownloadLink(scadaChannelId: number): string;
    getCidDownloadLink(scadaChannelId: number): string;

    getVirtualRtuPointsDownloadLink(virtualRtuId: number): string;
    uploadVirtualRtuPoints(virtualRtuId: number, request: VirtualRtus.AddVirtualRtuPointsRequest, file: File, progressHandler?: (event: ProgressEvent) => void): Promise<void>;
    uploadScadaChannelPoints(scadaChannelId: number, request: VirtualRtus.AddScadaChannelPointsRequest, file: File, progressHandler?: (event: ProgressEvent) => void): Promise<void>;
    
    getVirtualRtuUnassignedRtuPoints(virtualRtuId: number, args: Args.GetVirtualRtuUnassignedRtuPointsArguments): Promise<Args.Collection<VirtualRtus.UnassignedVirtualRtuPoint>>;
    getVirtualRtuUnassignedSystemComponentPoints(virtualRtuId: number, optionalArgs?: Args.GetVirtualRtuUnassignedSystemComponentPointsArguments): Promise<Args.Collection<VirtualRtus.UnassignedVirtualRtuPoint>>;
    
    addScadaChannel(request: Scada.AddScadaChannelRequest): Promise<number>;
    getAllScadaChannels(scadaChannelArgs?: Args.ScadaChannelArguments): Promise<Args.Collection<Scada.ScadaChannel>>;
    getScadaChannels(scadaInterfaceId: number): Promise<Args.Collection<Scada.ScadaChannel>>;
    getScadaChannelById(scadaChannelId: number, includeAdvancedSettings?: boolean, includeSettings?: boolean): Promise<Scada.ScadaChannel>;
    getScadaChannel(scadaInterfaceId: number, scadaChannelId: number): Promise<Scada.ScadaChannel>;
    getScadaChannelAdvancedSettings(scadaChannelId: number): Promise<Scada.ScadaChannelAdvancedSettings>;
    getScadaInterfaceAdvancedSettings(scadaChannelId: number): Promise<Scada.ScadaInterfaceAdvancedSettings>;
    updateScadaChannel(scadaChannelId: number, request: Scada.UpdateScadaChannelRequest): Promise<void>;
    updateScadaChannelAdvancedSettings(request: Scada.UpdateScadaChannelAdvancedSettingsRequest): Promise<void>;
    deleteScadaChannel(scadaChannelId: number): Promise<void>;
    deleteScadaChannels(request: Scada.DeleteScadaChannelsRequest): Promise<void>;

    getScadaInterfaces(args?: Args.GetScadaInterfacesArgs): Promise<Args.Collection<Scada.ScadaInterface>>;
    getScadaInterface(scadaInterfaceId: number, args?: Args.GetScadaInterfaceArgs): Promise<Scada.ScadaInterface>;
    addScadaInterface(request: Scada.AddScadaInterfaceRequest): Promise<number>;
    updateScadaInterface(scadaInterfaceId: number, request: Scada.UpdateScadaInterfaceRequest): Promise<void>;
    updateScadaInterfaceAdvancedSettings(request: Scada.UpdateScadaInterfaceAdvancedSettingsRequest): Promise<void>;
    deleteScadaInterfaces(request: Scada.DeleteScadaInterfacesRequest): Promise<void>;
    deleteScadaInterface(scadaInterfaceId: number): Promise<void>;

    getCustomerMetricsValues(customerId: number, dateArguments?: Args.DateArguments, enabled?: boolean): Promise<Args.Collection<Metrics.CustomerMetricValue>>;
    getCustomerMetrics(enabled?: boolean): Promise<Args.Collection<Metrics.Metric>>;

    getSystemMetricsValues(dateArguments?: Args.DateArguments, enabled?: boolean): Promise<Args.Collection<Metrics.SystemMetricValue>>;
    getSystemMetrics(enabled?: boolean): Promise<Args.Collection<Metrics.Metric>>;

    getAddressBook(args?: AddressBookEntry.GetAddressBookArgs): Promise<Args.Collection<AddressBookEntry.AddressBookEntry>>;
    getAddressBookEntry(addressBookEntryId: number, args?: AddressBookEntry.GetAddressBookEntryArgs): Promise<AddressBookEntry.AddressBookEntry>;
    addAddressBookEntry(request: AddressBookEntry.AddAddressBookEntryRequest): Promise<number>;
    updateAddressBookEntry(addressBookEntryId: number, request: AddressBookEntry.UpdateAddressBookEntryRequest): Promise<void>;
    deleteAddressBookEntry(addressBookEntryId: number): Promise<void>;
    deleteAddressBookEntries(request: AddressBookEntry.DeleteAddressBookEntriesRequest): Promise<void>;
    testAddressBookEntry(addressBookEntryId: number): Promise<number>;
    testAddressBookEntries(request: AddressBookEntry.TestAddressBookEntriesRequest): Promise<void>;
    linkAddressBookEntry(addressBookEntryId: number, request: AddressBookEntry.LinkAddressBookEntryRequest): Promise<void>;
    moveAddressBookEntry(addressBookEntryId: number, request: AddressBookEntry.MoveAddressBookEntryRequest): Promise<void>;

    getSystemLogs(
        dateArguments?: Args.InclusiveDateArguments, 
        systemLogTypes?: Enums.SystemLogType[], 
        eventSortingArguments?: Args.SortingOptions<Enums.SystemLogSortColumn>,
        paging?: Args.PagingOptions): Promise<Args.Collection<SystemLog.SystemLog>>;
    getSystemLog(systemLogId: number): Promise<SystemLog.SystemLog>;
    deleteSystemLog(systemLogId: number): Promise<void>;
    acknowledgeSystemLog(systemLogId: number, ack: boolean): Promise<void>;
    acknowledgeAllSystemLogs(ack: boolean): Promise<void>;
    getSystemLogNotifications(customerIds?: number[], includeAddressBookEntries?: boolean): Promise<Args.Collection<SystemLog.SystemLogNotification>>;
    addSystemLogNotifications(request: SystemLog.AddSystemLogNotificationsRequest): Promise<void>;
    deleteSystemLogNotifications(request: SystemLog.DeleteSystemLogNotificationsRequest): Promise<void>;
    getSystemLogNotificationHistory(systemLogId: number): Promise<Args.Collection<Notifications.Message>>;

    getRtuTemplate(templateId: number, optionalArguments?: Args.IncludeTemplateSyncLinksArguments & Args.IncludePointSummaryArguments): Promise<Rtus.Rtu>;

    getTaskManagers(): Promise<Args.Collection<TaskManager.TaskManager>>;
    getTaskManager(taskManagerId: number): Promise<TaskManager.TaskManager>;

    getTaskManagerTasks(taskManagerId: number): Promise<Args.Collection<TaskManager.TaskModule>>;
    getTaskManagerTask(taskManagerId: number, taskId: number): Promise<TaskManager.TaskModule>;
    getTaskManagerSettings(taskManagerId: number): Promise<Args.Collection<TaskManager.TaskManagerSetting>>;
    getTaskModuleSettings(taskManagerId: number, taskId: number): Promise<Args.Collection<TaskManager.TaskModuleSetting>>;

    getCsms(includeCsmLines?: boolean): Promise<Args.Collection<Csm.Csm>>;
    getCsm(csmId: number, includeCsmLines?: boolean): Promise<Csm.Csm>;
    getCsmLines(csmId: number): Promise<Args.Collection<Csm.CsmLine>>;
    getCsmLine(csmId: number, csmLineId: number): Promise<Csm.CsmLine>;
    getCsmSettings(csmId: number): Promise<Args.Collection<Csm.CsmSetting>>;
    getCsmLineSettings(csmId: number, csmLineId: number): Promise<Args.Collection<Csm.CsmLineSetting>>;

    copyRtuSettings(rtuId: number, copySettingsRequest: Rtus.CopyRtuSettingsRequest): Promise<Rtus.CopyRtuSettingsResponse>;
    bulkImportRtus(rtuBulkImportRequest: Rtus.RtuBulkImportRequest, file: File, progressHandler?: (event: ProgressEvent) => void): Promise<Rtus.RtuBulkImportResponse>;

    getRtuTemplateSyncLinks(templateId: number): Promise<Args.Collection<Rtus.RtuTemplateSyncLink>>;
    getRtuTemplateSyncLink(rtuId: number, templateId: number): Promise<Rtus.RtuTemplateSyncLink>;
    getAssignableRtusForTemplateSync(templateId: number): Promise<Args.Collection<Rtus.Rtu>>;
    addRtuTemplateSyncLink(rtuId: number, templateId: number, link: Rtus.RtuTemplateSyncLink): Promise<void>;
    deleteRtuTemplateSyncLink(rtuId: number, templateId: number): Promise<void>;
    deleteRtuTemplateSyncLinks(templateId: number, links: Rtus.RtuTemplateSyncLink[]): Promise<void>;

    getOutputOperations(
        rtuId: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions)
        : Promise<Args.PagedCollection<Inputs.OutputOperation>>;

    addVirtualRtu(virtualRtu: VirtualRtus.CreateVirtualRtuRequest): Promise<number>;

    getUserLoginDetails(): Promise<Customers.UserLoginDetails>;

    getReports(customerId: number, linked?: boolean): Promise<Args.Collection<Reports.Report>>;
    getReport(reportId: number, customerId: number): Promise<Reports.Report>;
    importReport(reportId: number, customerId: number): Promise<void>;
    unlinkReport(reportId: number, customerId: number): Promise<void>;
    unlinkReports(removeReportsRequest: Reports.RemoveReportsRequest): Promise<void>;
    getReportQueueEntries(
        customerId: number,
        dateArguments?: Args.DateArguments,
        includeAuthorDetails?: boolean,
        includeModifiedByDetails?: boolean,
        includeNotifications?: boolean,
        includeUnprocessedReports?: boolean,
        paging?: Args.PagingOptions,
        sorting?: Args.SortingOptions<Enums.ReportQueueSortColumn>
        ): Promise<Args.Collection<Reports.ReportQueueEntry>>;
    getReportQueueEntry(
        entryId: number, 
        includeAuthorDetails?: boolean, 
        includeModifiedByDetails?: boolean,
        includeNotifications?: boolean): Promise<Reports.ReportQueueEntry>;
    getReportQueueEntryStatus(entryId: number): Promise<Enums.ReportRunStatus>;
    deleteReportQueueEntry(entryId: number): Promise<void>;
    deleteReportQueueEntries(entryIds: number[]): Promise<void>;
    queueReport(queueReportRequest: Reports.QueueReportRequest): Promise<number>;
    updateQueuedReport(entryId: number, queueReportRequest: Reports.QueueReportRequest): Promise<void>;
    addReportNotifications(request: Reports.AddReportNotificationsRequest): Promise<void>;
    deleteReportNotifications(request: Reports.DeleteReportNotificationsRequest): Promise<void>;
    getReportFiles(
        fileIds?: number[],
        customerId?: number, 
        reportId?: number, 
        reportQueueEntryId?: number, 
        dateArguments?: Args.DateArguments,
        includeAuthorDetails?: boolean,
        paging?: Args.PagingOptions,
        sorting?: Args.SortingOptions<Enums.ReportFileSortColumn>
    ): Promise<Args.Collection<Reports.ReportFile>>;
    getReportFilesLink(fileIds: number[]): string;
    getReportFile(fileId: number, includeAuthorDetails: boolean): Promise<Reports.ReportFile>;
    getReportFileLink(fileId: number): string;
    deleteReportFile(fileId: number): Promise<void>;
    deleteReportFiles(fileIds: number[]): Promise<void>;

    getRtuEventNotifications(rtuId: number, includeAddressBookEntries?: boolean): Promise<Args.Collection<Notifications.EventNotification>>;
    addRtuEventNotifications(rtuId: number, request: Notifications.AddEventNotificationsRequest): Promise<void>;
    bulkAddRtuEventNotifications(request: Notifications.BulkAddRtuEventNotificationsRequest): Promise<void>;
    updateRtuEventNotifications(rtuId: number, request: Notifications.UpdateEventNotificationsRequest): Promise<void>;
    moveRtuEventNotifications(rtuId: number, request: Notifications.MoveEventNotificationsRequest): Promise<void>;
    copyRtuEventNotifications(rtuId: number, request: Notifications.CopyEventNotificationsRequest): Promise<void>;
    copyAllRtuEventNotifications(rtuId: number, request: Notifications.CopyAllNotificationsRequest): Promise<void>;
    deleteRtuEventNotifications(rtuId: number, request: Notifications.DeleteEventNotificationsRequest): Promise<void>;
    bulkDeleteRtuEventNotifications(request: Notifications.DeleteEventNotificationsRequest): Promise<void>;

    uploadFirmware(rtuFirmwareUpload: Rtus.RtuFirmwareUpload, file: File): Promise<Rtus.RtuFirmwareUploadSummary>;
    deleteRtuFirmware(request: Rtus.DeleteRtuStoredFirmwareRequest): Promise<number>;

    uploadConfig(rtuConfigUpload: Rtus.RtuConfigUpload, files: FileList | File[]): Promise<Rtus.RtuConfigUploadSummary>;
    deleteRtuConfig(request: Rtus.DeleteRtuStoredConfigRequest): Promise<number>;

    getRtuConfigFile(rtuId: number): Promise<Rtus.RtuConfig>;

    getUserGroup(userGroupId: number, includePermissions?: boolean, includeUsers?: boolean): Promise<UserGroup.UserGroup>;
    getUserGroups(customerIds?: number[], includePermissions?: boolean, includeUsers?: boolean, assignableOnly?: boolean): Promise<Args.Collection<UserGroup.UserGroup>>;

    getOverviews(customerIds?: number[]): Promise<Args.Collection<Plugins.OverviewPlugin>>;
    getMimics(enabled?: boolean): Promise<Args.Collection<Plugins.WebPlugin>>;
    getApp(appId: string): Promise<Plugins.AppPlugin>;

    getEventRecordingData(rtuFileId: number, standardiseUnits?: boolean, channelIndexes?: number[], eventRecordingFormat?: Enums.EventRecordingFormat, includeOnlyDigitalChanges?: boolean, triggerTimeOverride?: string): Promise<EventRecordings.EventRecordingData>;
    getEventRecordingDataFileLink(rtuFileId: number, standardiseUnits?: boolean, channelIndexes?: number[], eventRecordingFormat?: Enums.EventRecordingFormat, includeOnlyDigitalChanges?: boolean, triggerTimeOverride?: string): string;
    calculateEventRecordingData(eventRecordingCalculationRequest: EventRecordings.EventRecordingCalculationRequest): Promise<EventRecordings.EventRecordingCalculationData>;
    getEventRecording(rtuFileId: number): Promise<EventRecordings.EventRecordingFile>;
    getEventRecordingZipFileLink(rtuFileId: number): string;
    getEventRecordings(rtuIds: number[], dateArguments: Args.DateArguments, rtuFileIds?: number[], paging?: Args.PagingOptions): Promise<Args.PagedCollection<EventRecordings.EventRecordingFile>>;

    getBmus(
        customerId?: number,
        topRtuGroupId?: number,
        bmuIdFilter?: string,
        mappedVirtualRtuNamePattern?: string,
        bmuStateFilter?: Enums.BmuState,
        minTimestamp?: string,
        sortingArguments?: Args.SortingOptions<Enums.BmuField>,
        paging?: Args.PagingOptions): Promise<Args.PagedCollection<Bmus.Bmu>>;

    getPQEvents(
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        pqEventTypes?: Enums.PQEventTypes,
        dateArguments?: Args.DateArguments,
        toTimeInclusive?: boolean,
        includeDisabledPoints?: boolean,
        includeParked?: boolean,
        nominalVoltages?: number[],
        sortOrder?: Enums.SortOrder,
        paging?: Args.PagingOptions
    ): Promise<Args.Collection<PQEvents.PQEvent>>;

    getPQEventTimeSummaries(
        dateArguments: Args.DateArguments,
        bucketWidthSeconds: number,
        searchFromTime?: string,
        searchToTime?: string,
        customerId?: number,
        topRtuGroupId?: number,
        pqEventCountOptions?: Enums.PQEventCountOptions,
        pqEventTypes?: Enums.PQEventTypes,
        includeRtu?: boolean,
        includeRtuGroup?: boolean,
        removeEmptyGroups?: boolean,
        removeEmptyBuckets?: boolean,
        includeDisabledPoints?: boolean,
    ): Promise<Args.Collection<PQEvents.PQEventTimeSummary>>;

    getPQEventTimeBucket(
        fromTime: string,
        bucketWidthSeconds: number,
        searchFromTime?: string,
        searchToTime?: string,
        rtuId?: number,
        rtuGroupId?: number,
        pqEventCountOptions?: Enums.PQEventCountOptions,
        pqEventTypes?: Enums.PQEventTypes,
        includePQEvents?: boolean,
        includeEventRecordings?: boolean,
        includeDisabledPoints?: boolean,
        pqEventsSortOrder?: Enums.SortOrder,
        pqEventsPaging?: Args.PagingOptions 
    ): Promise<PQEvents.PQEventTimeBucket>;

    updatePrimaryCustomer(username: string, rtuGroup: Users.MoveUserRequest): Promise<void>;

    getCustomerNotes(customerId: number, pinned?: boolean, includeDeleted?: boolean): Promise<Args.Collection<CustomerNotes.CustomerNote>>;
    getCustomerNote(customerId: number, noteId: number, includeDeleted?: boolean): Promise<CustomerNotes.CustomerNote>;
    getCustomerNoteRevisions(customerId: number, noteId: number): Promise<Args.Collection<CustomerNotes.CustomerNoteRevision>>;
    addCustomerNote(customerId: number, note: Pick<CustomerNotes.CustomerNote, "CustomerId" | "Title" | "Text">): Promise<number>;
    editCustomerNote(customerId: number, noteId: number, note: Pick<CustomerNotes.CustomerNote, "CustomerNoteId" | "CustomerId" | "Title" | "Text" | "Revision">): Promise<void>;
    deleteCustomerNote(customerId: number, noteId: number): Promise<void>;
    pinCustomerNote(customerId: number, noteId: number, pinCustomerNoteRequest: CustomerNotes.PinCustomerNoteRequest): Promise<void>;

    getUserAccessToken(username: string, userAccessTokenName: string): Promise<Users.UserAccessToken>;
    getUserAccessTokens(username: string): Promise<Args.Collection<Users.UserAccessToken>>;
    addUserAccessToken(username: string, addUserAccessTokenRequest: Users.AddUserAccessTokenRequest): Promise<Users.NewUserAccessToken>;
    deleteUserAccessToken(username: string, userAccessTokenName: string): Promise<void>;

    getShapeTemplates(customerIds?: number[]): Promise<Args.Collection<ShapeTemplates.ShapeTemplate>>;
    getShapeTemplate(shapeTemplateId: number): Promise<ShapeTemplates.ShapeTemplate>;
    renderShapeTemplate(shapeTemplateId: number, renderShapeTemplateRequest?: ShapeTemplates.RenderShapeTemplateRequest): Promise<ShapeTemplates.ShapeData>;
    addShapeTemplate(addShapeTemplateRequest: ShapeTemplates.AddShapeTemplateRequest): Promise<number>;
    updateShapeTemplate(shapeTemplateId: number, updateShapeTemplateRequest: ShapeTemplates.UpdateShapeTemplateRequest): Promise<void>;
    deleteShapeTemplate(shapeTemplateId: number): Promise<void>;

    getNotificationTemplates(args: Notifications.GetNotificationTemplatesArgs): Promise<Args.Collection<Notifications.NotificationTemplate>>;
    getNotificationTemplate(notificationTemplateId: number, basicDetails?: boolean): Promise<Notifications.NotificationTemplate>;
    addNotificationTemplate(addNotificationTemplateRequest: Notifications.AddNotificationTemplateRequest): Promise<number>;
    updateNotificationTemplate(notificationTemplateId: number, updateNotificationTemplateRequest: Notifications.UpdateNotificationTemplateRequest): Promise<void>;
    deleteNotificationTemplate(notificationTemplateId: number): Promise<void>;
    deleteNotificationTemplates(notificationTemplateIds: number[]): Promise<void>;

    getMessageApis(messageApiTypes?: Enums.MessageApiType[], enabled?: boolean, isDefault?: boolean): Promise<Args.Collection<Notifications.MessageApi>>;
    getMessageApi(messageApiId: number): Promise<Notifications.MessageApi>;
    addMessageApi(addMessageApiRequest: Notifications.AddMessageApiRequest): Promise<number>;
    updateMessageApi(messageApiId: number, updateMessageApiRequest: Notifications.UpdateMessageApiRequest): Promise<void>;
    deleteMessageApi(messageApiId: number): Promise<void>;
    deleteMessageApis(messageApiIds: number[]): Promise<void>;

    getLoadSurveyStudies(customerId: number, authors?: string[], includeMeasurementPointDetails?: boolean): Promise<Args.Collection<LoadSurveys.Study>>;
    getLoadSurveyStudy(customerId: number, studyId: string, includeMeasurementPointDetails?: boolean): Promise<LoadSurveys.Study>;
    getLoadSurveyStudyCsvDownloadLink(customerId: number, studyId: string): string;
    addLoadSurveyStudy(customerId: number, study: LoadSurveys.AddStudyRequest): Promise<string>;
    updateLoadSurveyStudy(customerId: number, studyId: string, study: LoadSurveys.UpdateStudyRequest, partial?: boolean): Promise<void>;
    uploadLoadSurveyStudyCsv(customerId: number, studyId: string, csvFile: File, validationOnly?: boolean): Promise<void>;
    deleteLoadSurveyStudy(customerId: number, studyId: string): Promise<void>;
    getLoadSurveySites(customerId: number, studyId: string, searchText?: string, siteAddressIds?: string[], paging?: Args.PagingOptions): Promise<Args.Collection<LoadSurveys.Site>>;
    getLoadSurveySite(customerId: number, studyId: string, siteAddressId: string): Promise<LoadSurveys.Site>;
    getLoadSurveySiteAnalogues(customerId: number, studyId: string, siteAddressId: string, analogueNumbers?: number[]): Promise<Args.Collection<LoadSurveys.Analogue>>;
    getLoadSurveySiteAnalogue(customerId: number, studyId: string, siteAddressId: string, analogueNumber: number): Promise<LoadSurveys.Analogue>;
    getLoadSurveyAnalogueSeries(
        customerId: number,
        studyId: string,
        siteAddressId: string,
        analogueNumber: number,
        multiplier?: number,
        polarityStartTime?: string,
        polarityEndTime?: string,
        endTimeInclusive?: boolean,
        precision?: number): Promise<LoadSurveys.LoadSurveySeries>;
    getLoadSurveyMeasurementPointFrame(
        customerId: number,
        studyId: string,
        measurementPointId: number,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        includeGeneration?: boolean,
        endTimeInclusive?: boolean,
        precision?: number): Promise<LoadSurveys.LoadSurveyMeasurementPointFrame>;
    getLoadSurveyMeasurementPointsFrame(
        customerId: number,
        studyId: string,
        measurementPointIds?: number[],
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        includeGeneration?: boolean,
        endTimeInclusive?: boolean,
        precision?: number): Promise<LoadSurveys.LoadSurveyMeasurementPointsFrame>;
    getLoadSurveySiteTopology(customerId: number, studyId: string): Promise<Args.Collection<LoadSurveys.TopologyDataNode>>;
    getLoadSurveySiteTopologyFrame(
        customerId: number,
        studyId: string,
        topologyDataNodeId: string,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        includeGeneration?: boolean,
        endTimeInclusive?: boolean,
        precision?: number): Promise<LoadSurveys.LoadSurveyTopologyDataFrame>;
    getLoadSurveySiteTopologyPeaks(
        customerId: number,
        studyId: string,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        endTimeInclusive?: boolean,
        precision?: number,
        maximumDepth?: LoadSurveys.NodeType): Promise<Args.Collection<LoadSurveys.LoadSurveyTopologyDataFrame>>;
    getLoadSurveySiteTopologyParentChildPeaks(
        customerId: number,
        studyId: string,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        endTimeInclusive?: boolean,
        precision?: number,
        maximumDepth?: LoadSurveys.NodeType): Promise<Args.Collection<LoadSurveys.LoadSurveyTopologyDataFrame>>;
    getLoadSurveySiteTopologyValues(
        customerId: number,
        studyId: string,
        dateTimes: string[],
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        precision?: number,
        maximumDepth?: LoadSurveys.NodeType): Promise<Args.Collection<LoadSurveys.LoadSurveyTopologyDataFrame>>;

    getPQSummaryStats(
        measurandSet: Enums.PQMeasurandSet,
        startTime: string,
        endTime: string,
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        reducer?: Enums.AggregationMode,
        valueScaling?: Enums.PQValueScaling,
        phases?: Enums.PQPhase,
        flickerMeasurands?: Enums.PQFlickerMeasurand,
        harmonics?: string,
        includeParked?: boolean,
        hasLocation?: boolean
    ): Promise<Args.Collection<PQData.PQSummaryStats>>;

    getPQActivityData(
        activityTypes: Enums.PQActivityTypes,
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        eventTypes?: Enums.PQEventTypes,
        measurandLimitAlarms?: Enums.PQMeasurandLimitAlarms,
        timeRange?: Enums.PQTimeRange,
        includeParked?: boolean,
        nominalVoltages?: number[],
        hasLocation?: boolean
    ): Promise<Args.Collection<PQData.PQActivity>>;

    getPQSummation(
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        eventTypes?: Enums.PQEventTypes,
        measurandLimitAlarms?: Enums.PQMeasurandLimitAlarms,
        includeParked?: boolean,
        nominalVoltages?: number[]
    ): Promise<PQData.PQSummation>;

    cancelPendingOutputOperations(request: Rtus.CancelPendingOutputOperationsRequest): Promise<void>;
    generateRtuConfig(rtuId: number, request: Rtus.RtuConfigGenerationRequest): Promise<void>;
    generateRtuConfigs(request: Rtus.BulkRtuConfigGenerationRequest): Promise<void>;

    getEventQueues(includeEventCount?: boolean): Promise<Args.Collection<EventQueues.EventQueue>>;
    getEventQueue(eventQueueId: number, includeEventCount?: boolean): Promise<EventQueues.EventQueue>;
    getEventQueueStats(eventQueueId: number): Promise<EventQueues.EventQueueStatistics>;
    getEventQueueFilter(eventQueueId: number): Promise<Args.Collection<Events.EventLogType>>;
    updateEventQueue(eventQueueId: number, request: EventQueues.UpdateEventQueueRequest): Promise<void>;
    
    getOperationalMeteringTotals(): Promise<OpsMetering.OpsMeteringTotals>;

    getIdentityProviders(): Promise<Args.Collection<IdentityProviders.IdentityProvider>>;
    getIdentityProvider(identityProviderId: number): Promise<IdentityProviders.IdentityProvider>;
    addIdentityProvider(addIdentityProviderRequest: IdentityProviders.AddIdentityProviderRequest): Promise<number>;
    updateIdentityProvider(identityProviderId: number, updateIdentityProviderRequest: IdentityProviders.UpdateIdentityProviderRequest): Promise<void>;
    deleteIdentityProvider(identityProviderId: number): Promise<void>;
    getConsumeSaml2ResponseUrl(identityProviderUuid: string): string;

    getRtuApplications(rtuId: number): Promise<Args.Collection<Rtus.RtuApplication>>;
    getRtuApplication(rtuId: number, applicationName: string): Promise<Rtus.RtuApplication>;
    getRtuApplicationImage(rtuId: number, applicationName: string): Promise<Rtus.RtuApplicationImage>;
    getRtuApplicationImageDownloadLink(rtuId: number, applicationName: string): string;
    getRtuApplicationConfig(rtuId: number, applicationName: string): Promise<Rtus.RtuApplicationConfig>;
    getRtuApplicationConfigDownloadLink(rtuId: number, applicationName: string): string;
    addRtuApplication(
        rtuId: number,
        addRtuApplicationRequest: Rtus.AddRtuApplicationRequest,
        imageFile: File,
        configFile: File,
        progressHandler?: (event: ProgressEvent) => void
    ): Promise<void>;
    updateRtuApplicationImage(
        rtuId: number,
        applicationName: string,
        updateRtuApplicationImageRequest: Rtus.UpdateRtuApplicationImageRequest,
        file: File,
        progressHandler?: (event: ProgressEvent) => void
    ): Promise<void>;
    updateRtuApplicationConfig(
        rtuId: number,
        applicationName: string,
        updateRtuApplicationConfigRequest: Rtus.UpdateRtuApplicationConfigRequest,
        file: File,
        progressHandler?: (event: ProgressEvent) => void
    ): Promise<void>;
    deleteRtuApplication(rtuId: number, applicationName: string): Promise<void>;

    getLVDevices(args: Args.GetLVDevicesArgs): Promise<Args.Collection<LVData.LVDevice>>;
    getLVDevice(rtuId: number): Promise<LVData.LVDevice>;
    getLVMeasurements(rtuId: number, args: Args.GetLVMeasurementsArgs): Promise<Args.Collection<LVData.LVMeasurement>>;
    getLVAlarms(rtuId: number, args: Args.GetLVAlarmsArgs): Promise<Args.Collection<LVData.LVAlarm>>;

    getEventStreamingServices(): Promise<Args.Collection<EventStreamingServices.EventStreamingService>>;
    getEventStreamingService(eventStreamingServiceId: number): Promise<EventStreamingServices.EventStreamingService>;
    addEventStreamingService(request: EventStreamingServices.AddEventStreamingServiceRequest): Promise<void>;
    updateEventStreamingService(request: EventStreamingServices.UpdateEventStreamingServiceRequest): Promise<void>;
    deleteEventStreamingServices(request: EventStreamingServices.DeleteEventStreamingServicesRequest): Promise<void>;
    deleteEventStreamingService(eventStreamingServiceId: number): Promise<void>;

    getEventStreamingChannels(eventStreamingServiceIds?: number[]): Promise<Args.Collection<EventStreamingServices.EventStreamingChannel>>;
    getEventStreamingChannel(eventStreamingChannelId: number): Promise<EventStreamingServices.EventStreamingChannel>;
    addEventStreamingChannel(request: EventStreamingServices.AddEventStreamingChannelRequest): Promise<void>;
    updateEventStreamingChannel(request: EventStreamingServices.UpdateEventStreamingChannelRequest): Promise<void>;
    deleteEventStreamingChannels(request: EventStreamingServices.DeleteEventStreamingChannelsRequest): Promise<void>;
    deleteEventStreamingChannel(eventStreamingChannelId: number): Promise<void>;

    getEventStreamingProducers(eventStreamingProducerIds?: number[]): Promise<Args.Collection<EventStreamingServices.EventStreamingProducer>>;
    getEventStreamingProducer(eventStreamingProducerId: number): Promise<EventStreamingServices.EventStreamingProducer>;
    addEventStreamingProducer(request: EventStreamingServices.AddEventStreamingProducerRequest): Promise<void>;
    updateEventStreamingProducer(request: EventStreamingServices.UpdateEventStreamingProducerRequest): Promise<void>;
    deleteEventStreamingProducers(request: EventStreamingServices.DeleteEventStreamingProducersRequest): Promise<void>;
    deleteEventStreamingProducer(eventStreamingProducerId: number): Promise<void>;
}

export default class IHostWebApi implements IHostApi {

    public defaultPageNumber: number = 0;
    public defaultPageSize: number = 100;
    
    constructor(public urlRoot: string, defaultPageSize?: number) {
        this.defaultPageSize = defaultPageSize || this.defaultPageSize;
    }

    /**
     * Returns an object where all paging options are specified with defaults
     * used if they were null/undefined in pagingOptions.
     * @param pagingOptions the user supplied paging options
     * @returns pagingOptions with defaults added if they didnt already exist
     */
    private getPagingOptions(pagingOptions?: Args.PagingOptions): Args.PagingOptions {
        return $.extend({}, {
            page: this.defaultPageNumber,
            pageSize: this.defaultPageSize,
        }, pagingOptions);
    }

    private ajaxRequest<T>(
        url: string, 
        data?: any, 
        type?: string, 
        cache: boolean = false, 
        dataType: string = "json", 
        progressHandler?: (event: ProgressEvent) => void, 
        headers?: { [key: string]: string }) {
        let ajax = $.ajax({
            cache,
            data,
            dataType,
            type: type || "GET",
            url: this.urlRoot + url,
            headers: headers,
            // silently convert empty string to null so 204 (No Content) responses succeed
            dataFilter: dataType === "json"
                ? responseText => responseText !== "" ? responseText : null
                : void 0
        });
        if (progressHandler) {
            ajax = ajax.progress(progressHandler);
        }

        return Promise.resolve<T>(ajax);
    }

    private postRequest<T>(url: string, dataType = "json", progressHandler?: (event: ProgressEvent) => void) {
        return this.ajaxRequest<T>(url, null, "POST", false, dataType, progressHandler);
    }

    private putRequest<T>(url: string, dataType = "json", progressHandler?: (event: ProgressEvent) => void) {
        return this.ajaxRequest<T>(url, null, "PUT", false, dataType, progressHandler);
    }

    private deleteRequest<T>(url: string, dataType = "json", progressHandler?: (event: ProgressEvent) => void) {
        return this.ajaxRequest<T>(url, null, "DELETE", false, dataType, progressHandler);
    }

    private postJson<T>(url: string, data: any, dataType = "json") {
        return this.sendJson<T>(url, data, "POST", dataType);
    }

    private putJson<T>(url: string, data: any, dataType = "json") {
        return this.sendJson<T>(url, data, "PUT", dataType);
    }

    private deleteJson<T>(url: string, data: any, dataType = "json") {
        return this.sendJson<T>(url, data, "DELETE", dataType);
    }

    private sendJson<T>(url: string, data: any, method: string, dataType: string) {
        return Promise.resolve<T>($.ajax({
            contentType: "application/json; charset=UTF-8",
            data: JSON.stringify(data),
            dataType,
            type: method,
            url: this.urlRoot + url,
            // silently convert empty string to null so 204 (No Content) responses succeed
            dataFilter: dataType === "json"
                ? responseText => responseText !== "" ? responseText : null
                : void 0
        }));
    }

    private postFormData<T>(url: string, data: FormData, dataType = "json", progressHandler?: (event: ProgressEvent) => void) {
        return this.sendFormData<T>(url, data, "POST", dataType, progressHandler);
    }

    private putFormData<T>(url: string, data: FormData, processHandler?: (event: ProgressEvent) => void) {
        return this.sendFormData<T>(url, data, "PUT", void 0, processHandler);
    }

    private sendFormData<T>(url: string, data: FormData, method: string, dataType = "json", progressHandler?: (event: ProgressEvent) => void): Promise<T> {
        return Promise.resolve<T>($.ajax({
            contentType: false,
            data,
            dataType,
            processData: false,
            type: method,
            url: this.urlRoot + url,
            xhr: () => {
                const xhr = new XMLHttpRequest();
                if (xhr.upload && progressHandler) {
                    xhr.upload.onprogress = progressHandler;
                }
                return xhr;
            },
        }));
    }

    private sendRtuFiles<T>(
        method: string,
        rtuId: number,
        metadata: Rtus.UploadedRtuFile[],
        files: FileList | File[],
        progressHandler?: (event: ProgressEvent) => void) {
        const rtuFilesMetadata = metadata.map((m) => $.extend({}, m, { RtuId: rtuId }));
        const formData = IHostWebApi.createFileFormData(rtuFilesMetadata, "metadata", files, "files");
        return this.sendFormData<T>("/rtus/" + rtuId + "/files", formData, method, void 0, progressHandler);
    }

    private static flattenSettings(settings: { [index: string]: any }) {
        const flattenedSettings: { [index: string]: string } = {};
        for (const prop in settings) {
            if (settings.hasOwnProperty(prop)) {
                flattenedSettings[prop] = IHostWebApi.flattenSetting(settings, prop);
            }
        }
        return flattenedSettings;
    }

    private static flattenSetting(settings: { [index: string]: any }, prop: any) {
        return typeof settings[prop] !== "string" ? JSON.stringify(settings[prop]) : settings[prop];
    }

    /**
     * Create FormData for multiple files, each with the same content name.
     */
    private static createFileFormData(data: any, dataContentName: string, files: FileList | File[], fileContentName: string) {
        const formData = new FormData();
        formData.append(dataContentName, new Blob([JSON.stringify(data)], { type: "application/json" }), "");
        for (let i = 0; i < files.length; ++i) {
            formData.append(fileContentName, files[i], files[i].name);
        }
        return formData;
    }

    /**
     * Create FormData for multiple files, each with a different content name.
     */
    private static createNamedFileFormData(data: any, dataContentName: string, fileParts: Interfaces.MultipartFilePart[]) {
        const formData = new FormData();
        formData.append(dataContentName, new Blob([JSON.stringify(data)], { type: "application/json" }), "");
        for (let i = 0; i < fileParts.length; ++i) {
            const filePart = fileParts[i];
            formData.append(filePart.PartName, filePart.File, filePart.File.name);
        }
        return formData;
    }

    private static createFileForm(file: File): FormData {
        const formData = new FormData();
        formData.append("file", file, file.name);
        return formData;
    }

    private getRtuFileWithArgs<T>(rtuId: number, fileId: number, optionalArguments?: Args.DownloadAsJsonArguments) {
        const args: Args.ContentTypeArguments = {};
        if (optionalArguments && optionalArguments.downloadAsJson) {
            args.contentType = "json";
        }
        return this.ajaxRequest<T>(`/rtus/${rtuId}/files/${fileId}`, args);
    }

    private getFolderContentTypeAsString(contentType: Enums.FolderContentType): string {
        switch (contentType) {
            case Enums.FolderContentType.Zip:
                return "zip";
            default:
                return "";
        }
    }

    private getFileContentTypeAsString(contentType: number): string {
        switch (contentType) {
            case Enums.FileContentType.Raw:
                return "raw";
            case Enums.FileContentType.Zip:
                return "zip";
            case Enums.FileContentType.UnZip:
                return "unzip";
            case Enums.FileContentType.Json:
                return "json";
            case Enums.FileContentType.Svg:
                return "svg";
            default:
                return "";
        }
    }

    /** Creates a query string with both required and optional arguments.
     * @param requiredArgs The arguments that must be specified.
     * @param optionalArgs The arguments that are optional. These are omitted if their value is undefined or null.
     * @returns The query string with a prefixed question mark.
     */
    private static createQueryString(requiredArgs?: { [key: string]: any }, optionalArgs?: { [key: string]: any }): string {
        let queryItems: string[] = [];

        if (requiredArgs != null) {
            for (const key in requiredArgs) {
                const value = requiredArgs[key];
                // If an array, add each key/value by hand to avoid JQuery adding []s to the key.
                if (Array.isArray(value)) {
                    for (const item of value) {
                        queryItems.push($.param({ [key]: item }));
                    }
                }
                // If not an array, add the standard way.
                else {
                    queryItems.push($.param({ [key]: value }));
                }
            }
        }
        
        if (optionalArgs != null) {
            for (const key in optionalArgs) {
                const value = optionalArgs[key];
                // If undefined or null, then omit.
                if (value != null) {
                    // If an array, add each key/value by hand to avoid JQuery adding []s to the key.
                    if (Array.isArray(value)) {
                        for (const item of value) {
                            queryItems.push($.param({ [key]: item }));
                        }
                    }
                    // If not an array, add the standard way.
                    else {
                        queryItems.push($.param({ [key]: value }));
                    }
                }
            }
        }

        return queryItems.length > 0 ? `?${queryItems.join('&')}` : "";
    }

    private getArchivedEventKeyRouteString(archivedEventKey: Events.ArchivedEventKey): string {
        return `${archivedEventKey.PointId.toString()}-${archivedEventKey.EventTime}-${archivedEventKey.EventArchiveId}`
    }

    addRtuGroup(rtuGroup: Rtus.AddRtuGroup) {
        return this.postJson<number>("/rtugroups/", rtuGroup);
    }

    getRtuGroup(rtuGroupId: number) {
        return this.ajaxRequest<Rtus.RtuGroup>("/rtugroups/" + rtuGroupId)
    }

    deleteRtuGroup(rtuGroupId: number) {
        return this.deleteRequest<void>(`/rtugroups/${rtuGroupId}`);
    }

    updateRtuGroup(rtuGroupId: number, rtuGroup: Rtus.RtuGroup) {
        return this.putJson<void>(`/rtugroups/${rtuGroupId}`, rtuGroup);
    }

    getRtuGroupForRtu(rtuId: number) {
        return this.ajaxRequest<Rtus.RtuGroup>("/rtus/" + rtuId + "/rtugroup");
    }

    updateRtuGroupForRtu(rtuId: number, moveRtuGroupRequest: Rtus.MoveRtuGroupRequest) {
        return this.putJson<void>("/rtus/" + rtuId + "/rtugroup", moveRtuGroupRequest);
    }

    getRtuGroupPath(rtuGroupId: number) {
        return this.ajaxRequest<Rtus.RtuGroup[]>("/rtugroups/" + rtuGroupId + "/path");
    }

    getRtuGroupAllRtus(rtuGroupId: number, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Rtus.Rtu>>("/rtugroups/" + rtuGroupId + "/rtus/all", this.getPagingOptions(paging));
    }

    getRtuGroupRtus(rtuGroupId: number, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Rtus.Rtu>>("/rtugroups/" + rtuGroupId + "/rtus", this.getPagingOptions(paging));
    }

    getRtu(rtuId: number, optionalArguments?: Args.GetRtuArguments) {
        return this.ajaxRequest<Rtus.Rtu>(`/rtus/${rtuId}`, optionalArguments);
    }

    getRtuTemplates(customerIds?: number[], optionalArguments?: Args.RtuTemplatesArguments) {
        return this.ajaxRequest<Args.Collection<Rtus.Rtu>>("/rtus/templates", $.extend({}, { customerIds }, optionalArguments));
    }

    addRtu(rtu: Rtus.AddRtu) {
        return this.postJson<number>("/rtus/new", rtu)
    }

    deleteRtu(rtuId: number) {
        return this.deleteRequest<void>(`/rtus/${rtuId}`);
    }

    deleteRtus(request: Rtus.DeleteRtusRequest) {
        return this.deleteJson<void>("/rtus", request);
    }

    replaceRtu(rtuId: number, request: Rtus.ReplaceRtuRequest) {
        return this.postJson<void>(`/rtus/${rtuId}/replace`, request);
    }

    updateRtusOnline(request: Rtus.UpdateRtusOnlineRequest) {
        return this.postJson<void>(`/rtus/online`, request);
    }

    updateRtuOnline(rtuId: number, request: Rtus.UpdateRtuOnlineRequest) {
        return this.postJson<void>(`/rtus/${rtuId}/online`, request);
    }

    lockRtu(rtuId: number, request: Rtus.LockRtuRequest) {
        return this.putJson<void>(`/rtus/${rtuId}/lock`, request);
    }

    pollRtu(rtuId: number) {
        return this.putRequest<void>(`/rtus/${rtuId}/poll`);
    }

    getRtuPollStatus(rtuId: number) {
        return this.ajaxRequest<Enums.PollStatus>(`/rtus/${rtuId}/pollstatus`);
    }

    bulkUpdateRtuSettings(request: Rtus.BulkUpdateRtuSettingsRequest) {
        return this.putJson<void>(`/rtus/settings`, request);
    }

    getRtuBySerialNumber(rtuSerialNumber: string, optionalArguments?: Args.GetRtuArguments) {
        return this.ajaxRequest<Rtus.Rtu>(`/rtus/serialnumber/${rtuSerialNumber}`, optionalArguments);
    }

    getRtusBySiteName(siteName: string, optionalArguments?: Args.RtuSiteNameArguments) {
        return this.ajaxRequest<Args.Collection<Rtus.Rtu>>(`/rtus/sitename/${siteName}`, optionalArguments);
    }

    getRtuLinks(rtuId: number) {
        return this.ajaxRequest<Links.RtuLinks>("/rtus/" + rtuId + "/links");
    }

    getRtuCustomLinks(rtuId: number) {
        return this.ajaxRequest<Links.RtuCustomLink[]>("/rtus/" + rtuId + "/customlinks");
    }

    getRtuUserData(rtuId: number) {
        return this.ajaxRequest<Rtus.RtuUserData>("/rtus/" + rtuId + "/userdata");
    }

    getRtuUserDatas(optionalArguments?: Args.AllRtusArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Rtus.RtuUserData>>("/rtus/userdata", $.extend({}, optionalArguments, this.getPagingOptions(paging)));
    }

    getRtuStatus(rtuId: number, optionalArguments?: Args.IncludeAlarmStateArguments) {
        return this.ajaxRequest<Rtus.RtuStatus>(`/rtus/${rtuId}/status`, optionalArguments);
    }

    getRtusStatus(optionalArguments?: Args.RtusStatusArguments, paging?: Args.PagingOptions) {
        paging = this.getPagingOptions(paging);

        return this.ajaxRequest<Args.PagedCollection<Rtus.RtuStatus>>("/rtus/status", $.extend({}, optionalArguments, paging));
    }

    getRtuLocation(rtuId: number) {
        return this.ajaxRequest<Rtus.RtuLocation>("/rtus/" + rtuId + "/location");
    }

    getRtuLocations(optionalArguments?: Args.AllRtusArguments, paging?: Args.PagingOptions) {
        const data = $.extend({}, optionalArguments, this.getPagingOptions(paging));

        return this.ajaxRequest<Args.PagedCollection<Rtus.RtuLocation>>("/rtus/locations", data);
    }

    updateRtuLocation(rtuId: number, rtuLocation: Rtus.UpdateRtuLocationRequest) {
        return this.putJson<void>(`/rtus/${rtuId}/location`, rtuLocation);
    }

    getRtuUserDataLabels(rtuId: number) {
        return this.ajaxRequest<Customers.UserDataLabels>("/rtus/" + rtuId + "/userdatalabels");
    }

    rtuSearch(
        query: string, 
        customerIds?: number[], 
        rtuGroupIds?: number[], 
        includeSubGroups?: boolean, 
        rtuTypes?: Enums.RtuType[],
        rtuFunctionIds?: string[],
        protocols?: Enums.Protocol[],
        searchFields?: Enums.RtuSearchFields,
        includeLinks?: boolean,
        paging: Args.PagingOptions = {}) {
        const pagingOptions = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Rtus.RtuWithUserData>>("/rtus/search/", {
            query: query,
            customerIds: customerIds,
            rtuGroupIds: rtuGroupIds,
            includeSubGroups: includeSubGroups,
            rtuTypes: rtuTypes,
            rtuFunctionIds: rtuFunctionIds,
            protocols: protocols,
            searchFields: searchFields,
            includeLinks: includeLinks,
            page: pagingOptions ? pagingOptions.page : undefined,
            pageSize: pagingOptions ? pagingOptions.pageSize : undefined
        });
    }

    getRtusDetails(args?: Rtus.GetRtusDetailsArgs) {
        return this.ajaxRequest<Args.Collection<Rtus.RtuDetails>>("/rtus/details", args);
    }

    acknowledgeAllEvents(rtuId: number, ack: boolean) {
        return this.ajaxRequest<void>("/rtus/" + rtuId + "/ackevents", { acknowledge: ack }, "POST");
    }

    acknowledgeEvents(ackEventsRequest: Args.AcknowledgeEventsRequest) {
        return this.postJson<void>("/rtus/ackevents", ackEventsRequest);
    }

    updateEvent(rtuId: number, eventId: number, request: Events.UpdateEventRequest) {
        return this.putJson<void>(`/rtus/${rtuId}/events/${eventId}`, request);
    }

    updateArchiveEvent(rtuId: number, archivedEventKey: Events.ArchivedEventKey, request: Events.UpdateEventRequest) {
        return this.putJson<void>(`/rtus/${rtuId}/events/${this.getArchivedEventKeyRouteString(archivedEventKey)}`, request);
    }

    deleteEvent(rtuId: number, eventId: number) {
        return this.deleteRequest<void>(`/rtus/${rtuId}/events/${eventId}`);
    }

    deleteEvents(eventIds: number[]) {
        return this.deleteRequest<void>("/rtus/events?" + $.param({ eventIds }));
    }

    getCustomer(customerId: number, optionalArguments?: Args.GetCustomerArguments) {
        return this.ajaxRequest<Customers.Customer>(`/customers/${customerId}`, optionalArguments);
    }

    getCustomers(customerIds?: number[], optionalArguments?: Args.GetCustomersArguments) {
        return this.ajaxRequest<Args.Collection<Customers.Customer>>("/customers", $.extend({}, { customerIds }, optionalArguments));
    }

    updateCustomer(customerId: number, request: Customers.UpdateCustomerRequest) {
        return this.putJson<void>(`/customers/${customerId}`, request);
    }

    updateCustomerAssignedMessageApis(customerId: number, request: Customers.AssignCustomerMessageApiRequest) {
        return this.putJson<void>(`/customers/${customerId}/assignedmessageapis`, request);
    }

    updateCustomerAssignedIdentityProviders(customerId: number, request: Customers.AssignCustomerIdentityProviderRequest) {
        return this.putJson<void>(`/customers/${customerId}/assignedidentityproviders`, request);
    }

    deleteCustomer(customerId: number) {
        return this.deleteRequest<void>(`/customers/${customerId}`);
    }

    updateCustomerLicense(customerId: number, license: Customers.CustomerLicense) {
        return this.putJson<void>(`/customers/${customerId}/license`, license);
    }

    getCustomerForRtu(rtuId: number) {
        return this.ajaxRequest<Customers.Customer>("/rtus/" + rtuId + "/customer");
    }

    getCustomerRtus(customerId: number, optionalArguments?: Args.AllRtusAndStatusAndLinkArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Rtus.Rtu>>(`/customers/${customerId}/rtus`, $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getCustomerUserDataLabels(customerId: number) {
        return this.ajaxRequest<Customers.UserDataLabels>("/customers/" + customerId + "/userdatalabels");
    }

    getCustomerRtuGroups(
        customerId: number,
        optionalArguments?: Args.CustomerRtuGroupsArguments, paging: Args.PagingOptions = {}) {
        return this.ajaxRequest<Args.PagedCollection<Rtus.RtuGroup>>("/customers/" + customerId + "/rtugroups", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getCustomerRtuUserData(
        customerId: number,
        optionalArguments?: Args.CustomerRtuUserDataArguments, paging: Args.PagingOptions = {}) {
        return this.ajaxRequest<Args.PagedCollection<Rtus.RtuUserData>>("/customers/" + customerId + "/rtus/userdata", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getCustomerSettings(customerId: number, keys?: string[]) {
        return this.ajaxRequest<{ [key: string]: string }>("/customers/" + customerId + "/settings", { key: keys });
    }

    getCurrentCustomerSettings(keys?: string[]) {
        return this.ajaxRequest<{ [key: string]: string }>("/customers/current/settings", { key: keys });
    }

    setCustomerSettings(customerId: number, settings: { [index: string]: any }) {
        return this.postJson<void>("/customers/" + customerId + "/settings/", IHostWebApi.flattenSettings(settings));
    }

    setCurrentCustomerSettings(settings: { [index: string]: any }) {
        return this.postJson<void>("/customers/current/settings/", IHostWebApi.flattenSettings(settings));
    }

    getCustomerPriorities(customerId: number) {
        return this.ajaxRequest<Args.Collection<Customers.CustomerPriority>>(`/customers/${customerId}/priorities`);
    }

    getAllCustomerPriorities(customerIds?: number[]) {
        return this.ajaxRequest<Args.Collection<Customers.CustomerPriority>>("/customers/priorities", { customerIds: customerIds });
    }

    getCustomerSummary(customerId: number) {
        return this.ajaxRequest<Customers.CustomerSummary>(`/customers/${customerId}/summary`);
    }
    
    getCustomerNotificationStats(customerId: number, createdFrom: string, createdTo: string, createdToInclusive?: boolean) {
        return this.ajaxRequest<Args.Collection<Notifications.NotificationStats>>(`/customers/${customerId}/notificationstats`, { createdFrom, createdTo, createdToInclusive });
    }

    getCustomerPasswordRequirements(customerId: number) {
        return this.ajaxRequest<Auth.PasswordRequirements>(`/customers/${customerId}/passwordrequirements`);
    }

    getAnalogueInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.AnalogueInput>>("/rtus/" + rtuId + "/ai", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getAnalogueInput(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.AnalogueInput>("/rtus/" + rtuId + "/ai/" + ioReference);
    }

    getAnalogueInputByPointNumber(rtuId: number, pointNumber: number) {
        return this.ajaxRequest<Inputs.AnalogueInput>("/rtus/" + rtuId + "/ai/number/" + pointNumber);
    }

    getAnalogueInputThresholdsForPoint(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.AnalogueThreshold>("/rtus/" + rtuId + "/ai/" + ioReference + "/thresholds");
    }

    getAnalogueInputThresholds(rtuId: number, optionalArguments?: Args.IoReferencesArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.AnalogueThreshold>>("/rtus/" + rtuId + "/ai/thresholds", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getAnalogueInputScaleForPoint(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.AnalogueScale>("/rtus/" + rtuId + "/ai/" + ioReference + "/scale");
    }

    getAnalogueInputScales(rtuId: number, optionalArguments?: Args.IoReferencesArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.AnalogueScale>>("/rtus/" + rtuId + "/ai/scale", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getAnalogueOutputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.AnalogueOutput>>("/rtus/" + rtuId + "/ao", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getAnalogueOutput(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.AnalogueOutput>("/rtus/" + rtuId + "/ao/" + ioReference);
    }

    getAnalogueOutputByPointNumber(rtuId: number, pointNumber: number) {
        return this.ajaxRequest<Inputs.AnalogueOutput>("/rtus/" + rtuId + "/ao/number/" + pointNumber);
    }

    getAnalogueOutputScaleForPoint(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.AnalogueScale>("/rtus/" + rtuId + "/ao/" + ioReference + "/scale");
    }

    getAnalogueOutputScales(rtuId: number, optionalArguments?: Args.IoReferencesArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.AnalogueScale>>("/rtus/" + rtuId + "/ao/scale", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getAnalogueOutputOperation(rtuId: number, ioReference: number, outputOperationId: number) {
        return this.ajaxRequest<Inputs.AnalogueOutputOperation>(`/rtus/${rtuId}/ao/${ioReference}/queue/${outputOperationId}`);
    }

    getAnalogueOutputOperations(
        rtuId: number, ioReference: number,
        optionalArguments?: Args.DateArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.AnalogueOutputOperation>>(`/rtus/${rtuId}/ao/${ioReference}/queue`, $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    queueAnalogueOutputOperation(rtuId: number, ioReference: number, request: Args.QueueAnalogueOutputOperationRequest) {
        return this.postJson<number>(`/rtus/${rtuId}/ao/${ioReference}/queue`, request);
    }

    deleteAnalogueOutputOperation(rtuId: number, ioReference: number, outputOperationId: number) {
        return this.deleteRequest<void>(`/rtus/${rtuId}/ao/${ioReference}/queue/${outputOperationId}`);
    }

    getBinaryInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.BinaryInput>>("/rtus/" + rtuId + "/bi", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getBinaryInput(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.BinaryInput>("/rtus/" + rtuId + "/bi/" + ioReference);
    }

    getBinaryInputByPointNumber(rtuId: number, pointNumber: number) {
        return this.ajaxRequest<Inputs.BinaryInput>("/rtus/" + rtuId + "/bi/number/" + pointNumber);
    }

    getCounterInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.CounterInput>>("/rtus/" + rtuId + "/ci", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getCounterInput(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.CounterInput>("/rtus/" + rtuId + "/ci/" + ioReference);
    }

    getCounterInputByPointNumber(rtuId: number, pointNumber: number) {
        return this.ajaxRequest<Inputs.CounterInput>("/rtus/" + rtuId + "/ci/number/" + pointNumber);
    }

    getStringInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.StringInput>>("/rtus/" + rtuId + "/si", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getStringInput(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.StringInput>("/rtus/" + rtuId + "/si/" + ioReference);
    }

    getStringInputByPointNumber(rtuId: number, pointNumber: number) {
        return this.ajaxRequest<Inputs.StringInput>("/rtus/" + rtuId + "/si/number/" + pointNumber);
    }

    getDoubleBitInputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.DoubleBitInput>>("/rtus/" + rtuId + "/di", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getDoubleBitInput(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.DoubleBitInput>("/rtus/" + rtuId + "/di/" + ioReference);
    }

    getDoubleBitByPointNumber(rtuId: number, pointNumber: number) {
        return this.ajaxRequest<Inputs.DoubleBitInput>("/rtus/" + rtuId + "/di/number/" + pointNumber);
    }

    getBinaryOutputs(rtuId: number, optionalArguments?: Args.InputsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.BinaryOutput>>("/rtus/" + rtuId + "/bo", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getBinaryOutput(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.BinaryOutput>("/rtus/" + rtuId + "/bo/" + ioReference);
    }

    getBinaryOutputByPointNumber(rtuId: number, pointNumber: number) {
        return this.ajaxRequest<Inputs.BinaryOutput>("/rtus/" + rtuId + "/bo/number/" + pointNumber);
    }

    getBinaryOutputOperations(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Inputs.BinaryOutputOperation>>("/rtus/" + rtuId + "/bo/" + ioReference + "/queue", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
        });
    }

    getBinaryOutputOperation(rtuId: number, ioReference: number, outputOperationId: number) {
        return this.ajaxRequest<Inputs.BinaryOutputOperation>("/rtus/" + rtuId + "/bo/" + ioReference + "/queue/" + outputOperationId);
    }

    deleteBinaryOutputOperation(rtuId: number, ioReference: number, outputOperationId: number) {
        return this.deleteRequest<void>("/rtus/" + rtuId + "/bo/" + ioReference + "/queue/" + outputOperationId);
    }

    queueBinaryOutputOperation(rtuId: number, ioReference: number, request: Args.QueueBinaryOutputOperationRequest) {
        return this.postJson<number>("/rtus/" + rtuId + "/bo/" + ioReference + "/queue", request);
    }

    updatePointLogic(rtuId: number, ioReference: number, pointLogic: Inputs.PointLogic) {
        return this.putJson<void>(`/rtus/${rtuId}/points/${ioReference}/logic`, pointLogic);
    }

    getAllPoints(optionalArguments?: Args.GetAllPointsArguments): Promise<Args.Collection<Inputs.Input>> {
        return this.ajaxRequest("/rtus/points", optionalArguments);
    }

    getPoints(rtuId: number, optionalArguments?: Args.GetPointsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.Input>>(`/rtus/${rtuId}/points`, $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getPointsWithQuality(rtuId: number, qualityFlag: number[], paging?: Args.PagingOptions) {
        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Inputs.Input>>("/rtus/" + rtuId + "/points", {
            page: paging.page,
            pageSize: paging.pageSize,
            pointQuality: qualityFlag,
        });
    }

    getPoint(rtuId: number, ioReference: number) {
        return this.ajaxRequest<Inputs.Input>("/rtus/" + rtuId + "/points/" + ioReference);
    }

    getPointsForPointGroup(rtuId: number, pointGroupId: number, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.Input>>("/rtus/" + rtuId + "/pointgroups/" + pointGroupId + "/points",
            this.getPagingOptions(paging));
    }

    getPointGroupsForPoint(rtuId: number, ioReference: number, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.PointGroup>>("/rtus/" + rtuId + "/points/" + ioReference + "/pointgroups",
            this.getPagingOptions(paging));
    }

    getPointGroupsForRtu(rtuId: number, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Inputs.PointGroup>>("/rtus/" + rtuId + "/pointgroups", this.getPagingOptions(paging));
    }

    getPointGroupForRtu(rtuId: number, pointGroupId: number) {
        return this.ajaxRequest<Inputs.PointGroup>("/rtus/" + rtuId + "/pointgroups/" + pointGroupId);
    }

    getEvent(rtuId: number, eventId: number) {
        return this.ajaxRequest<Events.IHostEvent>(`/rtus/${rtuId}/events/${eventId}`);
    }

    getArchiveEvent(rtuId: number, archivedEventKey: Events.ArchivedEventKey) {
        return this.ajaxRequest<Events.IHostEvent>(`/rtus/${rtuId}/events/${this.getArchivedEventKeyRouteString(archivedEventKey)}`);
    }

    getDetailedEvent(rtuId: number, eventId: number, includeEventData?: boolean, includeEventComments?: boolean) {
        return this.ajaxRequest<Events.DetailedEvent | PQEvents.PQEvent>(`/rtus/${rtuId}/events/detailed/${eventId}`, $.extend({}, {
            includeEventComments: includeEventComments as boolean,
            includeEventData: includeEventData as boolean,
        }));
    }

    getDetailedArchiveEvent(rtuId: number, archivedEventKey: Events.ArchivedEventKey, includeEventData?: boolean, includeEventComments?: boolean) {
        return this.ajaxRequest<Events.DetailedEvent | PQEvents.PQEvent>(`/rtus/${rtuId}/events/detailed/${this.getArchivedEventKeyRouteString(archivedEventKey)}`, $.extend({}, {
            includeEventComments: includeEventComments as boolean,
            includeEventData: includeEventData as boolean,
        }));
    }

    getEventStatusHistory(rtuId: number, eventId: number) {
        return this.ajaxRequest<Args.Collection<Events.EventStatusHistory>>(`/rtus/${rtuId}/events/${eventId}/statushistory`);
    }

    getEventNotificationHistory(rtuId: number, eventId: number) {
        return this.ajaxRequest<Args.Collection<Notifications.Message>>(`/rtus/${rtuId}/events/${eventId}/notificationhistory`);
    }

    getEvents(
        rtuId: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions,
        eventLogTypes?: Enums.EventType[],
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        minPriorityId?: number,
        useSavedFilter?: boolean,
        eventStatus?: Enums.EventLogStatus,
        eventStorageType?: Enums.EventStorageType) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.IHostEvent>>("/rtus/" + rtuId + "/events", $.extend({}, {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
            eventLogTypes: eventLogTypes as Enums.EventType[],
            sortBy: eventSortingArguments ? eventSortingArguments.sortColumn : null,
            sortOrder: eventSortingArguments ? eventSortingArguments.sortOrder : null,
            includeTotal: includeTotal as boolean,
            minPriorityId: minPriorityId,
            useSavedFilter: useSavedFilter,
            eventStatus: eventStatus,
            eventStorageType: eventStorageType
        }));
    }

    getAllEvents(
        optionalArguments?: Args.GroupedEventsArguments,
        paging?: Args.PagingOptions,
        sortOrder?: Enums.SortOrder) {

        return this.ajaxRequest<Args.Collection<Events.IHostEvent>>("/rtus/events", {
            ...optionalArguments,
            ...this.getPagingOptions(paging),
            sortOrder
        });
    }

    getPointEvents(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions,
        eventLogTypes?: Enums.EventType[],
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        minPriorityId?: number,
        useSavedFilter?: boolean,
        eventStatus?: Enums.EventLogStatus,
        eventStorageType?: Enums.EventStorageType) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.IHostEvent>>("/rtus/" + rtuId + "/points/" + ioReference + "/events", $.extend({}, {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
            eventLogTypes: eventLogTypes as Enums.EventType[],
            sortBy: eventSortingArguments ? eventSortingArguments.sortColumn : null,
            sortOrder: eventSortingArguments ? eventSortingArguments.sortOrder : null,
            includeTotal: includeTotal as boolean,
            minPriorityId,
            useSavedFilter,
            eventStatus,
            eventStorageType
        }));
    }

    getDetailedEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions,
        eventLogTypes?: Enums.EventType[],
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        includeEventData?: boolean,
        includeEventComments?: boolean,
        minPriorityId?: number,
        useSavedFilter?: boolean,
        eventStatus?: Enums.EventLogStatus,
        eventStorageType?: Enums.EventStorageType) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.DetailedEvent | PQEvents.PQEvent>>("/rtus/" + rtuId + "/points/" + ioReference + "/events/detailed", $.extend({}, {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
            eventLogTypes: eventLogTypes as Enums.EventType[],
            sortBy: eventSortingArguments ? eventSortingArguments.sortColumn : null,
            sortOrder: eventSortingArguments ? eventSortingArguments.sortOrder : null,
            includeTotal: includeTotal as boolean,
            includeEventData: includeEventData as boolean,
            includeEventComments: includeEventComments as boolean,
            minPriorityId,
            useSavedFilter,
            eventStatus,
            eventStorageType
        }));
    }

    getDetailedEvents(
        rtuId: number,
        optionalArguments?: Args.DetailedEventsArguments,
        paging?: Args.PagingOptions,
        eventSortingArguments?: Args.SortingOptions<Enums.EventSortColumn>,
        includeTotal?: boolean,
        includeEventData?: boolean,
        includeEventComments?: boolean) {

        paging = this.getPagingOptions(paging);
        const args =
            $.extend({},
                optionalArguments,
                paging,
                {
                    from: optionalArguments ? optionalArguments.from : undefined,
                    to: optionalArguments ? optionalArguments.to : undefined
                },
                eventSortingArguments ?
                    { sortBy: eventSortingArguments.sortColumn, sortOrder: eventSortingArguments.sortOrder } :
                    {},
                { includeTotal: includeTotal as boolean },
                { includeEventData: includeEventData as boolean },
                { includeEventComments: includeEventComments as boolean });
        return this.ajaxRequest<Args.PagedCollection<Events.DetailedEvent | PQEvents.PQEvent>>(`/rtus/${rtuId}/events/detailed`, args);
    }

    getAllDetailedEvents(
        optionalArguments?: Args.GroupedEventsArguments,
        paging?: Args.PagingOptions,
        sortOrder?: Enums.SortOrder) {
            
        return this.ajaxRequest<Args.Collection<Events.DetailedEvent | PQEvents.PQEvent>>("/rtus/events/detailed", {
            ...optionalArguments,
            ...this.getPagingOptions(paging),
            sortOrder
        });
    }

    getAnalogueInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Events.AnalogueInputEvent>>("/rtus/" + rtuId + "/ai/events", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getAnalogueInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.AnalogueInputEvent>>("/rtus/" + rtuId + "/ai/" + ioReference + "/events", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
        });
    }

    getAnalogueOutputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Events.AnalogueOutputEvent>>("/rtus/" + rtuId + "/ao/events", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getAnalogueOutputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.AnalogueOutputEvent>>("/rtus/" + rtuId + "/ao/" + ioReference + "/events", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
        });
    }

    getBinaryInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Events.BinaryInputEvent>>("/rtus/" + rtuId + "/bi/events", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getBinaryInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.BinaryInputEvent>>("/rtus/" + rtuId + "/bi/" + ioReference + "/events", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
        });
    }

    getBinaryOutputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Events.BinaryOutputEvent>>("/rtus/" + rtuId + "/bo/events", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getBinaryOutputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.BinaryOutputEvent>>("/rtus/" + rtuId + "/bo/" + ioReference + "/events", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
        });
    }

    getCounterInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Events.CounterInputEvent>>("/rtus/" + rtuId + "/ci/events", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getCounterInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.CounterInputEvent>>("/rtus/" + rtuId + "/ci/" + ioReference + "/events", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to
        });
    }

    getStringInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Events.StringInputEvent>>("/rtus/" + rtuId + "/si/events", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getStringInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.StringInputEvent>>("/rtus/" + rtuId + "/si/" + ioReference + "/events", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
        });
    }

    getDoubleBitInputEvents(rtuId: number, optionalArguments?: Args.EventsArguments, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Events.DoubleBitInputEvent>>("/rtus/" + rtuId + "/di/events", $.extend({}, optionalArguments,
            this.getPagingOptions(paging)));
    }

    getDoubleBitInputEventsForPoint(
        rtuId: number, ioReference: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions) {

        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Events.DoubleBitInputEvent>>("/rtus/" + rtuId + "/di/" + ioReference + "/events", {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
        });
    }

    getRtuPointLinks(rtuId: number, optionalArguments?: Args.IoReferencesArguments) {
        return this.ajaxRequest<Links.PointLink[]>("/rtus/" + rtuId + "/points/links", optionalArguments);
    }

    getPointLinks(rtuId: number, rtuIoReference: number) {
        return this.ajaxRequest<Links.PointLink[]>("/rtus/" + rtuId + "/points/" + rtuIoReference + "/links");
    }

    saveAnalogueInputEvents(rtuId: number, eventData: Events.AnalogueInputEvent[]) {
        return this.postJson<number>("/rtus/" + rtuId + "/ai/events", eventData);
    }

    saveAnalogueInputEventsForPoint(rtuId: number, ioReference: number, eventData: Events.AnalogueInputEvent[]) {
        return this.postJson<number>("/rtus/" + rtuId + "/ai/" + ioReference + "/events", eventData);
    }

    getRtuFiles(rtuId: number, optionalArguments?: Args.FilesArguments) {
        return this.getRtuFilesWithArgs<Rtus.RtuFile[]>(rtuId, optionalArguments);
    }

    getRtuFilesJson<T>(rtuId: number, optionalArguments?: Args.FilesArguments) {
        const args: Args.FilesArguments & Args.DownloadAsJsonArguments = optionalArguments || {};
        args.downloadAsJson = true;
        return this.getRtuFilesWithArgs<Rtus.RtuJsonFile<T>[]>(rtuId, args);
    }

    private getRtuFilesWithArgs<T>(rtuId: number, optionalArguments?: Args.FilesArguments & Args.DownloadAsJsonArguments) {
        const args: Args.ContentTypeArguments & Args.FilesArguments & Args.DownloadAsJsonArguments = optionalArguments || {};
        if (args.downloadAsJson) {
            args.contentType = "json";
            delete args.downloadAsJson;
        }
        return this.ajaxRequest<T>(`/rtus/${rtuId}/files`, args);
    }

    getRtuFile(rtuId: number, fileId: number) {
        return this.getRtuFileWithArgs<Rtus.RtuFile>(rtuId, fileId);
    }

    getRtuFileJson<T>(rtuId: number, fileId: number) {
        const args: Args.DownloadAsJsonArguments = {
            downloadAsJson: true,
        };
        return this.getRtuFileWithArgs<Rtus.RtuJsonFile<T>>(rtuId, fileId, args);
    }

    getRtuFileNxb(rtuId: number, fileId: number) {
        return this.ajaxRequest<Rtus.NxbFile>(`/rtus/${rtuId}/files/${fileId}`, { contentType: "x.nxbinary.v1+json" });
    }

    updateRtuFile(rtuId: number, fileId: number, file: Pick<Rtus.RtuFile, "RtuFileId" | "RtuId" | "RtuFileFolderId" | "Filename" | "FolderPath" | "Description">) {
        return this.putJson<void>(`/rtus/${rtuId}/files/${fileId}/metadata`, file);
    }

    getRtuFolders(rtuId: number, optionalArguments: Args.FoldersArguments) {
        return this.ajaxRequest<Rtus.RtuFileFolder[]>("/rtus/" + rtuId + "/files/folders", optionalArguments);
    }

    getRtuTopFolders(rtuId: number) {
        return this.ajaxRequest<Rtus.RtuFileFolder[]>("/rtus/" + rtuId + "/files/folders/top");
    }

    getRtuFolder(rtuId: number, folderId: number) {
        return this.ajaxRequest<Rtus.RtuFileFolder>("/rtus/" + rtuId + "/files/folders/" + folderId);
    }

    updateRtuFolder(rtuId: number, folderId: number, folder: Rtus.RtuFileFolder) {
        return this.putJson<void>(`/rtus/${rtuId}/files/folders/${folderId}/metadata`, folder);
    }

    getRtuFolderSubfolders(rtuId: number, folderId: number) {
        return this.ajaxRequest<Rtus.RtuFileFolder[]>("/rtus/" + rtuId + "/files/folders/" + folderId + "/subfolders");
    }

    getRtuFolderLink(rtuId: number, folderId: number, optionalArguments?: Args.RtuFolderLinkArguments) {
        let contentType = Enums.FolderContentType.Zip;
        if (optionalArguments && optionalArguments.contentType != null) {
            contentType = optionalArguments.contentType;
        }
        return `${this.urlRoot}/rtus/${rtuId}/files/folders/${folderId}/?contentType=${this.getFolderContentTypeAsString(contentType)}`;
    }

    getRtuFoldersLink(rtuId: number, folderIds?: number[], downloadFilename?: string) {
        const queryParams = $.param({
            ...folderIds ? { folderIds } : {},
            ...downloadFilename ? { downloadFilename } : {},
            contentType: this.getFileContentTypeAsString(Enums.FileContentType.Zip)
        });

        return `${this.urlRoot}/rtus/${rtuId}/files/folders?${queryParams}`;
    }

    getRtuTextFileContents(rtuId: number, fileId: number, optionalArguments?: Args.RtuFileContentsArguments) {
        const args: Args.RtuFileContentsArguments = $.extend({ autoUnzip: true }, optionalArguments);

        return this.ajaxRequest<string>("/rtus/" + rtuId + "/files/" + fileId, {
            contentType: args.autoUnzip
                ? this.getFileContentTypeAsString(Enums.FileContentType.UnZip)
                : this.getFileContentTypeAsString(Enums.FileContentType.Raw),
        }, void 0, void 0, "text");
    }

    getRtuJsonFileContents<T>(rtuId: number, fileId: number, optionalArguments?: Args.RtuFileContentsArguments) {
        const args: Args.RtuFileContentsArguments = $.extend({ autoUnzip: true }, optionalArguments);

        return this.ajaxRequest("/rtus/" + rtuId + "/files/" + fileId, {
            contentType: args.autoUnzip
                ? this.getFileContentTypeAsString(Enums.FileContentType.UnZip)
                : this.getFileContentTypeAsString(Enums.FileContentType.Raw),
        }, void 0, void 0, void 0) as Promise<T>;
    }

    getRtuFileLink(rtuId: number, fileId: number, optionalArguments?: Args.RtuFileLinkArguments) {
        let contentType: string = this.getFileContentTypeAsString(Enums.FileContentType.Zip);
        if (optionalArguments && optionalArguments.contentType != null) {
            contentType = this.getFileContentTypeAsString(optionalArguments.contentType);
        }
        return `${this.urlRoot}/rtus/${rtuId}/files/${fileId}?contentType=${contentType}`;
    }

    getRtuFilesLink(rtuId: number, fileIds?: number[], folderIds?: number[], downloadFilename?: string) {
        const queryParams = $.param({
            ...fileIds ? { fileIds: fileIds } : {},
            ...folderIds ? { folderIds } : {},
            ...downloadFilename ? { downloadFilename } : {},
            includeSubfolders: true,
            contentType: this.getFileContentTypeAsString(Enums.FileContentType.Zip)
        });

        return `${this.urlRoot}/rtus/${rtuId}/files?${queryParams}`;
    }

    uploadRtuFiles(rtuId: number, metadata: Rtus.UploadedRtuFile[], files: FileList | File[], progressHandler?: (event: ProgressEvent) => void) {
        return this.sendRtuFiles<Rtus.RtuFile[]>("POST", rtuId, metadata, files, progressHandler);
    }

    overwriteRtuFiles(rtuId: number, metadata: Rtus.UploadedRtuFile[], files: FileList | File[], progressHandler?: (event: ProgressEvent) => void) {
        return this.sendRtuFiles<Rtus.RtuFile[]>("PUT", rtuId, metadata, files, progressHandler);
    }

    addRtuFileFolder(rtuId: number, name: string, path: string) {
        const rtuFileFolder = { RtuId: rtuId, Name: name, Path: path };

        return this.postJson<number>("/rtus/" + rtuId + "/files/folders", rtuFileFolder);
    }

    deleteRtuFile(rtuId: number, rtuFileId: number) {
        return this.deleteRequest<void>("/rtus/" + rtuId + "/files/" + rtuFileId);
    }

    deleteRtuFiles(rtuId: number, rtuFileIds: number[]) {
        return this.deleteRequest<void>("/rtus/" + rtuId + "/files?" + $.param({ rtuFileIds }));
    }

    deleteRtuFileFolder(rtuId: number, rtuFileFolderId: number) {
        return this.deleteRequest<void>("/rtus/" + rtuId + "/files/folders/" + rtuFileFolderId);
    }

    deleteRtuFileFolders(rtuId: number, rtuFileFolderIds: number[]) {
        return this.deleteRequest<void>("/rtus/" + rtuId + "/files/folders?" + $.param({ rtuFileFolderIds }));
    }

    processRtuFiles(rtuId: number, processRequest: Args.RtuFileProcessRequest) {
        return this.postJson<void>("/rtus/" + rtuId + "/files/processqueue", processRequest);
    }

    reprocessRtuFiles(rtuId: number, reprocessRequest: Args.RtuFileReprocessRequest) {
        return this.putJson<void>("/rtus/" + rtuId + "/files/processqueue", reprocessRequest);
    }

    getRtuRssi(rtuId: number) {
        return this.ajaxRequest<Inputs.RssiPoint>("/rtus/" + rtuId + "/system/rssi");
    }

    getRtuMainsStatus(rtuId: number) {
        return this.ajaxRequest<Inputs.MainsStatus>("/rtus/" + rtuId + "/system/mains");
    }

    getRtusMainsStatus(optionalArguments: Args.AllRtusArguments, paging?: Args.PagingOptions) {
        paging = this.getPagingOptions(paging);

        return this.ajaxRequest<Args.PagedCollection<Inputs.MainsStatus>>("/rtus/system/mains", $.extend({}, optionalArguments, paging));
    }

    startLogin(startLoginRequest: Auth.StartLoginRequest) {
        return this.postJson<Auth.StartLoginResponse>("/auth/loginstart", startLoginRequest);
    }

    login(credentials: Auth.UserCredentials) {
        return this.postJson<Auth.AuthToken>("/auth/token", credentials);
    }

    logout() {
        return this.deleteRequest<void>("/auth/token");
    }

    getAuthLinks() {
        return this.ajaxRequest<Links.AuthLinks>("/auth/links");
    }

    changePassword(request: Auth.ChangePasswordRequest) {
        return this.postJson<void>("/auth/passwordchange", request);
    }

    generatePasswordResetToken(request: Auth.GeneratePasswordResetRequest) {
        return this.postJson<void>("/auth/passwordresettoken", request);
    }

    verifyPasswordReset(request: Auth.VerifyPasswordResetRequest) {
        return this.postJson<Auth.VerifyPasswordResetResponse>("/auth/passwordresetverify", request);
    }

    checkPasswordStrength(request: Auth.PasswordStrengthRequest) {
        return this.postJson<Auth.PasswordStrengthResponse>("/auth/passwordstrength", request);
    }

    resetPassword(request: Auth.ResetPasswordRequest) {
        return this.postJson<void>("/auth/passwordreset", request);
    }
    
    generateOtpAnonymous(request: Auth.GenerateUserOtpRequest) {
        return this.postJson<void>("/auth/otprequest", request);
    }

    getCurrentUser(
        optionalArguments?: Args.IncludeAppsArguments,
        includePermissions?: boolean,
        includeUserSettings?: boolean,
        includeCustomerSettings?: boolean,
        includeCustomerUserDataLabels?: boolean) {
        return this.ajaxRequest<Customers.CurrentUser>("/users/current", $.extend({},
            optionalArguments,
            { includePermissions: includePermissions as boolean },
            { includeUserSettings: includeUserSettings as boolean },
            { includeCustomerSettings: includeCustomerSettings as boolean },
            { includeCustomerUserDataLabels: includeCustomerUserDataLabels as boolean },
        ));
    }

    getUser(username: string, optionalArguments?: Args.IncludeAppsArguments) {
        // append forward slash to end so usernames with dot character work
        return this.ajaxRequest<Customers.User>(`/users/${username}/`, optionalArguments);
    }

    getUsers(customerIds?: number[], userGroupIds?: number[], includeApps?: boolean) {
        return this.ajaxRequest<Args.Collection<Customers.User>>(`/users`, {
            customerIds: customerIds,
            userGroupIds: userGroupIds,
            includeApps: includeApps
        });
    }

    getUserAvailableEventFilter(username : string) {
        return this.ajaxRequest<Args.Collection<Events.EventLogType>>( `/users/${username}/eventfilter/available`);
    }

    getCurrentUserRtus(optionalArguments?: Args.AllRtusAndStatusAndLinkArguments, pagingOptions?: Args.PagingOptions, headers?: { [key: string]: string }) {
        pagingOptions = this.getPagingOptions(pagingOptions);
        
        return this.ajaxRequest<Args.PagedCollection<Rtus.Rtu>>("/rtus", $.extend({}, optionalArguments, pagingOptions), undefined, undefined, undefined, undefined, headers);
    }

    getUserSettings(username: string, keys?: string[]) {
        return this.ajaxRequest<{ [index: string]: string }>("/users/" + username + "/settings", { key: keys });
    }

    getCurrentUserSettings(keys?: string[]) {
        return this.ajaxRequest<{ [index: string]: string }>("/users/current/settings", { key: keys });
    }

    setUserSettings(username: string, settings: { [index: string]: any }) {
        return this.postJson<void>("/users/" + username + "/settings/", IHostWebApi.flattenSettings(settings));
    }

    setCurrentUserSettings(settings: { [index: string]: any }) {
        return this.postJson<void>("/users/current/settings/", IHostWebApi.flattenSettings(settings));
    }

    bulkSetUserSettings(request: Users.BulkUpdateUserSettingsRequest) {
        return this.postJson<void>("/users/settings", request);
    }

    getCurrentUserApps() {
        return this.ajaxRequest<{ [index: string]: Plugins.AppPlugin }>("/users/current/apps");
    }

    getUserApps(username: string) {
        return this.ajaxRequest<{ [index: string]: Plugins.AppPlugin }>("/users/" + username + "/apps");
    }

    updateCurrentUserPassword(request: Users.UpdateUserPasswordRequest) {
        return this.putJson<void>("/users/current/password", request);
    }

    updateUserPassword(username: string, request: Users.UpdateUserPasswordRequest) {
        return this.putJson<void>(`/users/${username}/password`, request);
    }

    checkCurrentUserPasswordStrength() {
        return this.ajaxRequest<Auth.PasswordStrengthResponse>("/users/current/passwordstrength");
    }

    checkUserPasswordStrength(username: string) {
        return this.ajaxRequest<Auth.PasswordStrengthResponse>(`/users/${username}/passwordstrength`);
    }

    logoutUser(username: string): Promise<void> {
        return this.deleteRequest<void>(`/users/${username}/login`);
    }

    updateUserLinkedCustomers(username: string, updateUserLinkedCustomersRequest: Users.UpdateUserLinkedCustomersRequest): Promise<void> {
        return this.putJson(`/users/${username}/linkedcustomers`, updateUserLinkedCustomersRequest);
    }

    getUserMfaEnrollments(username: string) {
        return this.ajaxRequest<Args.Collection<Users.UserMfaEnrollment>>(`/users/${username}/mfaenrollments`);
    }

    startUserMfaEnrollment(username: string, request: Users.StartUserMfaEnrollmentRequest) {
        return this.postJson<Users.StartUserMfaEnrollmentResponse>(`/users/${username}/mfaenrollments`, request);
    }

    verifyUserMfaEnrollment(username: string, request: Users.VerifyUserMfaEnrollmentRequest) {
        return this.postJson<void>(`/users/${username}/mfaenrollments/verify`, request);
    }

    disableUserMfaEnrollment(username: string, request: Users.DisableUserMfaEnrollmentRequest) {
        return this.deleteJson<void>(`/users/${username}/mfaenrollments`, request);
    }

    generateUserMfaRecoveryCodes(username: string) {
        return this.postJson<Users.UserMfaRecoveryCode[]>(`/users/${username}/mfarecoverycodes`, null);
    }

    deleteUserMfaRecoveryCodes(username: string) {
        return this.deleteRequest<void>(`/users/${username}/mfarecoverycodes`);
    }

    generateUserOtp(username: string, request: Users.GenerateOtpRequest) {
        return this.postJson<void>(`/users/${username}/mfaenrollments/otprequest`, request);
    }

    getCurrentUserLoginHistory(paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.Collection<Users.UserLoginHistory>>(`/users/current/loginhistory`, $.extend({}, { 
            page: paging ? paging.page : undefined,
            pageSize: paging ? paging.pageSize : undefined
        }));
    }

    getUserLoginHistory(username: string, paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.Collection<Users.UserLoginHistory>>(`/users/${username}/loginhistory`, $.extend({}, { 
            page: paging ? paging.page : undefined,
            pageSize: paging ? paging.pageSize : undefined
        }));
    }

    getCustomerIconSet(customerId: number, iconSetId: number) {
        return this.ajaxRequest<Customers.IconSet>("/customers/" + customerId + "/iconsets/" + iconSetId);
    }

    getIconSet(iconSetId: number) {
        return this.ajaxRequest<Customers.IconSet>(`/iconsets/${iconSetId}`);
    }

    getCustomerIconSets(customerId: number) {
        return this.ajaxRequest<Args.Collection<Customers.IconSet>>("/customers/" + customerId + "/iconsets");
    }

    getIconSets(customerIds?: number[]) {
        return this.ajaxRequest<Args.Collection<Customers.IconSet>>("/iconsets", $.extend({}, { customerIds }));
    }

    addIconSet(customerId: number, iconSet: Customers.IconSet) {
        return this.postJson<number>("/customers/" + customerId + "/iconsets", iconSet);
    }

    updateIconSet(customerId: number, iconSetId: number, iconSet: Customers.IconSet) {
        return this.putJson<void>("/customers/" + customerId + "/iconsets/" + iconSetId, iconSet);
    }

    deleteIconSet(customerId: number, iconSetId: number) {
        return this.deleteRequest<void>("/customers/" + customerId + "/iconsets/" + iconSetId);
    }

    getSystemStatus(detailed = false) {
        return this.ajaxRequest<System.SystemStatus>("/system/status", { detailed });
    }

    getReleaseNotes() {        
        return this.ajaxRequest<string>("/system/releasenotes", null, "GET", false, "text");
    }

    getSystemTime() {
        return this.ajaxRequest<string>("/system/time");
    }

    getSystemLinks() {
        return this.ajaxRequest<Links.SystemLinks>("/system/links");
    }

    getSystemLimits() {
        return this.ajaxRequest<System.SystemLimits>("/system/limits");
    }

    getSystemServices(detailed: boolean = false) {
        return this.ajaxRequest<Args.Collection<System.SystemService>>("/system/services", { detailed });
    }

    getSystemBackups() {
        return this.ajaxRequest<Args.Collection<System.SystemBackup>>("/system/backups");
    }

    getSystemBackup(backupId: string) {
        return this.ajaxRequest<System.SystemBackup>(`/system/backups/${backupId}`);
    }

    getSystemBackupDownloadLink(backupId: string) {
        return `${this.urlRoot}/system/backups/${backupId}?contentType=zip`;
    }

    createSystemBackup() {
        return this.postJson<void>("/system/backups", null, "text");
    }

    restoreSystemBackup(uploadedSystemBackup: System.UploadedSystemBackup, file: File, progressHandler?: (event: ProgressEvent) => void) {
        return this.postFormData<void>(
            "/system/backups/restore",
            IHostWebApi.createFileFormData(uploadedSystemBackup, "UploadedSystemBackup", [file], "data"),
            "text",
            progressHandler);
    }

    deleteSystemBackup(backupId: string) {
        return this.deleteRequest<void>(`/system/backups/${backupId}`);
    }

    getSystemBackupProgress() {
        return this.ajaxRequest<System.SystemBackupProgress>("/system/backups/progress");
    }

    exportToCsv(csvContent: string, filename?: string) {
        filename = filename || "results.csv";

        if ($("form#hidden-form").length < 1) {
            $("<form>").attr(
                {
                    action: this.urlRoot + "/csv",
                    id: "hidden-form",
                    method: "POST",
                }).appendTo("body");
        }

        const fields: { [index: string]: string } = { Csv: csvContent, Filename: filename };

        $("form#hidden-form").html("");
        for (const propertyName in fields) {
            if (fields.hasOwnProperty(propertyName)) {
                $("<input>").attr({
                    id: propertyName,
                    name: propertyName,
                    type: "hidden",
                    value: fields[propertyName],
                }).appendTo("#hidden-form");
            }
        }

        $("#hidden-form").submit();

        return false;
    }

    getServiceLogEvents(
        serviceType: Enums.SystemServiceType,
        serviceName: string,
        optionalArguments?: Args.ServiceLogEventsArguments
    ) {
        return this.ajaxRequest<Args.Collection<System.ServiceLogEvent>>("/system/servicelogs/events", {
            serviceType,
            serviceName,
            fromTime: optionalArguments?.fromTime,
            toTime: optionalArguments?.toTime,
            toInclusive: optionalArguments?.toInclusive,
            searchText: optionalArguments?.searchText,
            levels: optionalArguments?.levels,
            minLevel: optionalArguments?.minLevel,
            componentNames: optionalArguments?.componentNames,
            limit: optionalArguments?.limit
        });
    }


    getServiceLogEventsDownloadLink(
        serviceType: Enums.SystemServiceType,
        serviceName: string,
        format: Enums.ServiceLogEventsFormat,
        optionalArguments?: Args.ServiceLogEventsArguments
    ) {
        return `${this.urlRoot}/system/servicelogs/events${IHostWebApi.createQueryString(
        {
            serviceType,
            serviceName,
            format,
        },
        {
            fromTime: optionalArguments?.fromTime,
            toTime: optionalArguments?.toTime,
            toInclusive: optionalArguments?.toInclusive,
            searchText: optionalArguments?.searchText,
            levels: optionalArguments?.levels,
            minLevel: optionalArguments?.minLevel,
            componentNames: optionalArguments?.componentNames,
            limit: optionalArguments?.limit
        })}`;
    }

    getSeries(getSeriesArgs: Args.GetSeriesArguments) {
        const args: any = {
            keys: this.getSeriesKeysAsStringArray(getSeriesArgs.keys),
            startTime: getSeriesArgs.startTime,
            endTime: getSeriesArgs.endTime,
        };
        if (getSeriesArgs.reducer) {
            args.reducer = getSeriesArgs.reducer.serialize();
        }
        if (getSeriesArgs.interpolation) {
            args.interpolation = getSeriesArgs.interpolation.serialize();
        }
        if (getSeriesArgs.rollup) {
            args.rollup = getSeriesArgs.rollup.serialize();
        }
        if (getSeriesArgs.compression) {
            args.compression = getSeriesArgs.compression.serialize();
        }

        return this.ajaxRequest<TimeSeries.Series>("/timeseries/series", $.extend({}, getSeriesArgs, args));
    }

    getSeriesCsvDownloadLink(getSeriesArgs: Args.GetSeriesArguments) {
        const args: any = {
            keys: this.getSeriesKeysAsStringArray(getSeriesArgs.keys),
            startTime: getSeriesArgs.startTime,
            endTime: getSeriesArgs.endTime,
        };
        if (getSeriesArgs.reducer) {
            args.reducer = getSeriesArgs.reducer.serialize();
        }
        if (getSeriesArgs.interpolation) {
            args.interpolation = getSeriesArgs.interpolation.serialize();
        }
        if (getSeriesArgs.rollup) {
            args.rollup = getSeriesArgs.rollup.serialize();
        }
        if (getSeriesArgs.compression) {
            args.compression = getSeriesArgs.compression.serialize();
        }

        return `${this.urlRoot}/timeseries/series?format=csv&${$.param($.extend({}, getSeriesArgs, args))}`;
    }

    findSeries(findSeriesArgs: Args.GetFindSeriesArguments) {
        const args: any = {
            keys: this.getSeriesKeysAsStringArray(findSeriesArgs.keys),
            mode: findSeriesArgs.mode,
            startTime: findSeriesArgs.startTime,
            endTime: findSeriesArgs.endTime
        };
        if (findSeriesArgs.window) {
            args.window = findSeriesArgs.window.serialize();
        }
        if (findSeriesArgs.reducer) {
            args.reducer = findSeriesArgs.reducer.serialize();
        }

        return this.ajaxRequest<TimeSeries.Series>("/timeseries/find", $.extend({}, findSeriesArgs, args));
    }

    getDurationCurve(getDurationCurveArgs: Args.GetDurationCurveArguments) {
        const args: any = {
            keys: this.getSeriesKeysAsStringArray(getDurationCurveArgs.keys),
            startTime: getDurationCurveArgs.startTime,
            endTime: getDurationCurveArgs.endTime,
        };
        if (getDurationCurveArgs.reducer) {
            args.reducer = getDurationCurveArgs.reducer.serialize();
        }

        return this.ajaxRequest<TimeSeries.DurationCurve>("/timeseries/durationcurve", $.extend({}, getDurationCurveArgs, args));
    }

    getHistogram(getHistogramArgs: Args.GetHistogramArguments) {
        const args: any = {
            keys: this.getSeriesKeysAsStringArray(getHistogramArgs.keys),
            startTime: getHistogramArgs.startTime,
            endTime: getHistogramArgs.endTime,
        };
        if (getHistogramArgs.reducer) {
            args.reducer = getHistogramArgs.reducer.serialize();
        }

        return this.ajaxRequest<TimeSeries.Histogram>("/timeseries/histogram", $.extend({}, getHistogramArgs, args));
    }

    private getSeriesKeysAsStringArray(seriesKeys: TimeSeries.SeriesKey[]): string[] {
        return seriesKeys.map(k => {
            let seriesKey = k.RtuId + ":" + k.IOReference;
            if (k.Modifier !== void 0) {
                seriesKey += ":" + this.getSeriesModifierAsString(k.Modifier)
            }
            return seriesKey;
        });
    }

    private getSeriesModifierAsString(seriesModifier: Enums.SeriesModifier): string {
        switch (seriesModifier) {
            case Enums.SeriesModifier.Static:
                return "static";
            default:
                return "";
        }
    }

    private static getFrameRequestArgs(getFrameArgs: Args.FrameArguments): Args.FrameRequestArguments {
        const startTime = getFrameArgs.startTime;
        const endTime = getFrameArgs.endTime;
        const args: Args.FrameRequestArguments = {
                startTime : startTime,
                endTime : endTime,
        };
        if (getFrameArgs.interpolation) {
            args.interpolation = getFrameArgs.interpolation.serialize();
        }
        if (getFrameArgs.rollup) {
            args.rollup = getFrameArgs.rollup.serialize();
        }
        if (getFrameArgs.rollupPerSeries) {
            args.rollupPerSeries = getFrameArgs.rollupPerSeries.map((r) => r !== null ? r.serialize() : "");
        }
        if (getFrameArgs.compression) {
            args.compression = getFrameArgs.compression.serialize();
        }
        if (getFrameArgs.compressionPerSeries) {
            args.compressionPerSeries = getFrameArgs.compressionPerSeries.map((r) => r !== null ? r.serialize() : "");
        }
        return args;
    }

    getFrame(getFrameArgs: Args.GetFrameArguments) {
        const args = IHostWebApi.getFrameRequestArgs(getFrameArgs) as Args.GetFrameRequestArguments;
        args.keys = this.getSeriesKeysAsStringArray(getFrameArgs.keys);
        return this.ajaxRequest<TimeSeries.Frame>("/timeseries/frame", $.extend({}, getFrameArgs, args));
    }

    getFrameCsvDownloadLink(getFrameArgs: Args.GetFrameArguments) {
        const args = IHostWebApi.getFrameRequestArgs(getFrameArgs) as Args.GetFrameRequestArguments;
        args.keys = this.getSeriesKeysAsStringArray(getFrameArgs.keys);
        args.format = "csv";
        return `${this.urlRoot}/timeseries/frame?${$.param($.extend({}, getFrameArgs, args))}`;
    }

    getSeriesForRtu(rtuId: number, getSeriesForRtuArgs: Args.GetSeriesForRtuArguments) {
        const args: any = {
            startTime: getSeriesForRtuArgs.startTime,
            endTime: getSeriesForRtuArgs.endTime,
        };
        if (getSeriesForRtuArgs.reducer) {
            args.reducer = getSeriesForRtuArgs.reducer.serialize();
        }
        if (getSeriesForRtuArgs.interpolation) {
            args.interpolation = getSeriesForRtuArgs.interpolation.serialize();
        }
        if (getSeriesForRtuArgs.rollup) {
            args.rollup = getSeriesForRtuArgs.rollup.serialize();
        }
        if (getSeriesForRtuArgs.compression) {
            args.compression = getSeriesForRtuArgs.compression.serialize();
        }

        return this.ajaxRequest<TimeSeries.Series>(`/rtus/${rtuId}/timeseries/series`, $.extend({}, getSeriesForRtuArgs, args));
    }

    getSeriesForRtuCsvDownloadLink(rtuId: number, getSeriesForRtuArgs: Args.GetSeriesForRtuArguments) {
        const args: any = {
            startTime: getSeriesForRtuArgs.startTime,
            endTime: getSeriesForRtuArgs.endTime,
        };
        if (getSeriesForRtuArgs.reducer) {
            args.reducer = getSeriesForRtuArgs.reducer.serialize();
        }
        if (getSeriesForRtuArgs.interpolation) {
            args.interpolation = getSeriesForRtuArgs.interpolation.serialize();
        }
        if (getSeriesForRtuArgs.rollup) {
            args.rollup = getSeriesForRtuArgs.rollup.serialize();
        }
        if (getSeriesForRtuArgs.compression) {
            args.compression = getSeriesForRtuArgs.compression.serialize();
        }
        return `${this.urlRoot}/rtus/${rtuId}/timeseries/series?format=csv&${$.param($.extend({}, getSeriesForRtuArgs, args))}`;
    }

    findSeriesForRtu(rtuId: number, findSeriesForRtuArgs: Args.GetFindSeriesForRtuArguments) {
        const args: any = {
            mode: findSeriesForRtuArgs.mode,
            startTime: findSeriesForRtuArgs.startTime,
            endTime: findSeriesForRtuArgs.endTime,
        };
        if (findSeriesForRtuArgs.window) {
            args.window = findSeriesForRtuArgs.window.serialize();
        }
        if (findSeriesForRtuArgs.reducer) {
            args.reducer = findSeriesForRtuArgs.reducer.serialize();
        }

        return this.ajaxRequest<TimeSeries.Series>(`/rtus/${rtuId}/timeseries/find`, $.extend({}, findSeriesForRtuArgs, args));
    }

    getDurationCurveForRtu(rtuId: number, getDurationCurveForRtuArgs: Args.GetDurationCurveForRtuArguments) {
        const args: any = {
            startTime: getDurationCurveForRtuArgs.startTime,
            endTime: getDurationCurveForRtuArgs.endTime,
        };
        if (getDurationCurveForRtuArgs.reducer) {
            args.reducer = getDurationCurveForRtuArgs.reducer.serialize();
        }

        return this.ajaxRequest<TimeSeries.DurationCurve>(`/rtus/${rtuId}/timeseries/durationcurve`,
            $.extend({}, getDurationCurveForRtuArgs, args));
    }

    getHistogramForRtu(rtuId: number, getHistogramForRtuArgs: Args.GetHistogramForRtuArguments) {
        const args: any = {
            startTime: getHistogramForRtuArgs.startTime,
            endTime: getHistogramForRtuArgs.endTime,
        };
        if (getHistogramForRtuArgs.reducer) {
            args.reducer = getHistogramForRtuArgs.reducer.serialize();
        }

        return this.ajaxRequest<TimeSeries.Histogram>(`/rtus/${rtuId}//timeseries/histogram`, $.extend({}, getHistogramForRtuArgs, args));
    }

    getFrameForRtu(rtuId: number, getFrameForRtuArgs: Args.GetFrameForRtuArguments) {
        const args = IHostWebApi.getFrameRequestArgs(getFrameForRtuArgs);
        return this.ajaxRequest<TimeSeries.Frame>(`/rtus/${rtuId}/timeseries/frame`, $.extend({}, getFrameForRtuArgs, args));
    }

    getFrameForRtuCsvDownloadLink(rtuId: number, getFrameForRtuArgs: Args.GetFrameForRtuArguments) {
        const args = IHostWebApi.getFrameRequestArgs(getFrameForRtuArgs);
        args.format = "csv";
        return `${this.urlRoot}/rtus/${rtuId}/timeseries/frame?${$.param($.extend({}, getFrameForRtuArgs, args))}`;
    }

    getPQDataTimeSeriesFrame(getPQDataTimeSeriesFrameArguments: Args.GetPQDataTimeSeriesFrameArguments) {
        const args = IHostWebApi.getFrameRequestArgs(getPQDataTimeSeriesFrameArguments);
        return this.ajaxRequest<TimeSeries.Frame>(`/pqdata/timeseries/frame`, $.extend({}, getPQDataTimeSeriesFrameArguments, args));
    }

    getPQDataTimeSeriesFrameCsvDownloadLink(getPQDataTimeSeriesFrameArguments: Args.GetPQDataTimeSeriesFrameArguments) {
        const args = IHostWebApi.getFrameRequestArgs(getPQDataTimeSeriesFrameArguments);
        args.format = "csv";
        return `${this.urlRoot}/pqdata/timeseries/frame?${$.param($.extend({}, getPQDataTimeSeriesFrameArguments, args))}`;
    }

    getRtuNotes(rtuId: number, pinned: boolean | null = null, includeDeleted: boolean = false) {
        return this.ajaxRequest<Args.Collection<RtuNotes.RtuNote>>("/rtus/" + rtuId + "/notes", { pinned, includeDeleted });
    }

    getRtuNote(rtuId: number, noteId: number, includeDeleted: boolean = false) {
        return this.ajaxRequest<RtuNotes.RtuNote>("/rtus/" + rtuId + "/notes/" + noteId, { includeDeleted });
    }

    getRtuNoteRevisions(rtuId: number, noteId: number) {
        return this.ajaxRequest<Args.Collection<RtuNotes.RtuNoteRevision>>("/rtus/" + rtuId + "/notes/" + noteId + "/revisions");
    }

    addRtuNote(rtuId: number, note: Pick<RtuNotes.RtuNote, "RtuId" | "Title" | "Text">) {
        return this.postJson<number>("/rtus/" + rtuId + "/notes", note);
    }

    editRtuNote(
        rtuId: number, noteId: number,
        note: Pick<RtuNotes.RtuNote, "RtuNoteId" | "RtuId" | "Title" | "Text" | "Revision">) {
        return this.putJson<void>("/rtus/" + rtuId + "/notes/" + noteId, note);
    }

    deleteRtuNote(rtuId: number, noteId: number) {
        return this.deleteRequest<void>("/rtus/" + rtuId + "/notes/" + noteId);
    }

    pinRtuNote(rtuId: number, noteId: number, pinRtuNoteRequest: RtuNotes.PinRtuNoteRequest) {
        return this.putJson<void>("/rtus/" + rtuId + "/notes/" + noteId + "/pin", pinRtuNoteRequest);
    }

    getCustomerFiles(customerId: number, optionalArguments?: Args.CustomerFilesArguments) {
        return this.ajaxRequest<Args.Collection<CustomerFiles.CustomerFile>>(
            `/customers/${customerId}/files`,
            $.extend({}, {
                fileIds: optionalArguments?.fileIds,
                folderIds: optionalArguments?.folderIds,
                folderPaths: optionalArguments?.folderPaths,
                includeSubfolders: optionalArguments?.includeSubfolders,
                nameFilter: optionalArguments?.nameFilter,
                modifiedFrom: optionalArguments?.modifiedTime?.from,
                modifiedTo: optionalArguments?.modifiedTime?.to,
                modifiedToInclusive: optionalArguments?.modifiedTime?.toInclusive,
                addedFrom: optionalArguments?.addedTime?.from,
                addedTo: optionalArguments?.addedTime?.to,
                addedToInclusive: optionalArguments?.addedTime?.toInclusive,
                extensions: optionalArguments?.extensions,
                minFileId: optionalArguments?.minFileId,
                maxFileId: optionalArguments?.maxFileId,
                page: optionalArguments?.page,
                pageSize: optionalArguments?.pageSize,
                sortColumn: optionalArguments?.sortColumn,
                sortOrder: optionalArguments?.sortOrder
            }));
    }

    getCustomerFilesDownloadLink(customerId: number, customerFileIds?: number[], folderIds?: number[], downloadFilename?: string) {
        const queryParams = $.param({
            ...customerFileIds ? { fileIds: customerFileIds } : {},
            ...folderIds ? { folderIds } : {},
            ...downloadFilename ? { downloadFilename } : {},
            contentType: "zip"
        });

        return `${this.urlRoot}/customers/${customerId}/files?${queryParams}`;
    }

    getCustomerFile(customerId: number, customerFileId: number) {
        return this.ajaxRequest<CustomerFiles.CustomerFile>(`/customers/${customerId}/files/${customerFileId}`);
    }

    getCustomerFileDownloadLink(customerId: number, customerFileId: number, contentType?: Enums.FileContentType) {
        const requestContentType = this.getFileContentTypeAsString(contentType ?? Enums.FileContentType.Raw);
        return `${this.urlRoot}/customers/${customerId}/files/${customerFileId}?contentType=${requestContentType}`;
    }

    copyCustomerFile(customerId: number, customerFileId: number, customerFileCopy: { CustomerIds: number[] }) {
        return this.postJson<number[]>(`/customers/${customerId}/files/${customerFileId}/copy`, customerFileCopy);
    }

    uploadCustomerFile(customerId: number, uploadedCustomerFile: CustomerFiles.UploadedCustomerFile, file: File, progressHandler?: (event: ProgressEvent) => void) {
        return this.postFormData<number>(`/customers/${customerId}/files`,
            IHostWebApi.createFileFormData(uploadedCustomerFile, "CustomerFile", [file], "data"), void 0, progressHandler);
    }

    updateCustomerFile(customerId: number, customerFileId: number, customerFile: CustomerFiles.UpdateCustomerFileRequest) {
        return this.putJson<void>(`/customers/${customerId}/files/${customerFileId}/metadata`, customerFile);
    }

    deleteCustomerFile(customerId: number, customerFileId: number) {
        return this.deleteRequest<void>(`/customers/${customerId}/files/${customerFileId}`);
    }

    deleteCustomerFiles(customerId: number, customerFileIds: number[]) {
        return this.deleteRequest<void>(`/customers/${customerId}/files?` + $.param({ customerFileIds }));
    }

    getCustomerFileFolders(customerId: number, folderIds?: number[]): Promise<Args.Collection<CustomerFiles.CustomerFileFolder>> {
        return this.ajaxRequest<Args.Collection<CustomerFiles.CustomerFileFolder>>(`/customers/${customerId}/files/folders`, $.extend({}, {
            folderIds
        }));
    }

    getCustomerFileFoldersDownloadLink(customerId: number, folderIds?: number[], downloadFilename?: string): string {
        const queryParams = $.param({
            ...folderIds ? { folderIds } : {},
            ...downloadFilename ? { downloadFilename } : {},
            contentType: "zip"
        });

        return `${this.urlRoot}/customers/${customerId}/files/folders?${queryParams}`;
    }


    getCustomerFileFolder(customerId: number, folderId: number): Promise<CustomerFiles.CustomerFileFolder> {
        return this.ajaxRequest<CustomerFiles.CustomerFileFolder>(`/customers/${customerId}/files/folders/${folderId}`);
    }

    getCustomerFileFolderDownloadLink(customerId: number, folderId: number, downloadFilename: string): string {
        const queryParams = $.param({
            ...downloadFilename ? { downloadFilename } : {},
            contentType: "zip"
        })

        return `${this.urlRoot}/customers/${customerId}/files/folders/${folderId}?${queryParams}`;
    }

    addCustomerFileFolder(customerId: number, customerFileFolder: CustomerFiles.CustomerFileFolder): Promise<number> {
        return this.postJson<number>(`/customers/${customerId}/files/folders`, customerFileFolder);
    }

    updateCustomerFileFolder(customerId: number, folderId: number, customerFileFolder: CustomerFiles.CustomerFileFolder): Promise<void> {
        return this.putJson<void>(`/customers/${customerId}/files/folders/${folderId}/metadata`, customerFileFolder);
    }

    deleteCustomerFileFolder(customerId: number, folderId: number): Promise<void> {
        return this.deleteRequest<void>(`/customers/${customerId}/files/folders/${folderId}`);
    }

    deleteCustomerFileFolders(customerId: number, folderIds: number[]): Promise<void> {
        return this.deleteRequest<void>(`/customers/${customerId}/files/folders?` + $.param({ folderIds }));
    }
    
    getAllCustomerSchedules(args: CustomerSchedules.GetAllCustomerSchedulesArgs) {
        return this.ajaxRequest<Args.Collection<CustomerSchedules.CustomerSchedule>>(`/customers/schedules`, args);
    }

    getCustomerSchedules(customerId: number, basicDetails?: boolean) {
        return this.ajaxRequest<Args.Collection<CustomerSchedules.CustomerSchedule>>(`/customers/${customerId}/schedules`, { basicDetails });
    }

    getCustomerSchedule(customerId: number, customerScheduleId: number, basicDetails?: boolean) {
        return this.ajaxRequest<CustomerSchedules.CustomerSchedule>(`/customers/${customerId}/schedules/${customerScheduleId}`, { basicDetails });
    }

    addCustomerSchedule(customerId: number, customerSchedule: CustomerSchedules.CustomerSchedule) {
        return this.postJson<number>(`/customers/${customerId}/schedules`, customerSchedule);
    }

    updateCustomerSchedule(customerId: number, customerScheduleId: number, customerSchedule: CustomerSchedules.CustomerSchedule) {
        return this.putJson<void>(`/customers/${customerId}/schedules/${customerScheduleId}`, customerSchedule);
    }

    deleteCustomerSchedule(customerId: number, customerScheduleId: number) {
        return this.deleteRequest<void>(`/customers/${customerId}/schedules/${customerScheduleId}`);
    }

    getMapSources() {
        return this.ajaxRequest<Args.Collection<Maps.MapSource>>("/mapsources");
    }

    getMapSource(mapSourceId: number) {
        return this.ajaxRequest<Maps.MapSource>(`/mapsources/${mapSourceId}`);
    }    

    addMapSource(mapSource: Maps.AddMapSourceRequest) {
        return this.postJson<number>("/mapsources", mapSource);
    }

    updateMapSource(mapSourceId: number, mapSource: Maps.UpdateMapSourceRequest) {
        return this.putJson<void>(`/mapsources/${mapSourceId}`, mapSource);
    }

    deleteMapSource(mapSourceId: number) {
        return this.deleteRequest<void>(`/mapsources/${mapSourceId}`);
    }

    deleteMapSources(request: Maps.DeleteMapSourcesRequest): Promise<void> {
        return this.deleteJson<void>("/mapsources/", request);
    }

    getMapSourceProxyUrl(mapSourceId: number) {
        return `${this.urlRoot}/mapsources/${mapSourceId}/proxy`;
    }

    getNetworks(optionalArguments?: Args.GetNetworksArguments) {
        return this.ajaxRequest<Args.Collection<networks.Network>>("/networks", optionalArguments);
    }

    getNetwork(networkId: number, optionalArguments?: Args.GetNetworkArguments) {
        return this.ajaxRequest<networks.Network>(`/networks/${networkId}`, optionalArguments);
    }

    getNetworkDefinitionDownloadLink(networkId: number, optionalArguments?: Args.GetNetworkArguments) {
        return `${this.urlRoot}/networks/${networkId}` + IHostWebApi.createQueryString({ format: "jsonNetwork" }, optionalArguments);
    }

    getNetworkBackgroundImageUrl(networkId: number, checksum: string) {
        return `${this.urlRoot}/networks/${networkId}/backgroundimage?checksum=${checksum}`;
    }

    addNetwork(addNetworkRequest: networks.AddNetworkRequest) {
        return this.postJson<number>("/networks", addNetworkRequest);
    }

    updateNetwork(networkId: number, updateNetworkRequest: networks.UpdateNetworkRequest, partial: boolean = false) {
        return this.putJson<void>(`/networks/${networkId}` + IHostWebApi.createQueryString({ partial }), updateNetworkRequest);
    }

    uploadNetworkDefinition(networkId: number, definition: File, progressHandler?: (event: ProgressEvent) => void) {
        return this.putFormData<void>(`/networks/${networkId}?format=jsonNetwork`, IHostWebApi.createFileForm(definition), progressHandler);
    }

    updateNetworkBackgroundImage(networkId: number, imageFile: File, processHandler?: (event: ProgressEvent) => void) {
        return this.putFormData<void>(`/networks/${networkId}/backgroundimage`, IHostWebApi.createFileForm(imageFile), processHandler);
    }

    deleteNetwork(networkId: number) {
        return this.deleteRequest<void>(`/networks/${networkId}`);
    }

    deleteNetworkBackgroundImage(networkId: number) {
        return this.deleteRequest<void>(`/networks/${networkId}/backgroundimage`);
    }

    getNetworkFaults(args?: networkFaults.GetNetworkFaultsArgs): Promise<Args.Collection<networkFaults.NetworkFault>> {
        return this.ajaxRequest<Args.Collection<networkFaults.NetworkFault>>("/networkfaults", args);
    }

    getNetworkFault(networkFaultId: number, args?: networkFaults.GetNetworkFaultArgs): Promise<networkFaults.NetworkFault> {
        return this.ajaxRequest<networkFaults.NetworkFault>(`/networkfaults/${networkFaultId}`, args);
    }

    getNetworkFaultLocations(networkFaultId: number): Promise<Args.Collection<networkFaults.NetworkFaultLocation>> {
        return this.ajaxRequest<Args.Collection<networkFaults.NetworkFaultLocation>>(`/networkfaults/${networkFaultId}/faultlocations`);
    }

    getNetworkFaultLocation(networkFaultId: number, networkFaultLocationId: number): Promise<networkFaults.NetworkFaultLocation> {
        return this.ajaxRequest<networkFaults.NetworkFaultLocation>(`/networkfaults/${networkFaultId}/faultlocations/${networkFaultLocationId}`);
    }

    getNetworkFaultLocationPlotUrl(networkFaultId: number, networkFaultLocationId: number, contentType?: Enums.FileContentType): string {
        const requestContentType = this.getFileContentTypeAsString(contentType ?? Enums.FileContentType.Raw);
        return `${this.urlRoot}/networkfaults/${networkFaultId}/faultlocations/${networkFaultLocationId}/plot?contentType=${requestContentType}`;
    }

    calculateNetworkFaultLocation(networkFaultId: number, request: networkFaults.CalculateNetworkFaultLocationRequest): Promise<number> {
        return this.postJson<number>(`/networkfaults/${networkFaultId}/faultlocations`, request);
    }
    
    getNetworkFaultNotificationHistory(networkFaultId: number) {
        return this.ajaxRequest<Args.Collection<Notifications.Message>>(`/networkfaults/${networkFaultId}/notificationhistory`);
    }
    
    getNetworkFaultTimeSummaries(args: networkFaults.GetNetworkFaultTimeSummariesArgs): Promise<Args.Collection<networkFaults.NetworkFaultTimeSummary>> {
        return this.ajaxRequest<Args.Collection<networkFaults.NetworkFaultTimeSummary>>("/networkfaults/timesummary", args);
    }

    getNetworkFaultTimeBucket(args: networkFaults.GetNetworkFaultTimeBucketArgs): Promise<networkFaults.NetworkFaultTimeBucket> {
        return this.ajaxRequest<networkFaults.NetworkFaultTimeBucket>("/networkfaults/timebucket", args);
    }

    getNetworkSnapshots(networkId: number) {
        return this.ajaxRequest<Args.Collection<networks.NetworkSnapshot>>(`/networks/${networkId}/snapshots`);
    }

    getNetworkSnapshot(networkId: number, networkSnapshotId: number) {
        return this.ajaxRequest<networks.NetworkSnapshot>(`/networks/${networkId}/snapshots/${networkSnapshotId}`);
    }

    getNetworkSnapshotDefinitionDownloadLink(networkId: number, networkSnapshotId: number) {
        return `${this.urlRoot}/networks/${networkId}/snapshots/${networkSnapshotId}?format=jsonNetwork`;
    }

    createNetworkSnapshot(networkId: number, createNetworkSnapshotRequest: networks.CreateNetworkSnapshotRequest) {
        return this.postJson<number>(`/networks/${networkId}/snapshots`, createNetworkSnapshotRequest);
    }

    deleteNetworkSnapshots(networkId: number, deleteNetworkSnapshotsRequest: networks.DeleteNetworkSnapshotsRequest) {
        return this.deleteJson<void>(`/networks/${networkId}/snapshots`, deleteNetworkSnapshotsRequest);
    }

    revertToNetworkSnapshot(networkId: number, networkSnapshotId: number) {
        return this.ajaxRequest<void>(`/networks/${networkId}/snapshots/${networkSnapshotId}/revert`, void 0, "POST");
    }

    getNetworkFaultNotifications(networkId: number, includeAddressBookEntries?: boolean) {
        return this.ajaxRequest<Args.Collection<networks.NetworkFaultNotification>>(`/networks/${networkId}/faultnotifications`, { includeAddressBookEntries });
    }

    addNetworkFaultNotifications(networkId: number, request: networks.AddNetworkFaultNotificationsRequest) {
        return this.postJson<void>(`/networks/${networkId}/faultnotifications`, request);
    }

    bulkAddNetworkFaultNotifications(request: networks.AddNetworkFaultNotificationsRequest) {
        return this.postJson<void>("/networks/faultnotifications", request);
    }

    deleteNetworkFaultNotifications(networkId: number, request: networks.DeleteNetworkFaultNotificationsRequest) {
        return this.deleteJson<void>(`/networks/${networkId}/faultnotifications`, request);
    }

    bulkDeleteNetworkFaultNotifications(request: networks.DeleteNetworkFaultNotificationsRequest) {
        return this.deleteJson<void>("/networks/faultnotifications", request);
    }

    getUserPermissions(username: string) {
        return this.ajaxRequest<string[]>("/users/" + username + "/permissions");
    }

    getCurrentUserPermissions() {
        return this.ajaxRequest<string[]>("/users/current/permissions");
    }

    getCertificates(certificateTypes?: Enums.CertificateType[], basicDetails?: boolean) {
        return this.ajaxRequest<Args.Collection<Certificates.Certificate>>("/certs", { certificateTypes, basicDetails });
    }

    getCertificate(certificateId: number, basicDetails?: boolean) {
        return this.ajaxRequest<Certificates.Certificate>(`/certs/${certificateId}`, { basicDetails });
    }

    getCertificateExportLink(certificateId: number) {
        return `${this.urlRoot}/certs/${certificateId}?contentType=pfx`;
    }

    getCertificateDownloadLink(certificateId: number) {
        return `${this.urlRoot}/certs/${certificateId}?contentType=raw`;
    }

    uploadSystemCertificate(uploadedCertificate: Certificates.UploadedCertificate) {
        return this.postJson<number>("/certs", uploadedCertificate);
    }

    generateSystemCertificate(certificateSigningRequest: Certificates.CertificateSigningRequest) {
        return this.postJson<number>("/certs/new", certificateSigningRequest);
    }

    deleteCertificate(certificateId: number) {
        return this.deleteRequest<void>(`/certs/${certificateId}`);
    }

    getRtuCertificate(rtuId: number, basicDetails?: boolean) {
        return this.ajaxRequest<Certificates.Certificate>(`/rtus/${rtuId}/cert`, { basicDetails });
    }

    getRtuCertificateDownloadLink(rtuId: number) {
        return `${this.urlRoot}/rtus/${rtuId}/cert?contentType=raw`;
    }

    uploadRtuCertificate(rtuId: number, uploadedCertificate: Certificates.UploadedCertificate) {
        return this.postJson<number>(`/rtus/${rtuId}/cert`, uploadedCertificate);
    }

    generateRtuCertificate(rtuId: number, certificateSigningRequest: Certificates.CertificateSigningRequest) {
        return this.postJson<Certificates.CertificateSigningResponse>(`/rtus/${rtuId}/cert/new`, certificateSigningRequest);
    }

    updateRtuCertificateStatus(rtuId: number, certificateStatusRequest: Certificates.CertificateStatusRequest) {
        return this.putJson<void>(`/rtus/${rtuId}/cert/status`, certificateStatusRequest);
    }

    getTrends(customerIds?: number[], trendIds?: number[], trendTypes?: Enums.TrendType[]) {
        return this.ajaxRequest<Args.Collection<Trends.Trend>>("/trends", { customerIds, trendIds, trendTypes });
    }

    getTrend(trendId: number) {
        return this.ajaxRequest<Trends.Trend>(`/trends/${trendId}`);
    }

    addTrend(trend: Trends.Trend) {
        return this.postJson<number>(`/trends`, trend);
    }

    updateTrend(trendId: number, trend: Trends.Trend) {
        return this.putJson<void>(`/trends/${trendId}`, trend);
    }

    deleteTrend(trendId: number) {
        return this.deleteRequest<void>(`/trends/${trendId}`);
    }

    getRtuGroupTree(
        customerIds?: number[],
        rtuTypes?: Enums.RtuType[],
        protocols?: Enums.Protocol[],
        maxFirmwareVersion?: number,
        includeLocked?: boolean,
        includeOnline?: boolean,
        includeEmptyNodes?: boolean,
        includeSyncedRtus?: boolean) {
        return this.ajaxRequest<(Rtus.RtuGroupTreeRtu | Rtus.RtuGroupTreeRtuGroup | Rtus.RtuGroupTreeCustomer)[]>("/rtus/grouptree", $.extend({},
            {
                customerIds: customerIds,
                rtuTypes: rtuTypes,
                protocols: protocols,
                maxFirmwareVersion: maxFirmwareVersion,
                includeLocked: includeLocked,
                includeOnline: includeOnline,
                includeEmptyNodes: includeEmptyNodes,
                includeSyncedRtus: includeSyncedRtus
            }));
    }

    getVirtualRtus(getVirtualRtusArgs?: Args.GetVirtualRtusArguments) {
        return this.ajaxRequest<Args.Collection<VirtualRtus.VirtualRtu>>("/virtualrtus", getVirtualRtusArgs);
    }

    getVirtualRtu(virtualRtuId: number, includeAdvancedSettings?: boolean, includePointSummary?: boolean) {
        return this.ajaxRequest<VirtualRtus.VirtualRtu>(`/virtualrtus/${virtualRtuId}`, {
            includeAdvancedSettings,
            includePointSummary
        });
    }

    updateVirtualRtu(virtualRtuId: number, request: VirtualRtus.UpdateVirtualRtuRequest) {
        return this.putJson<void>(`/virtualrtus/${virtualRtuId}`, request);
    }

    deleteVirtualRtu(virtualRtuId: number) {
        return this.deleteRequest<void>(`/virtualrtus/${virtualRtuId}`);
    }

    deleteVirtualRtus(request: VirtualRtus.DeleteVirtualRtusRequest): Promise<void> {
        return this.deleteJson<void>("/virtualrtus/", request);
    }

    getVirtualRtuAdvancedSettings(virtualRtuId: number) {
        return this.ajaxRequest<VirtualRtus.VirtualRtuAdvancedSettings>(`/virtualrtus/${virtualRtuId}/settings/advanced`);
    }

    updateVirtualRtuAdvancedSettings(updateVirtualRtuAdvancedSettingsRequest: VirtualRtus.UpdateVirtualRtuAdvancedSettingsRequest) {
        return this.postJson<void>("/virtualrtus/settings/advanced", updateVirtualRtuAdvancedSettingsRequest);
    }

    getAllVirtualRtuPoints(getVirtualRtuPointsArgs?: Args.VirtualRtuPointsArguments | null) {
        return this.ajaxRequest<Args.PagedCollection<VirtualRtus.VirtualRtuPoint>>("/virtualrtus/points", getVirtualRtuPointsArgs);
    }

    getVirtualRtuPoints(virtualRtuId: number, rtuIds?: number[], virtualPointTypes?: Enums.VirtualPointType[], rtuFilter?: string, paging?: Args.PagingOptions, includeTotal?: boolean) {
        return this.ajaxRequest<Args.PagedCollection<VirtualRtus.VirtualRtuPoint>>(`/virtualrtus/${virtualRtuId}/points`, {
            rtuIds,
            virtualPointTypes,
            rtuFilter,
            ...paging,
            includeTotal
        });
    }

    getVirtualRtuPointsForScadaChannel(scadaChannelId: number, virtualPointTypes?: Enums.VirtualPointType[], paging?: Args.PagingOptions, includeTotal?: boolean) {
        return this.ajaxRequest<Args.PagedCollection<VirtualRtus.VirtualRtuPoint>>(`/scadachannels/${scadaChannelId}/virtualrtus/points`, {
            virtualPointTypes,
            ...paging,
            includeTotal
        });
    }

    addVirtualRtuPoints(virtualRtuId: number, addVirtualRtuPointsRequest: VirtualRtus.AddVirtualRtuPointsRequest) {
        return this.postJson<void>(`/virtualrtus/${virtualRtuId}/points`, addVirtualRtuPointsRequest);
    }

    deleteVirtualRtuPoints(request: VirtualRtus.DeleteVirtualRtuPointsRequest): Promise<void> {
        return this.deleteJson<void>("/virtualrtus/points", request);
    }

    deleteVirtualRtuPointsForVirtualRtu(virtualRtuId: number, virtualRtuPointIds?: number[] | null) {
        let url = `/virtualrtus/${virtualRtuId}/points`;
        if (virtualRtuPointIds) {
            url += "?" + $.param({virtualRtuPointIds: virtualRtuPointIds});
        }
        return this.deleteRequest<void>(url);
    }

    autoNumberVirtualRtuPoints(virtualRtuId: number, request: VirtualRtus.AutoNumberVirtualRtuPointsRequest) {
        return this.postJson<void>(`/virtualrtus/${virtualRtuId}/points/autonumber`, request);
    }

    testVirtualRtuPoints(virtualRtuId: number, request: VirtualRtus.TestVirtualRtuPointsRequest) {
        return this.putJson<void>(`/virtualrtus/${virtualRtuId}/points/test`, request);
    }

    getScadaChannelPointsDownloadLink(scadaChannelId: number) {
        return `${this.urlRoot}/scadachannels/${scadaChannelId}/virtualrtus/points?type=csv`;
    }

    getCidDownloadLink(scadaChannelId: number) {
        return `${this.urlRoot}/scadachannels/${scadaChannelId}/iec61850/cid?download=true`;
    }

    getVirtualRtuPointsDownloadLink(virtualRtuId: number) {
        return `${this.urlRoot}/virtualrtus/${virtualRtuId}/points?type=csv`;
    }

    uploadVirtualRtuPoints(
        virtualRtuId: number,
        request: VirtualRtus.AddVirtualRtuPointsRequest,
        file: File,
        progressHandler?: (event: ProgressEvent<EventTarget>) => void)
    {
        return this.postFormData<void>(
            `/virtualrtus/${virtualRtuId}/points`,
            IHostWebApi.createFileFormData(request, "metadata", [file], "file"),
            void 0,
            progressHandler);
    }

    uploadScadaChannelPoints(
        scadaChannelId: number,
        request: VirtualRtus.AddScadaChannelPointsRequest,
        file: File,
        progressHandler?: (event: ProgressEvent<EventTarget>) => void)
    {
        return this.postFormData<void>(
            `/scadachannels/${scadaChannelId}/virtualrtus/points`,
            IHostWebApi.createFileFormData(request, "metadata", [file], "file"),
            void 0,
            progressHandler);
    }

    getVirtualRtuUnassignedRtuPoints(virtualRtuId: number, args: Args.GetVirtualRtuUnassignedRtuPointsArguments) {
        return this.ajaxRequest<Args.Collection<VirtualRtus.UnassignedRtuPoint>>(`/virtualrtus/${virtualRtuId}/points/unassigned`, args);
    }

    getVirtualRtuUnassignedSystemComponentPoints(virtualRtuId: number, optionalArgs?: Args.GetVirtualRtuUnassignedSystemComponentPointsArguments) {
        return this.ajaxRequest<Args.Collection<VirtualRtus.UnassignedVirtualRtuPoint>>(`/virtualrtus/${virtualRtuId}/points/unassigned/systemcomponents`, optionalArgs);
    }

    addScadaChannel(request: Scada.AddScadaChannelRequest) {
        return this.postJson<number>("/scadachannels", request);
    }

    getAllScadaChannels(scadaChannelArgs?: Args.ScadaChannelArguments) {
        return this.ajaxRequest<Args.Collection<Scada.ScadaChannel>>("/scadachannels", scadaChannelArgs);
    }

    getScadaChannels(scadaInterfaceId: number) {
        return this.ajaxRequest<Args.Collection<Scada.ScadaChannel>>(`/scadainterfaces/${scadaInterfaceId}/channels`);
    }

    getScadaChannelById(scadaChannelId: number, includeAdvancedSettings?: boolean, includeSettings?: boolean) {
        return this.ajaxRequest<Scada.ScadaChannel>(`/scadachannels/${scadaChannelId}`, {
            includeAdvancedSettings: includeAdvancedSettings,
            includeSettings: includeSettings
        });
    }

    getScadaChannel(scadaInterfaceId: number, scadaChannelId: number) {
        return this.ajaxRequest<Scada.ScadaChannel>(`/scadainterfaces/${scadaInterfaceId}/channels/${scadaChannelId}`);
    }

    getScadaChannelAdvancedSettings(scadaChannelId: number) {
        return this.ajaxRequest<Scada.ScadaChannelAdvancedSettings>(`/scadachannels/${scadaChannelId}/settings/advanced`);
    }

    getScadaInterfaceAdvancedSettings(scadaChannelId: number) {
        return this.ajaxRequest<Scada.ScadaInterfaceAdvancedSettings>(`/scadainterfaces/${scadaChannelId}/settings/advanced`);
    }

    updateScadaChannel(scadaChannelId: number, request: Scada.UpdateScadaChannelRequest) {
        return this.putJson<void>(`/scadachannels/${scadaChannelId}`, request);
    }

    updateScadaChannelAdvancedSettings(request: Scada.UpdateScadaChannelAdvancedSettingsRequest) {
        return this.postJson<void>(`/scadachannels/settings/advanced`, request);
    }

    deleteScadaChannel(scadaChannelId: number) {
        return this.deleteRequest<void>(`/scadachannels/${scadaChannelId}`);
    }

    deleteScadaChannels(request: Scada.DeleteScadaChannelsRequest) {
        return this.deleteJson<void>(`/scadachannels`, request);
    }

    getScadaInterfaces(args?: Args.GetScadaInterfacesArgs) {
        return this.ajaxRequest<Args.Collection<Scada.ScadaInterface>>(`/scadainterfaces`, args);
    }

    getScadaInterface(scadaInterfaceId: number, args?: Args.GetScadaInterfaceArgs) {
        return this.ajaxRequest<Scada.ScadaInterface>(`/scadainterfaces/${scadaInterfaceId}`, args);
    }

    addScadaInterface(request: Scada.AddScadaInterfaceRequest) {
        return this.postJson<number>("/scadainterfaces", request);
    }

    updateScadaInterface(scadaInterfaceId: number, request: Scada.UpdateScadaInterfaceRequest) {
        return this.putJson<void>(`/scadainterfaces/${scadaInterfaceId}`, request);
    }

    updateScadaInterfaceAdvancedSettings(request: Scada.UpdateScadaInterfaceAdvancedSettingsRequest) {
        return this.postJson<void>(`/scadainterfaces/settings/advanced`, request);
    }

    deleteScadaInterfaces(request: Scada.DeleteScadaInterfacesRequest) {
        return this.deleteJson<void>(`/scadainterfaces`, request);
    }

    deleteScadaInterface(scadaInterfaceId: number) {
        return this.deleteRequest<void>(`/scadainterfaces/${scadaInterfaceId}`);
    }

    getCustomerMetricsValues(customerId: number, dateArguments?: Args.DateArguments, enabled?: boolean) {
        return this.ajaxRequest<Args.Collection<Metrics.CustomerMetricValue>>(`/customers/${customerId}/metrics/values`, $.extend({}, { 
            from: dateArguments?.from, 
            to: dateArguments?.to,
            enabled: enabled
        }));
    }

    getCustomerMetrics(enabled?: boolean) {
        return this.ajaxRequest<Args.Collection<Metrics.Metric>>("/customers/metrics", { enabled });
    }

    getSystemMetricsValues(dateArguments?: Args.DateArguments, enabled?: boolean) {
        return this.ajaxRequest<Args.Collection<Metrics.SystemMetricValue>>(`/system/metrics/values`, $.extend({}, { 
            from: dateArguments?.from, 
            to: dateArguments?.to,
            enabled: enabled
        }));
    }

    getSystemMetrics(enabled?: boolean) {
        return this.ajaxRequest<Args.Collection<Metrics.Metric>>("/system/metrics", { enabled });
    }

    getAddressBook(args?: AddressBookEntry.GetAddressBookArgs) {
        return this.ajaxRequest<Args.Collection<AddressBookEntry.AddressBookEntry>>("/addressbook", args);
    }

    getAddressBookEntry(addressBookEntryId: number, args?: AddressBookEntry.GetAddressBookEntryArgs) {
        return this.ajaxRequest<AddressBookEntry.AddressBookEntry>(`/addressbook/${addressBookEntryId}`, args);
    }

    addAddressBookEntry(request: AddressBookEntry.AddAddressBookEntryRequest) {
        return this.postJson<number>(`/addressbook`, request);
    }

    updateAddressBookEntry(addressBookEntryId: number, request: AddressBookEntry.UpdateAddressBookEntryRequest) {
        return this.putJson<void>(`/addressbook/${addressBookEntryId}`, request);
    }

    deleteAddressBookEntry(addressBookEntryId: number) {
        return this.deleteRequest<void>(`/addressbook/${addressBookEntryId}`);
    }

    deleteAddressBookEntries(request: AddressBookEntry.DeleteAddressBookEntriesRequest) {
        return this.deleteJson<void>("/addressbook", request);
    }
    
    testAddressBookEntry(addressBookEntryId: number) {
        return this.postRequest<number>(`/addressbook/${addressBookEntryId}/test`);
    }

    testAddressBookEntries(request: AddressBookEntry.TestAddressBookEntriesRequest) {
        return this.postJson<void>("/addressbook/test", request);
    }

    linkAddressBookEntry(addressBookEntryId: number, request: AddressBookEntry.LinkAddressBookEntryRequest) {
        return this.putJson<void>(`/addressbook/${addressBookEntryId}/link`, request);
    }

    moveAddressBookEntry(addressBookEntryId: number, request: AddressBookEntry.MoveAddressBookEntryRequest) {
        return this.putJson<void>(`/addressbook/${addressBookEntryId}/move`, request);
    }

    getSystemLogs(
        dateArguments?: Args.DateArguments,
        systemLogTypes?: Enums.SystemLogType[], 
        eventSortingArguments?: Args.SortingOptions<Enums.SystemLogSortColumn>,
        paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.Collection<SystemLog.SystemLog>>("/system/events", $.extend({}, { 
            from: dateArguments ? dateArguments.from : undefined,
            to: dateArguments ? dateArguments.to : undefined,
            systemLogTypes: systemLogTypes,
            sortColumn: eventSortingArguments ? eventSortingArguments.sortColumn : undefined,
            sortOrder: eventSortingArguments ? eventSortingArguments.sortOrder : undefined,
            page: paging ? paging.page : undefined,
            pageSize: paging ? paging.pageSize : undefined
        }));
    }

    getSystemLog(systemLogId: number) {
        return this.ajaxRequest<SystemLog.SystemLog>(`/system/events/${systemLogId}`);
    }

    deleteSystemLog(systemLogId: number) {
        return this.deleteRequest<void>(`/system/events/${systemLogId}`);
    }

    acknowledgeSystemLog(systemLogId: number, ack: boolean) {
        return this.putJson<void>(`/system/events/${systemLogId}/ack`, { Acknowledge: ack });
    }

    acknowledgeAllSystemLogs(ack: boolean) {
        return this.putJson<void>(`/system/events/ack`, { Acknowledge: ack });
    }

    getSystemLogNotifications(customerIds?: number[], includeAddressBookEntries = false) {
        return this.ajaxRequest<Args.Collection<SystemLog.SystemLogNotification>>("/system/eventnotifications", {
            customerIds,
            includeAddressBookEntries 
        });
    }

    addSystemLogNotifications(request: SystemLog.AddSystemLogNotificationsRequest): Promise<void> {
        return this.postJson<void>("/system/eventnotifications", request);
    }

    deleteSystemLogNotifications(request: SystemLog.DeleteSystemLogNotificationsRequest): Promise<void> {
        return this.deleteJson<void>("/system/eventnotifications", request);
    }

    getSystemLogNotificationHistory(systemLogId: number) {
        return this.ajaxRequest<Args.Collection<Notifications.Message>>(`/system/events/${systemLogId}/notificationhistory`);
    }

    getRtuTemplate(templateId: number, optionalArguments?: Args.IncludeTemplateSyncLinksArguments & Args.IncludePointSummaryArguments) {
        return this.ajaxRequest<Rtus.Rtu>(`/rtus/templates/${templateId}`, optionalArguments);
    }

    getTaskManagers() {
        return this.ajaxRequest<Args.Collection<TaskManager.TaskManager>>("/taskmanagers");
    }

    getTaskManager(taskManagerId: number) {
        return this.ajaxRequest<TaskManager.TaskManager>(`/taskmanagers/${taskManagerId}`);
    }

    getTaskManagerTasks(taskManagerId: number) {
        return this.ajaxRequest<Args.Collection<TaskManager.TaskModule>>(`/taskmanagers/${taskManagerId}/tasks`);
    }

    getTaskManagerTask(taskManagerId: number, taskId: number) {
        return this.ajaxRequest<TaskManager.TaskModule>(`taskmanagers/${taskManagerId}/tasks/${taskId}`);
    }

    getTaskManagerSettings(taskManagerId: number) {
        return this.ajaxRequest<Args.Collection<TaskManager.TaskManagerSetting>>(`taskmanagers/${taskManagerId}/settings`);
    }

    getTaskModuleSettings(taskManagerId: number, taskId: number) {
        return this.ajaxRequest<Args.Collection<TaskManager.TaskManagerSetting>>(`/taskmanagers/${taskManagerId}/tasks/${taskId}/settings`);
    }

    getCsms(includeCsmLines?: boolean) {
        return this.ajaxRequest<Args.Collection<Csm.Csm>>("/csms?", $.param({ includeCsmLines }));
    }

    getCsm(csmId: number, includeCsmLines?: boolean) {
        return this.ajaxRequest<Csm.Csm>(`/csms/${csmId}?`, $.param({ includeCsmLines }));
    }

    getCsmLines(csmId: number) {
        return this.ajaxRequest<Args.Collection<Csm.CsmLine>>(`/csms/${csmId}/lines`);
    }

    getCsmLine(csmId: number, csmLineId: number) {
        return this.ajaxRequest<Csm.CsmLine>(`/csms/${csmId}/lines/${csmLineId}`);
    }

    getCsmSettings(csmId: number) {
        return this.ajaxRequest<Args.Collection<Csm.CsmLineSetting>>(`/csms/${csmId}/settings`);
    }

    getCsmLineSettings(csmId: number, csmLineId: number) {
        return this.ajaxRequest<Args.Collection<Csm.CsmLineSetting>>(`/csms/${csmId}/lines/${csmLineId}/settings`);
    }

    copyRtuSettings(rtuId: number, copySettingsRequest: Rtus.CopyRtuSettingsRequest) {
        return this.postJson<Rtus.CopyRtuSettingsResponse>(`/rtus/${rtuId}/settings/copy`, copySettingsRequest);
    }

    bulkImportRtus(rtuBulkImportRequest: Rtus.RtuBulkImportRequest, file: File, progressHandler?: (event: ProgressEvent) => void) {
        return this.postFormData<Rtus.RtuBulkImportResponse>(
            "/rtus/import",
            IHostWebApi.createFileFormData(rtuBulkImportRequest, "metadata", [file], "data"),
            void 0,
            progressHandler);
    }

    getRtuTemplateSyncLinks(templateId: number) {
        return this.ajaxRequest<Args.Collection<Rtus.RtuTemplateSyncLink>>(`/rtus/templates/${templateId}/synclinks`);
    }

    getRtuTemplateSyncLink(rtuId: number, templateId: number) {
        return this.ajaxRequest<Rtus.RtuTemplateSyncLink>(`/rtus/${rtuId}/synclinks/${templateId}`);
    }

    getAssignableRtusForTemplateSync(templateId: number) {
        return this.ajaxRequest<Args.Collection<Rtus.Rtu>>(`/rtus/templates/${templateId}/synclinks/assignable`);
    }

    addRtuTemplateSyncLink(rtuId: number, templateId: number, link: Rtus.RtuTemplateSyncLink) {
        return this.postJson<void>(`/rtus/${rtuId}/synclinks/${templateId}`, link);
    }

    deleteRtuTemplateSyncLink(rtuId: number, templateId: number) {
        return this.deleteRequest<void>(`/rtus/${rtuId}/synclinks/${templateId}`);
    }

    deleteRtuTemplateSyncLinks(templateId: number, links: Rtus.RtuTemplateSyncLink[]) {
        return this.deleteJson<void>(`/rtus/templates/${templateId}/synclinks`, links);
    }

    getOutputOperations(
        rtuId: number,
        dateArguments: Args.DateArguments,
        paging?: Args.PagingOptions,
        sortingArguments?: Args.SortingOptions<Enums.OutputOperationSortColumn>) {
        paging = this.getPagingOptions(paging);
        return this.ajaxRequest<Args.PagedCollection<Inputs.OutputOperation>>("/rtus/" + rtuId + "/outputs/queue", $.extend({}, {
            page: paging.page,
            pageSize: paging.pageSize,
            from: dateArguments.from,
            to: dateArguments.to,
            sortBy: sortingArguments ? sortingArguments.sortColumn : undefined,
            sortOrder: sortingArguments ? sortingArguments.sortOrder : undefined
        }));
    }

    addVirtualRtu(virtualRtu: VirtualRtus.CreateVirtualRtuRequest) {
        return this.postJson<number>(`/virtualrtus`, virtualRtu);
    }

    getUserLoginDetails() {
        return this.ajaxRequest<Customers.UserLoginDetails>(`/users/current/logindetails`);
    }

    getReports(customerId: number, linked?: boolean) {
        return this.ajaxRequest<Args.Collection<Reports.Report>>("/reports", { customerId, linked });
    }

    getReport(reportId: number, customerId: number) {
        return this.ajaxRequest<Reports.Report>(`/reports/${reportId}`, { customerId });
    }

    importReport(reportId: number, customerId: number) {
        return this.postJson<void>(`/reports/${reportId}/customers/${customerId}`, null);
    }

    unlinkReport(reportId: number, customerId: number) {
        return this.deleteRequest<void>(`/reports/${reportId}/customers/${customerId}`);
    }

    unlinkReports(removeReportsRequest: Reports.RemoveReportsRequest) {
        return this.deleteJson<void>("/reports", removeReportsRequest);
    }

    getReportQueueEntries(
        customerId: number,
        dateArguments?: Args.DateArguments,
        includeAuthorDetails?: boolean,
        includeModifiedByDetails?: boolean,
        includeNotifications?: boolean,
        includeUnprocessedReports?: boolean,
        paging?: Args.PagingOptions,
        sorting?: Args.SortingOptions<Enums.ReportQueueSortColumn>
        ) {
            return this.ajaxRequest<Args.Collection<Reports.ReportQueueEntry>>("/reports/queueentries", 
            {
                customerId: customerId,
                from: dateArguments ? dateArguments.from : undefined,
                to: dateArguments ? dateArguments.to : undefined,
                includeAuthorDetails: includeAuthorDetails,
                includeModifiedByDetails: includeModifiedByDetails,
                includeNotifications: includeNotifications,
                includeUnprocessedReports: includeUnprocessedReports,
                page: paging ? paging.page : undefined,
                pageSize: paging ? paging.pageSize : undefined,
                sortBy: sorting ? sorting.sortColumn : undefined,
                sortOrder: sorting ? sorting.sortOrder : undefined
            });
    }

    getReportQueueEntry(entryId: number, includeAuthorDetails?: boolean, includeModifiedByDetails?: boolean, includeNotifications?: boolean) {
        return this.ajaxRequest<Reports.ReportQueueEntry>(`/reports/queueentries/${entryId}`, { 
            includeAuthorDetails, 
            includeModifiedByDetails,
            includeNotifications 
        });
    }

    getReportQueueEntryStatus(entryId: number) {
        return this.ajaxRequest<Enums.ReportRunStatus>(`/reports/queueentries/${entryId}/status`);
    }

    deleteReportQueueEntry(entryId: number) {
        return this.deleteRequest<void>(`/reports/queueentries/${entryId}`);
    }

    deleteReportQueueEntries(entryIds: number[]) {
        const queryParams = entryIds.map(x => $.param({
            entryIds: x
        }));

        return this.deleteRequest<void>(`/reports/queueentries?${queryParams.join("&")}`);
    }

    queueReport(queueReportRequest: Reports.QueueReportRequest) {
        return this.postJson<number>("/reports/queueentries", queueReportRequest);
    }

    updateQueuedReport(entryId: number, queueReportRequest: Reports.QueueReportRequest) {
        return this.putJson<void>(`/reports/queueentries/${entryId}`, queueReportRequest);
    }

    addReportNotifications(request: Reports.AddReportNotificationsRequest) {
        return this.postJson<void>("/reports/queueentries/notifications", request);
    }

    deleteReportNotifications(request: Reports.DeleteReportNotificationsRequest) {
        return this.deleteJson<void>("/reports/queueentries/notifications", request);
    }

    getReportFiles(
        fileIds?: number[],
        customerId?: number, 
        reportId?: number, 
        reportQueueEntryId?: number, 
        dateArguments?: Args.DateArguments,
        includeAuthorDetails?: boolean,
        paging?: Args.PagingOptions,
        sorting?: Args.SortingOptions<Enums.ReportFileSortColumn>
    ) {
        return this.ajaxRequest<Args.Collection<Reports.ReportFile>>("/reports/files", 
        {  
            fileIds: fileIds,
            customerId: customerId,
            reportId: reportId,
            reportQueueEntryId: reportQueueEntryId,
            from: dateArguments ? dateArguments.from : undefined,
            to: dateArguments ? dateArguments.to : undefined,
            includeAuthorDetails: includeAuthorDetails,
            page: paging ? paging.page : undefined,
            pageSize: paging ? paging.pageSize : undefined,
            sortBy: sorting ? sorting.sortColumn : undefined,
            sortOrder: sorting ? sorting.sortOrder : undefined
        });
    }

    getReportFilesLink(fileIds: number[]) {
        const contentType = this.getFileContentTypeAsString(Enums.FileContentType.Zip);
        const queryParams = fileIds.map(x => $.param({
            fileIds: x
        }));
        return `${this.urlRoot}/reports/files/?contentType=${contentType}&${queryParams.join("&")}`;
    }

    getReportFile(fileId: number, includeAuthorDetails: boolean) {
        return this.ajaxRequest<Reports.ReportFile>(`/reports/files/${fileId}`, { includeAuthorDetails });
    }

    getReportFileLink(fileId: number) {
        const contentType = this.getFileContentTypeAsString(Enums.FileContentType.Raw);
        return `${this.urlRoot}/reports/files/${fileId}?contentType=${contentType}`;
    }

    deleteReportFile(fileId: number) {
        return this.deleteRequest<void>(`/reports/files/${fileId}`);
    }

    deleteReportFiles(fileIds: number[]) {
        const queryParams = fileIds.map(x => $.param({
            fileIds: x
        }));
        return this.deleteRequest<void>(`/reports/files?${queryParams.join("&")}`);
    }

    getRtuEventNotifications(rtuId: number, includeAddressBookEntries?: boolean) {
        return this.ajaxRequest<Args.Collection<Notifications.EventNotification>>(`/rtus/${rtuId}/eventnotifications`, {
            includeAddressBookEntries: includeAddressBookEntries
        });
    }

    addRtuEventNotifications(rtuId: number, request: Notifications.AddEventNotificationsRequest) {
        return this.postJson<void>(`/rtus/${rtuId}/eventnotifications`, request);
    }
    
    bulkAddRtuEventNotifications(request: Notifications.BulkAddRtuEventNotificationsRequest) {
        return this.postJson<void>("/rtus/eventnotifications", request);
    }

    updateRtuEventNotifications(rtuId: number, request: Notifications.UpdateEventNotificationsRequest) {
        return this.putJson<void>(`/rtus/${rtuId}/eventnotifications`, request);
    }

    moveRtuEventNotifications(rtuId: number, request: Notifications.MoveEventNotificationsRequest) {
        return this.postJson<void>(`/rtus/${rtuId}/eventnotifications/move`, request);
    }

    copyRtuEventNotifications(rtuId: number, request: Notifications.CopyEventNotificationsRequest) {
        return this.postJson<void>(`/rtus/${rtuId}/eventnotifications/copy`, request);
    }

    copyAllRtuEventNotifications(rtuId: number, request: Notifications.CopyAllNotificationsRequest) {
        return this.postJson<void>(`/rtus/${rtuId}/eventnotifications/copyall`, request);
    }

    deleteRtuEventNotifications(rtuId: number, request: Notifications.DeleteEventNotificationsRequest) {
        return this.deleteJson<void>(`/rtus/${rtuId}/eventnotifications`, request);
    }

    bulkDeleteRtuEventNotifications(request: Notifications.DeleteEventNotificationsRequest) {
        return this.deleteJson<void>("/rtus/eventnotifications", request);
    }
    
    uploadFirmware(rtuFirmwareUpload: Rtus.RtuFirmwareUpload, file: File) {
        return this.postFormData<Rtus.RtuFirmwareUploadSummary>(`/rtus/firmware`,
            IHostWebApi.createFileFormData(rtuFirmwareUpload, "RtuFirmwareUpload", [file], "data"));
    }

    deleteRtuFirmware(request: Rtus.DeleteRtuStoredFirmwareRequest) {
        return this.deleteJson<number>("/rtus/firmware", request);
    }

    uploadConfig(rtuConfigUpload: Rtus.RtuConfigUpload, files: FileList | File[]) {
        return this.postFormData<Rtus.RtuConfigUploadSummary>(`/rtus/config`,
            IHostWebApi.createFileFormData(rtuConfigUpload, "RtuConfigUpload", files, "data"));
    }

    deleteRtuConfig(request: Rtus.DeleteRtuStoredConfigRequest): Promise<number> {
        return this.deleteJson<number>("/rtus/config", request);
    }

    getRtuConfigFile(rtuId: number) {
        return this.ajaxRequest<Rtus.RtuConfig>(`/rtus/${rtuId}/configfile`);
    }

    getUserGroup(userGroupId: number, includePermissions?: boolean, includeUsers?: boolean) {
        return this.ajaxRequest<UserGroup.UserGroup>(`/usergroups/${userGroupId}`, { includePermissions, includeUsers });
    }

    getUserGroups(customerIds?: number[], includePermissions?: boolean, includeUsers?: boolean, assignableOnly?: boolean) {
        return this.ajaxRequest<Args.Collection<UserGroup.UserGroup>>("/usergroups", { customerIds, includePermissions, includeUsers, assignableOnly });
    }

    getOverviews(customerIds?: number[]) {
        return this.ajaxRequest<Args.Collection<Plugins.OverviewPlugin>>("/webplugins/overviews", { customerIds });
    }

    getMimics(enabled?: boolean) {
        return this.ajaxRequest<Args.Collection<Plugins.WebPlugin>>("/webplugins/mimics", { enabled });
    }

    getApp(appId: string) {
        return this.ajaxRequest<Plugins.AppPlugin>(`/webplugins/apps/${appId}`);
    }

    getEventRecordingData(rtuFileId: number, standardiseUnits?: boolean, channelIndexes?: number[], eventRecordingFormat?: Enums.EventRecordingFormat, includeOnlyDigitalChanges?: boolean, triggerTimeOverride?: string) {
        return this.ajaxRequest<EventRecordings.EventRecordingData>(`/eventrecordings/${rtuFileId}/data`, { 
            standardiseUnits, channelIndexes, eventRecordingFormat, includeOnlyDigitalChanges, triggerTimeOverride 
        });
    }

    getEventRecordingDataFileLink(rtuFileId: number, standardiseUnits?: boolean, channelIndexes?: number[], eventRecordingFormat?: Enums.EventRecordingFormat, includeOnlyDigitalChanges?: boolean, triggerTimeOverride?: string) {
        const contentType = this.getFileContentTypeAsString(Enums.FileContentType.Raw);
        const queryParams = $.param({
            standardiseUnits,
            ...channelIndexes ? { channelIndexes } : {},
            eventRecordingFormat,
            includeOnlyDigitalChanges,
            ...triggerTimeOverride ? { triggerTimeOverride } : {}
        });
        return `${this.urlRoot}/eventrecordings/${rtuFileId}/data?contentType=${contentType}&${queryParams}`;
    }

    calculateEventRecordingData(eventRecordingCalculationRequest: EventRecordings.EventRecordingCalculationRequest) {
        return this.postJson<EventRecordings.EventRecordingCalculationData>("/eventrecordings/data/calculate", eventRecordingCalculationRequest);
    }

    getEventRecording(rtuFileId: number) {
        return this.ajaxRequest<EventRecordings.EventRecordingFile>(`/eventrecordings/${rtuFileId}`);
    }

    getEventRecordingZipFileLink(rtuFileId: number) {
        const contentType = this.getFileContentTypeAsString(Enums.FileContentType.Zip);
        return `${this.urlRoot}/eventrecordings/${rtuFileId}?contentType=${contentType}`;
    }

    getEventRecordings(rtuIds: number[], dateArguments: Args.DateArguments, rtuFileIds?: number[], paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<EventRecordings.EventRecordingFile>>(`/eventrecordings`, $.extend({}, { 
            rtuIds: rtuIds,
            fromTime: dateArguments.from,
            toTime: dateArguments.to,
            rtuFileIds: rtuFileIds
        }, this.getPagingOptions(paging)));
    }

    getBmus(
        customerId?: number, 
        topRtuGroupId?: number, 
        bmuIdFilter?: string,
        mappedVirtualRtuNamePattern?: string,
        bmuStateFilter?: Enums.BmuState,
        minTimestamp?: string,
        sortingArguments?: Args.SortingOptions<Enums.BmuField>,
        paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.PagedCollection<Bmus.Bmu>>("/bmus", {
            customerId: customerId,
            topRtuGroupId: topRtuGroupId,
            bmuIdFilter: bmuIdFilter,
            mappedVirtualRtuNamePattern: mappedVirtualRtuNamePattern,
            bmuStateFilter: bmuStateFilter,
            minTimestamp: minTimestamp,
            sortBy: sortingArguments ? sortingArguments.sortColumn : undefined,
            sortOrder: sortingArguments ? sortingArguments.sortOrder : undefined,
            page: paging ? paging.page : undefined,
            pageSize: paging ? paging.pageSize : undefined
        });
    }

    getPQEvents(
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        pqEventTypes?: Enums.PQEventTypes,
        dateArguments?: Args.DateArguments,
        toTimeInclusive?: boolean,
        includeDisabledPoints?: boolean,
        includeParked?: boolean,
        nominalVoltages?: number[],
        sortOrder?: Enums.SortOrder,
        paging?: Args.PagingOptions
    ) {
        return this.ajaxRequest<Args.Collection<PQEvents.PQEvent>>(`/pqevents`, $.extend({}, { 
            customerIds: customerIds,
            rtuGroupIds: rtuGroupIds,
            includeSubgroups: includeSubgroups,
            rtuIds: rtuIds,
            pqEventTypes: pqEventTypes,
            fromTime: dateArguments ? dateArguments.from : undefined,
            toTime: dateArguments ? dateArguments.to : undefined,
            toTimeInclusive: toTimeInclusive,
            includeDisabledPoints: includeDisabledPoints,
            includeParked: includeParked,
            nominalVoltages: nominalVoltages,
            sortOrder: sortOrder,
            page: paging ? paging.page : undefined,
            pageSize: paging ? paging.pageSize : undefined
        }));
    }

    getPQEventTimeSummaries(
        dateArguments: Args.DateArguments,
        bucketWidthSeconds: number,
        searchFromTime?: string,
        searchToTime?: string,
        customerId?: number,
        topRtuGroupId?: number,
        pqEventCountOptions?: Enums.PQEventCountOptions,
        pqEventTypes?: Enums.PQEventTypes,
        includeRtu?: boolean,
        includeRtuGroup?: boolean,
        removeEmptyGroups?: boolean,
        removeEmptyBuckets?: boolean,
        includeDisabledPoints?: boolean
    ) {
        return this.ajaxRequest<Args.Collection<PQEvents.PQEventTimeSummary>>(`/pqevents/timesummary`, $.extend({}, { 
            fromTime: dateArguments.from,
            toTime: dateArguments.to,
            searchFromTime: searchFromTime,
            searchToTime: searchToTime,
            bucketWidthSeconds: bucketWidthSeconds,
            customerId: customerId,
            topRtuGroupId: topRtuGroupId,
            pqEventCountOptions: pqEventCountOptions,
            pqEventTypes: pqEventTypes,
            includeRtu: includeRtu,
            includeRtuGroup: includeRtuGroup,
            removeEmptyGroups: removeEmptyGroups,
            removeEmptyBuckets: removeEmptyBuckets,
            includeDisabledPoints: includeDisabledPoints
        }));
    }

    getPQEventTimeBucket(
        fromTime: string,
        bucketWidthSeconds: number,
        searchFromTime?: string,
        searchToTime?: string,
        rtuId?: number,
        rtuGroupId?: number,
        pqEventCountOptions?: Enums.PQEventCountOptions,
        pqEventTypes?: Enums.PQEventTypes,
        includePQEvents?: boolean,
        includeEventRecordings?: boolean,
        includeDisabledPoints?: boolean,
        pqEventsSortOrder?: Enums.SortOrder,
        pqEventsPaging?: Args.PagingOptions 
    ) {
        return this.ajaxRequest<PQEvents.PQEventTimeBucket>(`/pqevents/timebucket`, $.extend({}, { 
            fromTime: fromTime,
            searchFromTime: searchFromTime,
            searchToTime: searchToTime,
            bucketWidthSeconds: bucketWidthSeconds,
            rtuId: rtuId,
            rtuGroupId: rtuGroupId,
            pqEventCountOptions: pqEventCountOptions,
            pqEventTypes: pqEventTypes,
            includePQEvents: includePQEvents,
            includeEventRecordings: includeEventRecordings,
            includeDisabledPoints: includeDisabledPoints,
            pqEventsSortOrder: pqEventsSortOrder,
            pqEventsPage: pqEventsPaging ? pqEventsPaging.page : undefined,
            pqEventsPageSize: pqEventsPaging ? pqEventsPaging.pageSize : undefined
        }));
    }

    updatePrimaryCustomer(username: string, moveUserRequest: Users.MoveUserRequest) {
        return this.putJson<void>(`/users/${username}/primarycustomer`, moveUserRequest);
    }

    getCustomerNotes(customerId: number, pinned: boolean | null = null, includeDeleted: boolean = false) {
        return this.ajaxRequest<Args.Collection<CustomerNotes.CustomerNote>>(`/customers/${customerId}/notes`, { pinned, includeDeleted });
    }

    getCustomerNote(customerId: number, noteId: number, includeDeleted: boolean = false) {
        return this.ajaxRequest<CustomerNotes.CustomerNote>(`/customers/${customerId}/notes/${noteId}`, { includeDeleted });
    }

    getCustomerNoteRevisions(customerId: number, noteId: number) {
        return this.ajaxRequest<Args.Collection<CustomerNotes.CustomerNoteRevision>>(`/customers/${customerId}/notes/${noteId}/revisions`);
    }

    addCustomerNote(customerId: number, note: Pick<CustomerNotes.CustomerNote, "CustomerId" | "Title" | "Text">) {
        return this.postJson<number>(`/customers/${customerId}/notes`, note);
    }

    editCustomerNote(customerId: number, noteId: number, note: Pick<CustomerNotes.CustomerNote, "CustomerNoteId" | "CustomerId" | "Title" | "Text" | "Revision">) {
        return this.putJson<void>(`/customers/${customerId}/notes/${noteId}`, note);
    }

    deleteCustomerNote(customerId: number, noteId: number) {
        return this.deleteRequest<void>(`/customers/${customerId}/notes/${noteId}`);
    }

    pinCustomerNote(customerId: number, noteId: number, pinCustomerNoteRequest: CustomerNotes.PinCustomerNoteRequest) {
        return this.putJson<void>(`/customers/${customerId}/notes/${noteId}/pin`, pinCustomerNoteRequest);
    }

    getUserAccessToken(username: string, userAccessTokenName: string) {
        return this.ajaxRequest<Users.UserAccessToken>(`/users/${username}/accesstokens/${userAccessTokenName}`);
    }

    getUserAccessTokens(username: string) {
        return this.ajaxRequest<Args.Collection<Users.UserAccessToken>>(`/users/${username}/accesstokens`);
    }

    addUserAccessToken(username: string, addUserAccessTokenRequest: Users.AddUserAccessTokenRequest) {
        return this.postJson<Users.NewUserAccessToken>(`/users/${username}/accesstokens`, addUserAccessTokenRequest);
    }

    deleteUserAccessToken(username: string, userAccessTokenName: string) {
        return this.deleteRequest<void>(`/users/${username}/accesstokens/${userAccessTokenName}`);
    }

    getShapeTemplates(customerIds?: number[]) {
        return this.ajaxRequest<Args.Collection<ShapeTemplates.ShapeTemplate>>("/shapetemplates", { customerIds });
    }

    getShapeTemplate(shapeTemplateId: number) {
        return this.ajaxRequest<ShapeTemplates.ShapeTemplate>(`/shapetemplates/${shapeTemplateId}`);
    }

    renderShapeTemplate(shapeTemplateId: number, renderShapeTemplateRequest?: ShapeTemplates.RenderShapeTemplateRequest) {
        return this.postJson<ShapeTemplates.ShapeData>(`/shapetemplates/${shapeTemplateId}/data`, renderShapeTemplateRequest);
    }

    addShapeTemplate(addShapeTemplateRequest: ShapeTemplates.AddShapeTemplateRequest) {
        return this.postJson<number>("/shapetemplates", addShapeTemplateRequest);
    }

    updateShapeTemplate(shapeTemplateId: number, updateShapeTemplateRequest: ShapeTemplates.UpdateShapeTemplateRequest) {
        return this.putJson<void>(`/shapetemplates/${shapeTemplateId}`, updateShapeTemplateRequest);
    }

    deleteShapeTemplate(shapeTemplateId: number) {
        return this.deleteRequest<void>(`/shapetemplates/${shapeTemplateId}`);
    }

    getNotificationTemplates(args: Notifications.GetNotificationTemplatesArgs) {
        return this.ajaxRequest<Args.Collection<Notifications.NotificationTemplate>>("/notificationtemplates", args);
    }

    getNotificationTemplate(notificationTemplateId: number, basicDetails?: boolean) {
        return this.ajaxRequest<Notifications.NotificationTemplate>(`/notificationtemplates/${notificationTemplateId}`, { basicDetails });
    }

    addNotificationTemplate(addNotificationTemplateRequest: Notifications.AddNotificationTemplateRequest) {
        return this.postJson<number>("/notificationtemplates", addNotificationTemplateRequest);
    }

    updateNotificationTemplate(notificationTemplateId: number, updateNotificationTemplateRequest: Notifications.UpdateNotificationTemplateRequest) {
        return this.putJson<void>(`/notificationtemplates/${notificationTemplateId}`, updateNotificationTemplateRequest);
    }

    deleteNotificationTemplate(notificationTemplateId: number) {
        return this.deleteRequest<void>(`/notificationtemplates/${notificationTemplateId}`);
    }

    deleteNotificationTemplates(notificationTemplateIds: number[]) {
        return this.deleteRequest<void>("/notificationtemplates?" + $.param({ notificationTemplateIds }));
    }

    getMessageApis(messageApiTypes?: Enums.MessageApiType[], enabled?: boolean, isDefault?: boolean, basicDetails?: boolean) {
        return this.ajaxRequest<Args.Collection<Notifications.MessageApi>>("/messageapis", { messageApiTypes, enabled, isDefault, basicDetails });
    }

    getMessageApi(messageApiId: number, basicDetails?: boolean) {
        return this.ajaxRequest<Notifications.MessageApi>(`/messageapis/${messageApiId}`, { basicDetails });
    }

    addMessageApi(addMessageApiRequest: Notifications.AddMessageApiRequest) {
        return this.postJson<number>("/messageapis", addMessageApiRequest);
    }

    updateMessageApi(messageApiId: number, updateMessageApiRequest: Notifications.UpdateMessageApiRequest) {
        return this.putJson<void>(`/messageapis/${messageApiId}`, updateMessageApiRequest);
    }

    deleteMessageApi(messageApiId: number) {
        return this.deleteRequest<void>(`/messageapis/${messageApiId}`);
    }
    
    deleteMessageApis(messageApiIds: number[]) {
        return this.deleteRequest<void>("/messageapis?" + $.param({ messageApiIds }));
    }

    getLoadSurveyStudies(customerId: number, authors?: string[], includeMeasurementPointDetails?: boolean) {
        return this.ajaxRequest<Args.Collection<LoadSurveys.Study>>(`/customers/${customerId}/loadsurveys/studies` + IHostWebApi.createQueryString(void 0, { authors, includeMeasurementPointDetails }));
    }

    getLoadSurveyStudy(customerId: number, studyId: string, includeMeasurementPointDetails?: boolean) {
        return this.ajaxRequest<LoadSurveys.Study>(`/customers/${customerId}/loadsurveys/studies/${studyId}` + IHostWebApi.createQueryString(void 0, { includeMeasurementPointDetails }));
    }

    getLoadSurveyStudyCsvDownloadLink(customerId: number, studyId: string) {
        return `${this.urlRoot}/customers/${customerId}/loadsurveys/studies/${studyId}?format=csv`;
    }

    addLoadSurveyStudy(customerId: number, study: LoadSurveys.AddStudyRequest) {
        return this.postJson<string>(`/customers/${customerId}/loadsurveys/studies`, study);
    }

    updateLoadSurveyStudy(customerId: number, studyId: string, study: LoadSurveys.UpdateStudyRequest, partial?: boolean) {
        return this.putJson<void>(`/customers/${customerId}/loadsurveys/studies/${studyId}` + IHostWebApi.createQueryString(void 0, { partial }), study);
    }

    uploadLoadSurveyStudyCsv(customerId: number, studyId: string, csvFile: File, validationOnly?: boolean): Promise<void> {
        return this.putFormData<void>(`/customers/${customerId}/loadsurveys/studies/${studyId}` + IHostWebApi.createQueryString(void 0, { validationOnly }), IHostWebApi.createFileForm(csvFile));
    }

    deleteLoadSurveyStudy(customerId: number, studyId: string) {
        return this.deleteRequest<void>(`/customers/${customerId}/loadsurveys/studies/${studyId}`);
    }

    getLoadSurveySites(customerId: number, studyId: string, searchText?: string, siteAddressIds?: string[], paging?: Args.PagingOptions) {
        return this.ajaxRequest<Args.Collection<LoadSurveys.Site>>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sites` + IHostWebApi.createQueryString(void 0, $.extend({ searchText, siteAddressIds }, this.getPagingOptions(paging))));
    }

    getLoadSurveySite(customerId: number, studyId: string, siteAddressId: string) {
        return this.ajaxRequest<LoadSurveys.Site>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sites/${siteAddressId}`);
    }

    getLoadSurveySiteAnalogues(customerId: number, studyId: string, siteAddressId: string, analogueNumbers?: number[]) {
        return this.ajaxRequest<Args.Collection<LoadSurveys.Analogue>>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sites/${siteAddressId}/analogues` + IHostWebApi.createQueryString(void 0, { analogueNumbers }));
    }

    getLoadSurveySiteAnalogue(customerId: number, studyId: string, siteAddressId: string, analogueNumber: number) {
        return this.ajaxRequest<LoadSurveys.Analogue>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sites/${siteAddressId}/analogues/${analogueNumber}`);
    }

    getLoadSurveyAnalogueSeries(
        customerId: number,
        studyId: string,
        siteAddressId: string,
        analogueNumber: number,
        multiplier?: number,
        polarityStartTime?: string,
        polarityEndTime?: string,
        endTimeInclusive?: boolean,
        precision?: number
    ) {
        return this.ajaxRequest<LoadSurveys.LoadSurveySeries>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sites/${siteAddressId}/analogues/${analogueNumber}/series` + IHostWebApi.createQueryString(void 0, { multiplier, polarityStartTime, polarityEndTime, endTimeInclusive, precision }));
    }

    getLoadSurveyMeasurementPointFrame(
        customerId: number,
        studyId: string,
        measurementPointId: number,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        includeGeneration?: boolean,
        endTimeInclusive?: boolean,
        precision?: number
    ) {
        return this.ajaxRequest<LoadSurveys.LoadSurveyMeasurementPointFrame>(`/customers/${customerId}/loadsurveys/studies/${studyId}/measurementpoints/${measurementPointId}/frame` + IHostWebApi.createQueryString(void 0, { includeMW, includeMVar, includeMva, applyDeletions, applyWeatherCorrection, includeGeneration, endTimeInclusive, precision }));
    }

    getLoadSurveyMeasurementPointsFrame(
        customerId: number,
        studyId: string,
        measurementPointIds?: number[],
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        includeGeneration?: boolean,
        endTimeInclusive?: boolean,
        precision?: number
    ) {
        return this.ajaxRequest<LoadSurveys.LoadSurveyMeasurementPointsFrame>(`/customers/${customerId}/loadsurveys/studies/${studyId}/measurementpoints/frame` + IHostWebApi.createQueryString(void 0, { measurementPointIds, includeMW, includeMVar, includeMva, applyDeletions, applyWeatherCorrection, includeGeneration, endTimeInclusive, precision }));
    }

    getLoadSurveySiteTopology(customerId: number, studyId: string) {
        return this.ajaxRequest<Args.Collection<LoadSurveys.TopologyDataNode>>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sitetopology`);
    }

    getLoadSurveySiteTopologyFrame(
        customerId: number,
        studyId: string,
        topologyDataNodeId: string,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        includeGeneration?: boolean,
        endTimeInclusive?: boolean,
        precision?: number
    ) {
        return this.ajaxRequest<LoadSurveys.LoadSurveyTopologyDataFrame>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sitetopology/${topologyDataNodeId}/frame` + IHostWebApi.createQueryString(void 0, { includeMW, includeMVar, includeMva, applyDeletions, applyWeatherCorrection, applySwitching, includeGeneration, endTimeInclusive, precision }));
    }

    getLoadSurveySiteTopologyPeaks(
        customerId: number,
        studyId: string,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        endTimeInclusive?: boolean,
        precision?: number,
        maximumDepth?: LoadSurveys.NodeType
    ) {
        return this.ajaxRequest<Args.Collection<LoadSurveys.LoadSurveyTopologyDataFrame>>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sitetopology/frame/peaks` + IHostWebApi.createQueryString(void 0, { includeMW, includeMVar, includeMva, applyDeletions, applyWeatherCorrection, applySwitching, endTimeInclusive, precision, maximumDepth }));
    }

    getLoadSurveySiteTopologyParentChildPeaks(
        customerId: number,
        studyId: string,
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        endTimeInclusive?: boolean,
        precision?: number,
        maximumDepth?: LoadSurveys.NodeType
    ) {
        return this.ajaxRequest<Args.Collection<LoadSurveys.LoadSurveyTopologyDataFrame>>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sitetopology/frame/parentchildpeaks` + IHostWebApi.createQueryString(void 0, { includeMW, includeMVar, includeMva, applyDeletions, applyWeatherCorrection, applySwitching, endTimeInclusive, precision, maximumDepth }));
    }

    getLoadSurveySiteTopologyValues(
        customerId: number,
        studyId: string,
        dateTimes: string[],
        includeMW?: boolean,
        includeMVar?: boolean,
        includeMva?: boolean,
        applyDeletions?: boolean,
        applyWeatherCorrection?: boolean,
        applySwitching?: boolean,
        precision?: number,
        maximumDepth?: LoadSurveys.NodeType
    ) {
        return this.ajaxRequest<Args.Collection<LoadSurveys.LoadSurveyTopologyDataFrame>>(`/customers/${customerId}/loadsurveys/studies/${studyId}/sitetopology/frame/values` + IHostWebApi.createQueryString({ dateTimes }, { includeMW, includeMVar, includeMva, applyDeletions, applyWeatherCorrection, applySwitching, precision, maximumDepth }));
    }

    getPQSummaryStats(
        measurandSet: Enums.PQMeasurandSet,
        startTime: string,
        endTime: string,
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        reducer?: Enums.AggregationMode,
        valueScaling?: Enums.PQValueScaling,
        phases?: Enums.PQPhase,
        flickerMeasurands?: Enums.PQFlickerMeasurand,
        harmonics?: string,
        includeParked?: boolean,
        hasLocation?: boolean
    ) {
        return this.ajaxRequest<Args.Collection<PQData.PQSummaryStats>>("/pqdata/summarystats", { 
            measurandSet: measurandSet,
            startTime: startTime,
            endTime: endTime,
            customerIds: customerIds,
            rtuGroupIds: rtuGroupIds,
            includeSubgroups: includeSubgroups,
            rtuIds: rtuIds,
            reducer: reducer,
            valueScaling: valueScaling,
            phases: phases,
            flickerMeasurands: flickerMeasurands,
            harmonics: harmonics,
            includeParked: includeParked,
            hasLocation: hasLocation
        });
    }

    getPQActivityData(
        activityTypes: Enums.PQActivityTypes,
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        eventTypes?: Enums.PQEventTypes,
        measurandLimitAlarms?: Enums.PQMeasurandLimitAlarms,
        timeRange?: Enums.PQTimeRange,
        includeParked?: boolean,
        nominalVoltages?: number[],
        hasLocation?: boolean
    ) {
        return this.ajaxRequest<Args.Collection<PQData.PQActivity>>("/pqdata/activity", { 
            activityTypes: activityTypes,
            customerIds: customerIds,
            rtuGroupIds: rtuGroupIds,
            includeSubgroups: includeSubgroups,
            rtuIds: rtuIds,
            eventTypes: eventTypes,
            measurandLimitAlarms: measurandLimitAlarms,
            timeRange: timeRange,
            includeParked: includeParked,
            nominalVoltages: nominalVoltages,
            hasLocation: hasLocation
        });
    }

    getPQSummation(
        customerIds?: number[],
        rtuGroupIds?: number[],
        includeSubgroups?: boolean,
        rtuIds?: number[],
        eventTypes?: Enums.PQEventTypes,
        measurandLimitAlarms?: Enums.PQMeasurandLimitAlarms,
        includeParked?: boolean,
        nominalVoltages?: number[]
    ) {
        return this.ajaxRequest<PQData.PQSummation>("/pqdata/summation", { 
            customerIds: customerIds,
            rtuGroupIds: rtuGroupIds,
            includeSubgroups: includeSubgroups,
            rtuIds: rtuIds,
            eventTypes: eventTypes,
            measurandLimitAlarms: measurandLimitAlarms,
            includeParked: includeParked,
            nominalVoltages: nominalVoltages
        });
    }

    cancelPendingOutputOperations(request: Rtus.CancelPendingOutputOperationsRequest) {
        return this.deleteJson<void>("/rtus/outputs/queue", request);
    }

    generateRtuConfig(rtuId: number, request: Rtus.RtuConfigGenerationRequest) {
        return this.postJson<void>(`/rtus/${rtuId}/config/sync`, request);
    }

    generateRtuConfigs(request: Rtus.BulkRtuConfigGenerationRequest) {
        return this.postJson<void>("/rtus/config/sync", request); 
    }

    getEventQueues(includeEventCount?: boolean) {
        return this.ajaxRequest<Args.Collection<EventQueues.EventQueue>>("/eventqueues", { includeEventCount });
    }

    getEventQueue(eventQueueId: number, includeEventCount?: boolean) {
        return this.ajaxRequest<EventQueues.EventQueue>(`/eventqueues/${eventQueueId}`, { includeEventCount });
    }

    getEventQueueStats(eventQueueId: number) {
        return this.ajaxRequest<EventQueues.EventQueueStatistics>(`/eventqueues/${eventQueueId}/stats`);
    }

    getEventQueueFilter(eventQueueId: number) {
        return this.ajaxRequest<Args.Collection<Events.EventLogType>>(`/eventqueues/${eventQueueId}/filter`);
    }

    updateEventQueue(eventQueueId: number, request: EventQueues.UpdateEventQueueRequest) {
        return this.putJson<void>(`/eventqueues/${eventQueueId}`, request);
    }

    getOperationalMeteringTotals() {
        return this.ajaxRequest<OpsMetering.OpsMeteringTotals>("/opsmetering/analytics/totals");
    }

    getIdentityProviders() {
        return this.ajaxRequest<Args.Collection<IdentityProviders.IdentityProvider>>("/identityproviders");
    }

    getIdentityProvider(identityProviderId: number) {
        return this.ajaxRequest<IdentityProviders.IdentityProvider>(`/identityproviders/${identityProviderId}`);
    }

    addIdentityProvider(addIdentityProviderRequest: IdentityProviders.AddIdentityProviderRequest) {
        return this.postJson<number>("/identityproviders", addIdentityProviderRequest);
    }

    updateIdentityProvider(identityProviderId: number, updateIdentityProviderRequest: IdentityProviders.UpdateIdentityProviderRequest) {
        return this.putJson<void>(`/identityproviders/${identityProviderId}`, updateIdentityProviderRequest);
    }

    deleteIdentityProvider(identityProviderId: number) {
        return this.deleteRequest<void>(`/identityproviders/${identityProviderId}`);
    }

    getConsumeSaml2ResponseUrl(identityProviderUuid: string) {
        return `${this.urlRoot}/auth/saml2/consume/${identityProviderUuid}`;
    }
    
    getRtuApplications(rtuId: number) {
        return this.ajaxRequest<Args.Collection<Rtus.RtuApplication>>(`/rtus/${rtuId}/applications`);
    }

    getRtuApplication(rtuId: number, applicationName: string) {
        return this.ajaxRequest<Rtus.RtuApplication>(`/rtus/${rtuId}/applications/${applicationName}`);
    }

    getRtuApplicationImage(rtuId: number, applicationName: string) {
        return this.ajaxRequest<Rtus.RtuApplicationImage>(`/rtus/${rtuId}/applications/${applicationName}/image`);
    }

    getRtuApplicationImageDownloadLink(rtuId: number, applicationName: string) {
        return `${this.urlRoot}/rtus/${rtuId}/applications/${applicationName}/image?contentType=raw`;
    }

    getRtuApplicationConfig(rtuId: number, applicationName: string) {
        return this.ajaxRequest<Rtus.RtuApplicationConfig>(`/rtus/${rtuId}/applications/${applicationName}/config`);
    }

    getRtuApplicationConfigDownloadLink(rtuId: number, applicationName: string) {
        return `${this.urlRoot}/rtus/${rtuId}/applications/${applicationName}/config?contentType=raw`;
    }

    addRtuApplication(
        rtuId: number,
        addRtuApplicationRequest: Rtus.AddRtuApplicationRequest,
        imageFile: File,
        configFile: File,
        progressHandler?: (event: ProgressEvent) => void
    ) {
        const fileParts: Interfaces.MultipartFilePart[] = [
            {
                PartName: "image",
                File: imageFile,
            },
            {
                PartName: "config",
                File: configFile
            }
        ];
        return this.postFormData<void>(
            `/rtus/${rtuId}/applications`,
            IHostWebApi.createNamedFileFormData(addRtuApplicationRequest, "metadata", fileParts),
            void 0,
            progressHandler);
    }

    updateRtuApplicationImage(
        rtuId: number,
        applicationName: string,
        updateRtuApplicationImageRequest: Rtus.UpdateRtuApplicationImageRequest,
        file: File,
        progressHandler?: (event: ProgressEvent) => void
    ) {
        return this.putFormData<void>(
            `/rtus/${rtuId}/applications/${applicationName}/image`,
            IHostWebApi.createFileFormData(updateRtuApplicationImageRequest, "metadata", [file], "image"),
            progressHandler);
    }

    updateRtuApplicationConfig(
        rtuId: number,
        applicationName: string,
        updateRtuApplicationConfigRequest: Rtus.UpdateRtuApplicationConfigRequest,
        file: File,
        progressHandler?: (event: ProgressEvent) => void
    ) {
        return this.putFormData<void>(
            `/rtus/${rtuId}/applications/${applicationName}/config`,
            IHostWebApi.createFileFormData(updateRtuApplicationConfigRequest, "metadata", [file], "config"),
            progressHandler);
    }

    deleteRtuApplication(rtuId: number, applicationName: string) {
        return this.deleteRequest<void>(`/rtus/${rtuId}/applications/${applicationName}`);
    }

    getLVDevices(args: Args.GetLVDevicesArgs)  {
        return this.ajaxRequest<Args.Collection<LVData.LVDevice>>("/lvdata/devices", args);
    }

    getLVDevice(rtuId: number)  {
        return this.ajaxRequest<LVData.LVDevice>(`/lvdata/devices/${rtuId}`);
    }

    getLVMeasurements(rtuId: number, args: Args.GetLVMeasurementsArgs)  {
        return this.ajaxRequest<Args.Collection<LVData.LVMeasurement>>(`/lvdata/devices/${rtuId}/measurements`, args);
    }

    getLVAlarms(rtuId: number, args: Args.GetLVAlarmsArgs) {
        return this.ajaxRequest<Args.Collection<LVData.LVAlarm>>(`/lvdata/devices/${rtuId}/alarms`, args);
    }

    getEventStreamingServices() {
        return this.ajaxRequest<Args.Collection<EventStreamingServices.EventStreamingService>>("/eventstreamingservices");
    }

    getEventStreamingService(eventStreamingServiceId: number) {
        return this.ajaxRequest<EventStreamingServices.EventStreamingService>(`/eventstreamingservices/${eventStreamingServiceId}`);
    }

    addEventStreamingService(request: EventStreamingServices.AddEventStreamingServiceRequest) {
        return this.postJson<void>("/eventstreamingservices", request);
    }

    updateEventStreamingService(request: EventStreamingServices.UpdateEventStreamingServiceRequest) {
        return this.putJson<void>(`/eventstreamingservices/${request.EventStreamingServiceId}`, request);
    }

    deleteEventStreamingServices(request: EventStreamingServices.DeleteEventStreamingServicesRequest) {
        return this.deleteJson<void>("/eventstreamingservices", request);
    }

    deleteEventStreamingService(eventStreamingServiceId: number) {
        return this.deleteRequest<void>(`/eventstreamingservices/${eventStreamingServiceId}`);
    }

    getEventStreamingChannels(eventStreamingServiceIds?: number[]) {
        return this.ajaxRequest<Args.Collection<EventStreamingServices.EventStreamingChannel>>("/eventstreamingchannels", {
            eventStreamingServiceIds : eventStreamingServiceIds
        });
    }

    getEventStreamingChannel(eventStreamingChannelId: number) {
        return this.ajaxRequest<EventStreamingServices.EventStreamingChannel>(`/eventstreamingchannels/${eventStreamingChannelId}`);
    }

    addEventStreamingChannel(request: EventStreamingServices.AddEventStreamingChannelRequest) {
        return this.postJson<void>("/eventstreamingchannels", request);
    }

    updateEventStreamingChannel(request: EventStreamingServices.UpdateEventStreamingChannelRequest) {
        return this.putJson<void>(`/eventstreamingchannels/${request.EventStreamingChannelId}`, request);
    }

    deleteEventStreamingChannels(request: EventStreamingServices.DeleteEventStreamingChannelsRequest) {
        return this.deleteJson<void>("/eventstreamingchannels", request);
    }

    deleteEventStreamingChannel(eventStreamingChannelId: number) {
        return this.deleteRequest<void>(`/eventstreamingchannels/${eventStreamingChannelId}`);
    }

    getEventStreamingProducers(eventStreamingChannelIds?: number[]) {
        return this.ajaxRequest<Args.Collection<EventStreamingServices.EventStreamingProducer>>("/eventstreamingproducers", {
                eventStreamingChannelIds : eventStreamingChannelIds
            });
    }

    getEventStreamingProducer(eventStreamingProducerId: number) {
        return this.ajaxRequest<EventStreamingServices.EventStreamingProducer>(`/eventstreamingproducers/${eventStreamingProducerId}`);
    }

    addEventStreamingProducer(request: EventStreamingServices.AddEventStreamingProducerRequest) {
        return this.postJson<void>("/eventstreamingproducers", request);
    }

    updateEventStreamingProducer(request: EventStreamingServices.UpdateEventStreamingProducerRequest) {
        return this.putJson<void>(`/eventstreamingproducers/${request.EventStreamingProducerId}`, request);
    }    
    
    deleteEventStreamingProducers(request: EventStreamingServices.DeleteEventStreamingProducersRequest) {
        return this.deleteJson<void>("/eventstreamingproducers", request);
    }

    deleteEventStreamingProducer(eventStreamingProducerId: number) {
        return this.deleteRequest<void>(`/eventstreamingproducers/${eventStreamingProducerId}`);
    }
}
