export class CustomerRegistryKeys {
    /**
     * Ack all events on opening RTU Overview page.
     */
    public static readonly AckAll = "ack_all";

    /**
     * Auto ack events from Points set to Status.
     */
    public static readonly AckStatusPoints = "ack_status_points";

    public static readonly AlarmConditions = "alarm_conditions";

    /**
     * Archiving compression period in days.
     */
    public static readonly ArchiveCompressionPeriod = "archive_compress_period";

    /**
     * Limits the number of days for displaying archived events.
     */
    public static readonly ArchiveEventLimit = "archive_event_limit";

    /**
     * Archiving period in hours.
     */
    public static readonly ArchivePeriod = "archive_period";

    public static readonly CoordSystemDatum = "coord_system_datum";

    /**
     * Gets the coordinate system display format.
     * Dependend on CoordSystemType will be one of LatLongPosition or BngPosition
     */
    public static readonly CoordSystemDisplayFormat = "coord_system_display_format";

    /**
     * Gets the coordinate system type.
     * See CoordinateSystem enum
     */
    public static readonly CoordSystemType = "coord_system_type";

    /**
     * date/time format
     */
    public static readonly DateFormat = "date_format";

    /**
     * date/time format : millisecond format
     */
    public static readonly DateFormatMs = "date_format_ms";

    /**
     * Display RTU Point UUID on the Point Settings pages.
     */
    public static readonly DisplayRtuPointUuid = "display_rtu_point_uuid";

    /**
     * display whole range for analogues
     */
    public static readonly DisplayWholeRange = "display_whole_range";

    /**
     * sound alarm if unack events exist
     */
    public static readonly EventAlarms = "event_alarms";

    /**
     * use event colours on System Overview screen
     */
    public static readonly EventSysColours = "event_sys_colours";

    public static readonly EventUnackColour = "evt_unack_color";

    /**
     * Event view selected event type (comms/inputs/all)
     */
    public static readonly EventViewType = "evt_log_cond";

    /**
     * Whether the event filter is enabled on the Event Overview page
     */
    public static readonly EventFilterStatus = "evt_log_filter_sta";

    /**
     * hides disabled inputs on RTU Overview page
     */
    public static readonly HideDisabledInputs = "hide_disabled_inputs";

    /**
     * Gets the interactive trends max date range in days.
     */
    public static readonly InteractiveTrendMaxDays = "interactive_trend_max_days";

    public static readonly Locale = "locale";

    /**
     * Gets the minimum logic interval
     */
    public static readonly MinimumLogicInterval = "min_logic_interval";

    public static readonly Name2Sections = "name2_sections";
    public static readonly Name2SectionsSortByIoRef = "name2_sections_sort_by_io_ref";

    /**
     * Energy in Pre-Fault Peck % change threshold.
     */
    public static readonly EnergyInPreFaultPeckPercentageChangeThreshold = "energy_in_prefault_peck_percentage_change_threshold";

    /**
     * Maxiumum retention period for RTU summary stat period of an individual day.
     */
    public static readonly RtuSummaryStatPeriodMaxRetentionIndividualDay = "rtu_summary_stat_period_max_retention_individual_day";

    /**
     * Maxiumum retention period for RTU summary stat period of an individual week.
     */
    public static readonly RtuSummaryStatPeriodMaxRetentionIndividualWeek = "rtu_summary_stat_period_max_retention_individual_week";

    /**
     * Maxiumum retention period for RTU summary stat period of an individual month.
     */
    public static readonly RtuSummaryStatPeriodMaxRetentionIndividualMonth = "rtu_summary_stat_period_max_retention_individual_month";

    public static readonly WrapPointNames = "wrap_point_names";

    /**
     * User-definable field names
     */
    public static readonly SiteName = "site_name";

    public static readonly PointName2 = "point_name_2";

    public static readonly PointUuid = "point_uuid";

    public static readonly PointGroupName2 = "point_group_name_2";

    public static readonly UserData1 = "user_data_1";

    public static readonly UserData1Regex = "user_data_1_regex";

    public static readonly UserData2 = "user_data_2";

    public static readonly UserData2Regex = "user_data_2_regex";

    public static readonly UserData3 = "user_data_3";

    public static readonly UserData3Regex = "user_data_3_regex";

    public static readonly UserData4 = "user_data_4";

    public static readonly UserData4Regex = "user_data_4_regex";

    public static readonly UserData5 = "user_data_5";

    public static readonly UserData5Regex = "user_data_5_regex";

    public static readonly UserData6 = "user_data_6";

    public static readonly UserData6Regex = "user_data_6_regex";

    /**
     * How often reminder emails are sent for user access tokens, in months.
     */
    public static readonly AccessTokenReminderPeriod = "access_token_reminder_period";
    /**
     * How often expiry reminder emails are sent for user access tokens, in days.
     */
    public static readonly AccessTokenExpiryReminderPeriod = "access_token_expiry_reminder_period";
}

export class UserRegistryKeys {
    /**
     * The field to sort by on the Address Book table.
     */
    public static readonly AddressBookSortField = "addr_book_sort_field";

    /**
     * The sort order for the sorted field on the Address Book table.
     */
    public static readonly AddressBookSortOrder = "addr_book_sort_dir";

    /**
     * Gets whether auto refresh is enabled.
     */
    public static readonly AutoRefresh = "auto_refresh";

    /**
     * Max number of commands to be displayed in Recent Output History panel on Unit Overview.
     */
    public static readonly CommandHistoryLimit = "command_history_limit";

    /**
     * whether dark theme is applied
     */
    public static readonly DarkMode = "dark_mode";

    /**
     * default app for desktop and mobile (Main Interface)
     */
    public static readonly DefaultAppDesktop = "default_app_desktop";
    public static readonly DefaultAppMobile = "default_app_mobile";

    /**
     * Get/Set the current view in iHost
     */
    public static readonly DefaultView = "evt_view";

    /**
     * disable confirm boxes
     */
    public static readonly DisplayConfirmationDialogue = "dis_conf";

    /**
     * right pad analogue values up to the display precision on the Unit Overview
     */
    public static readonly FullAnaloguePrecision = "full_analogue_precision";

    public static readonly EventsAlarmSound = "events_alarm_sound";

    /**
     * Default number of hours to show on the Event Overview page
     */
    public static readonly EventDefaultHours = "evt_hours";

    /**
     * Maximum number of events to display on the Event Overview page
     */
    public static readonly EventLimit = "evt_limit";

    /**
     * Maximum number of events to display per page on the Event Overview page
     */
    public static readonly EventPageSize = "evt_page_size";

    /**
     * Columns (in display order) to display on the Event Overview page
     */
    public static readonly EventColumns = "evt_col";

    /**
     * Whether to highlight event rows on hover on the Event Overview page
     */
    public static readonly EventRowHighlight = "evt_row_highlight";

    /**
     * Event types to filter by on the Event Overview page
     */
    public static readonly EventTypesFilter = "event_log_filter";

    /**
     * Whether the event filter is enabled on the Event Overview page
     */
    public static readonly EventFilterStatus = "event_log_filter_sta";

    /**
     * Event view selected event type (comms/inputs/all)
     */
    public static readonly EventViewType = "evt_log_cond";


    /**
     * Event view selected event status type ID.
     */
    public static readonly EventViewStatus = "evt_log_type_view";

    /**
     * Gets the interactive trends analogue input filter, default is None.
     */
    public static readonly InteractiveTrendAiFilter = "interactive_trend_ai_filter";

    /**
     * Gets the interactive trends colour, default is Red.
     */
    public static readonly InteractiveTrendColour = "interactive_trend_colour";

    /**
     * Trend template to use when interactive trends is set as the default start screen.
     */
    public static readonly InteractiveTrendDefaultTemplate = "interactive_trend_template";

    /**
     * Gets the interactive trends highlight mode, default is NearestX.
     */
    public static readonly InteractiveTrendHighlightMode = "interactive_trend_highlight";
    
    /**
     * Gets the interactive trends bad quality, default is Exclude.
     */
    public static readonly InteractiveTrendIncludeBadQuality = "interactive_trend_bad_quality";

    /**
     * Gets the interactive trends indicator, default is Circle
     */
    public static readonly InteractiveTrendIndicator = "interactive_trend_indicator";

    /**
     * Gets the interactive trends legend title option, default is Include Unit
     */
    public static readonly InteractiveTrendLegendIncludeUnitInTitle = "interactive_trend_legend_title";

    /**
     * Gets the interactive trends legend stats option, default is Hide
     */
    public static readonly InteractiveTrendLegendShowStats = "interactive_trend_legend_stats";

    /**
     * Gets the interactive trends legend values option, default is Hide
     */
    public static readonly InteractiveTrendLegendShowValues = "interactive_trend_legend_vals";

    /**
     * Gets whether interactive trends should open in a pop-up window, default is No.
     */
    public static readonly InteractiveTrendOpenInPopupWindow = "interactive_trend_popup_window";

    /**
     * Gets the interactive trends start / end labels option, default is Hide.
     */
    public static readonly InteractiveTrendShowStartEndLabels = "interactive_trend_time_labels";

    /**
     * Gets the interactive trends tooltip option, default is Show.
     */
    public static readonly InteractiveTrendShowTooltip = "interactive_trend_tooltip";

    /**
     * Gets the interactive trends AI smoothing option, default is Step.
     */
    public static readonly InteractiveTrendSmoothing = "interactive_trend_smoothing";

    /**
     * hides analogues display min and max values on RTU Overview page
     */
    public static readonly HideAnalogueDisplayMinmax = "hide_analogue_display_minmax";

    /**
     *  Columns to be displayed on Maintenance View.
     */
    public static readonly MaintenanceViewColumns = "maintenance_view_columns";

    /**
     * Max number of units to be displayed on Maintenance View.
     */
    public static readonly MaintenanceViewLimit = "maintenance_view_limit";

    /**
     * Gets the map culture.
     */
    public static readonly MapCulture = "map_culture";

    /**
     * Gets the map filters.
     */
    public static readonly MapFilters = "map_filters";

    /**
     * Gets the map imagery set.
     */
    public static readonly MapImagerySet = "map_imagery_set";

    /**
     * Gets the map label position.
     */
    public static readonly MapLabelPosition = "map_label_position";

    /**
     * Gets the map background layer.
     */
    public static readonly MapBackgroundLayer = "map_background_layer";

    /**
     * Gets the map feature layers.
     */
    public static readonly MapFeatureLayers = "map_feature_layers";

    /**
     * Gets whether show map Point icon labels is enabled.
     */
    public static readonly MapShowPointIconLabels = "map_show_point_icon_labels";

    /**
     * Gets whether show map RTU icon labels is enabled.
     */
    public static readonly MapShowRtuIconLabels = "map_show_rtu_icon_labels";

    /**
     * Gets the type of Map displayed by default.
     */
    public static readonly MapViewType = "map_view_type";

    /**
     * displays assigned Mimic instead of RTU Overview page
     */
    public static readonly MimicDefault = "mimic_default";

    /**
     * displays assigned Mimic in new window rather than current frame
     */
    public static readonly MimicNewWindow = "mimic_new_window";

    /**
     * Event limit for the Network Faults Timeline Browser.
     */
    public static readonly NetworkFaultsTimelineBrowserEventLimit = "network_faults_timeline_browser_event_limit";

    /**
     * displays poll page in main window
     */
    public static readonly PollMainWindow = "poll_main_window";

    /**
     * PQ Event limit for the PQ Dashboard.
     */
    public static readonly PQDashboardEventLimit = "pq_dashboard_event_limit";

    /**
     * PQ Event limit for the PQ Events Timeline Browser.
     */
    public static readonly PQEventsTimelineBrowserEventLimit = "pq_events_timeline_browser_event_limit";

    /**
     * PQ Event Types filter for the PQ Map.
     */
    public static readonly PQMapEventTypes = "pq_map_event_types";

    /**
     * PQ Measurand Alarms filter for the PQ Map.
     */
    public static readonly PQMapMeasurandAlarms = "pq_map_measurand_alarms";

    /**
     * Nominal voltages filter for the PQ Map.
     */
    public static readonly PQMapNominalVoltages = "pq_map_nominal_voltages";

    /**
     * Time range for the PQ Map.
     */
    public static readonly PQMapTimeRange = "pq_map_time_range";

    /**
     * View type for the PQ Map e.g. viewing PQ Events.
     */
    public static readonly PQMapViewType = "pq_map_view_type";

    /**
     * Gets the user refresh interval.
     * The Value is in seconds
     */
    public static readonly RefreshTime = "refresh";

    public static readonly RtuAlarmSound = "rtu_alarm_sound";

    /**
     * Gets the RTU Group Trees that should be expanded.
     * The Value is an array of RTU Group IDs
     */
    public static readonly RtuGroupTree = "tree_expand";    

    /**
     * Gets the SLD culture.
     */
    public static readonly SldCulture = "sld_culture";

    /**
     * Default network to display when using the Single Line Diagrams page as the start screen.
     */
    public static readonly SldDefaultNetwork = "sld_network";

    /**
     * Gets the SLD filters.
     */
    public static readonly SldFilters = "sld_filters";

    /**
     * Gets the SLD imagery set.
     */
    public static readonly SldImagerySet = "sld_imagery_set";

    /**
     * Gets the single line diagram label position.
     */
    public static readonly SldLabelPosition = "sld_label_position";

    /**
     * Gets whether show SLD icon labels is enabled.
     */
    public static readonly SldShowIconLabels = "sld_show_icon_labels";

    /**
     * Gets whether show SLD mouse position is enabled.
     */
    public static readonly SldShowMousePosition = "sld_show_mouse_position";

    /**
     * Gets whether show SLD zoom level is enabled.
     */
    public static readonly SldShowZoomLevel = "sld_show_zoom_level";

    /**
     * Gets whether show SLD groups is enabled.
     */
    public static readonly SldShowGroups = "sld_show_groups";

    /**
     * Gets whether show SLD annotations is enabled.
     */
    public static readonly SldShowAnnotations = "sld_show_annotations";

    /**
     * Gets whether show decluttering nodes is enabled.
     */
    public static readonly SldDeclutterNodes = "sld_declutter_nodes";

    /**
     * Gets the opacity of the SLD background, in percentage.
     */
    public static readonly SldBackgroundOpacity = "sld_background_opacity";

    public static readonly SystemAlarmSound = "system_alarm_sound";

    /**
     * Max number of units to be displayed on System Overview.
     */
    public static readonly SystemOverviewLimit = "system_overview_limit";

    /**
     *  Columns to be displayed on System Overview.
     */
    public static readonly SystemOverviewColumns = "ov_col";

    /**
     *  Filter on the System Overview.
     */
    public static readonly SystemOverviewFilter = "sys_ov_view";

    /**
     *  Field used for sorting on the System Overview.
     */
    public static readonly SystemOverviewSortField = "ov_sort_field";

    /**
     *  Sort order used on the System Overview.
     */
    public static readonly SystemOverviewSortOrder = "ov_sort_dir";

    /**
     * Gets the minimum number of values required before applying compression for trends, default is 100000
     */
    public static readonly TrendCompressionMinCount = "trend_compression_min_count";

    /**
     * Whether to show login message on login.
     */
    public static readonly LoginMessage = "login_message";

    /**
     * Views for the DefaultView setting
     */
    public static get Views() {
        return {
            Default: "normal",
            EventView: "events",
            Maintenance: "maintenance",
            Map: "map",
            PluginOverview: "plugin_overview",
            SingleLineDiagrams: "diagrams",
            SystemOverview: "normal"
        };
    }
}
