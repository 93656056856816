export default class IHostWebPermissions {
    public static get AccessWebService() { return "ACCESS_WS"; }
    public static get AckAllEvents() { return "ACK_ALL_EVENTS"; }
    public static get AckEvents() { return "ACK_EVENTS"; }
    public static get AckSystemLogEvents() { return "ACK_SYSTEM_LOG_EVENTS"; }
    public static get BulkEditRtuSettings() { return "BULK_EDIT_RTU_SETTINGS"; }
    public static get CallInRapidInterval() { return "CALL_IN_RAPID_INTERVAL"; }
    public static get CallNow() { return "CALL_NOW"; }
    public static get ControlOutputs() { return "CONTROL_OUTPUTS"; }
    public static get CreateCustomer() { return "CREATE_CUSTOMER"; }
    public static get CreateIdentityProvider() { return "CREATE_IDENTITY_PROVIDER"; }
    public static get CreateLoadSurveyStudies() { return "CREATE_LOAD_SURVEY_STUDIES"; }
    public static get CreateMessageApi() { return "CREATE_MESSAGE_API"; }
    public static get CreateRtu() { return "CREATE_RTU"; }
    public static get CreateRtuEvents() { return "CREATE_RTU_EVENTS"; }
    public static get CreateRtuFromBaseTemplate() { return "CREATE_RTU_FROM_BASE_TEMPLATE"; }
    public static get CreateRtuTemplates() { return "CREATE_RTU_TEMPLATES"; }
    public static get CreateScadaChannel() { return "CREATE_SCADA_CHANNEL"; }
    public static get CreateSystemBackups() { return "CREATE_SYSTEM_BACKUPS"; }
    public static get CreateUser() { return "CREATE_USER"; }
    public static get CreateUserAccessToken() { return "CREATE_USER_ACCESS_TOKEN"; }
    public static get CreateUserGroup() { return "CREATE_USER_GROUP"; }
    public static get CreateVirtualRtu() { return "CREATE_VIRTUAL_RTU"; }
    public static get DeleteCustomer() { return "DELETE_CUSTOMER"; }
    public static get DeleteCustomerFiles() { return "DELETE_CUSTOMER_FILES"; }
    public static get DeleteCustomerNotes() { return "DELETE_CUSTOMER_NOTES"; }
    public static get DeleteCustomerSchedules() { return "DELETE_CUSTOMER_SCHEDULES"; }
    public static get DeleteEvents() { return "DELETE_EVENTS"; }
    public static get DeleteIconSets() { return "DELETE_ICON_SETS"; }
    public static get DeleteIdentityProvider() { return "DELETE_IDENTITY_PROVIDER"; }
    public static get DeleteLoadSurveyStudies() { return "DELETE_LOAD_SURVEY_STUDIES"; }
    public static get DeleteMessageApi() { return "DELETE_MESSAGE_API"; }
    public static get DeleteNetworks() { return "DELETE_NETWORKS"; }
    public static get DeleteRtu() { return "DELETE_RTU"; }
    public static get DeleteRtuApplications() { return "DELETE_RTU_APPLICATIONS"; }
    public static get DeleteRtuBaseTemplates() { return "DELETE_RTU_BASE_TEMPLATES"; }
    public static get DeleteRtuCertificates() { return "DELETE_RTU_CERTIFICATES"; }
    public static get DeleteRtuFiles() { return "DELETE_RTU_FILES"; }
    public static get DeleteRtuNotes() { return "DELETE_RTU_NOTES"; }
    public static get DeleteRtuTemplates() { return "DELETE_RTU_TEMPLATES"; }
    public static get DeleteScadaChannel() { return "DELETE_SCADA_CHANNEL"; }
    public static get DeleteShapeTemplates() { return "DELETE_SHAPE_TEMPLATES"; }
    public static get DeleteSystemBackups() { return "DELETE_SYSTEM_BACKUPS"; }
    public static get DeleteSystemCertificates() { return "DELETE_SYSTEM_CERTIFICATES"; }
    public static get DeleteSystemLogEvents() { return "DELETE_SYSTEM_LOG_EVENTS"; }
    public static get DeleteTrends() { return "DELETE_TRENDS"; }
    public static get DeleteUser() { return "DELETE_USER"; }
    public static get DeleteUserAccessToken() { return "DELETE_USER_ACCESS_TOKEN"; }
    public static get DeleteUserGroup() { return "DELETE_USER_GROUP"; }
    public static get DeleteVirtualRtu() { return "DELETE_VIRTUAL_RTU"; }
    public static get Developer() { return "DEVELOPER"; }
    public static get DownloadCustomerFiles() { return "DOWNLOAD_CUSTOMER_FILES"; }
    public static get DownloadSystemBackups() { return "DOWNLOAD_SYSTEM_BACKUPS"; }
    public static get EditAddressBook() { return "EDIT_ADDRESS_BOOK"; }
    public static get EditAggressiveCallIn() { return "EDIT_AGGRESSIVE_CALL_IN"; }
    public static get EditChildCustomer() { return "EDIT_CHILD_CUSTOMER"; }
    public static get EditCorrespondent() { return "EDIT_CORRESPONDENT"; }
    public static get EditCsmConfig() { return "EDIT_CSM_CONFIG"; }
    public static get EditCustomer() { return "EDIT_CUSTOMER"; }
    public static get EditCustomerArchiving() { return "EDIT_CUSTOMER_ARCHIVING"; }
    public static get EditCustomerArchivingCompression() { return "EDIT_CUSTOMER_ARCHIVING_COMPRESSION"; }
    public static get EditCustomerAssignedIdentityProvider() { return "EDIT_CUSTOMER_ASSIGNED_IDENTITY_PROVIDER"; }
    public static get EditCustomerAssignedMessageApi() { return "EDIT_CUSTOMER_ASSIGNED_MESSAGE_API"; }
    public static get EditCustomerFiles() { return "EDIT_CUSTOMER_FILES"; }
    public static get EditCustomerFileFolders() { return "EDIT_CUSTOMER_FILE_FOLDERS"; }
    public static get EditCustomerLimits() { return "EDIT_CUSTOMER_LIMITS"; }
    public static get EditCustomerLogo() { return "EDIT_CUSTOMER_LOGO"; }
    public static get EditCustomerNotes() { return "EDIT_CUSTOMER_NOTES"; }
    public static get EditCustomerNotificationFormat() { return "EDIT_CUSTOMER_NOTIFICATION_FORMAT"; }
    public static get EditCustomerPasswordSettings() { return "EDIT_CUSTOMER_PASSWORD_SETTINGS"; }
    public static get EditCustomerSchedules() { return "EDIT_CUSTOMER_SCHEDULES"; }
    public static get EditCustomerUserDataLabels() { return "EDIT_CUSTOMER_USER_DATA_LABELS"; }
    public static get EditEventComments() { return "EDIT_EVENT_COMMENTS"; }
    public static get EditEventQueues() { return "EDIT_EVENT_QUEUES"; }    
    public static get EditEventStreamingServiceConfig() { return "EDIT_ESS_CONFIG"; }
    public static get EditIconSets() { return "EDIT_ICON_SETS"; }
    public static get EditIdentityProvider() { return "EDIT_IDENTITY_PROVIDER"; }
    public static get EditLoadSurveyStudies() { return "EDIT_LOAD_SURVEY_STUDIES"; }
    public static get EditMapSources() { return "EDIT_MAP_SOURCES"; }
    public static get EditMessageApi() { return "EDIT_MESSAGE_API"; }
    public static get EditNetworkFaultLocation() { return "EDIT_NETWORK_FAULT_LOCATION"; }
    public static get EditNetworkFaultNotifications() { return "EDIT_NETWORK_FAULT_NOTIFICATIONS"; }
    public static get EditNetworks() { return "EDIT_NETWORKS"; }
    public static get EditNetworkAnnotations() { return "EDIT_NETWORK_ANNOTATIONS"; }
    public static get EditNextCallInMethod() { return "EDIT_NEXT_CALL_IN_METHOD"; }
    public static get EditPermissionsAll() { return "EDIT_PERMISSIONS_ALL"; }
    public static get EditPermissionsOwn() { return "EDIT_PERMISSIONS_OWN"; }
    public static get EditPlugins() { return "EDIT_PLUGINS"; }
    public static get EditPriorities() { return "EDIT_PRIORITIES"; }
    public static get EditReports() { return "EDIT_REPORTS"; }
    public static get EditRtuAiHysteresis() { return "EDIT_RTU_AI_HYSTERESIS"; }
    public static get EditRtuAiScale() { return "EDIT_RTU_AI_SCALE"; }
    public static get EditRtuAiSettings() { return "EDIT_RTU_AI_SETTINGS"; }
    public static get EditRtuAoSettings() { return "EDIT_RTU_AO_SETTINGS"; }
    public static get EditRtuApiPassword() { return "EDIT_RTU_API_PASSWORD"; }
    public static get EditRtuApplications() { return "EDIT_RTU_APPLICATIONS"; }
    public static get EditRtuArchiving() { return "EDIT_RTU_ARCHIVING"; }
    public static get EditRtuAssignedCustomer() { return "EDIT_RTU_ASSIGNED_CUSTOMER"; }
    public static get EditRtuAssignedCsm() { return "EDIT_RTU_ASSIGNED_CSM"; }
    public static get EditRtuAssignedFirmware() { return "EDIT_RTU_ASSIGNED_FIRMWARE"; }
    public static get EditRtuAssignedRtuGroup() { return "EDIT_RTU_ASSIGNED_RTU_GROUP"; }
    public static get EditRtuAutoPoll() { return "EDIT_RTU_AUTO_POLL"; }
    public static get EditRtuBaseTemplates() { return "EDIT_RTU_BASE_TEMPLATES"; }
    public static get EditRtuBiSettings() { return "EDIT_RTU_BI_SETTINGS"; }
    public static get EditRtuBoSettings() { return "EDIT_RTU_BO_SETTINGS"; }
    public static get EditRtuCallLimits() { return "EDIT_RTU_CALL_LIMITS"; }
    public static get EditRtuCertificates() { return "EDIT_RTU_CERTIFICATES"; }
    public static get EditRtuCiSettings() { return "EDIT_RTU_CI_SETTINGS"; }
    public static get EditRtuCommsSettings() { return "EDIT_RTU_COMMS_SETTINGS"; }
    public static get EditRtuConfigQ() { return "EDIT_RTU_CONFIG_Q"; }
    public static get EditRtuConfigSyncFlags() { return "EDIT_RTU_CONFIG_SYNC_FLAGS"; }
    public static get EditRtuDiSettings() { return "EDIT_RTU_DI_SETTINGS"; }
    public static get EditRtuDnp() { return "EDIT_RTU_DNP"; }
    public static get EditRtuDnpAdvanced() { return "EDIT_RTU_DNP_ADVANCED"; }
    public static get EditRtuDnpHostAddress() { return "EDIT_RTU_DNP_HOST_ADDRESS"; }
    public static get EditRtuDnpLogging() { return "EDIT_RTU_DNP_LOGGING"; }
    public static get EditRtuDtvi() { return "EDIT_RTU_DTVI"; }
    public static get EditRtuEventPriorities() { return "EDIT_RTU_EVENT_PRIORITIES"; }
    public static get EditRtuFileFolders() { return "EDIT_RTU_FILE_FOLDERS"; }
    public static get EditRtuFileLocations() { return "EDIT_RTU_FILE_LOCATIONS"; }
    public static get EditRtuFileRetentionPeriod() { return "EDIT_RTU_FILE_RETENTION_PERIOD"; }
    public static get EditRtuGroups() { return "EDIT_RTU_GROUPS"; }
    public static get EditRtuIec104() { return "EDIT_RTU_IEC104"; }
    public static get EditRtuLatePeriod() { return "EDIT_RTU_LATE_PERIOD"; }
    public static get EditRtuLogic() { return "EDIT_RTU_LOGIC"; }
    public static get EditRtuMimics() { return "EDIT_RTU_MIMICS"; }
    public static get EditRtuModbus() { return "EDIT_RTU_MODBUS"; }
    public static get EditRtuMqtt() { return "EDIT_RTU_MQTT"; }
    public static get EditRtuMqttPassword() { return "EDIT_RTU_MQTT_PASSWORD"; }
    public static get EditRtuNotes() { return "EDIT_RTU_NOTES"; }
    public static get EditRtuNotifications() { return "EDIT_RTU_NOTIFICATIONS"; }
    public static get EditRtuNotificationsCustom() { return "EDIT_RTU_NOTIFICATIONS_CUSTOM"; }
    public static get EditRtuNotificationThrottling() { return "EDIT_RTU_NOTIFICATION_THROTTLING"; }
    public static get EditRtuOnline() { return "EDIT_RTU_ONLINE"; }
    public static get EditRtuParked() { return "EDIT_RTU_PARKED"; }
    public static get EditRtuPointGroups() { return "EDIT_RTU_POINT_GROUPS"; }
    public static get EditRtuPointMeasurements() { return "EDIT_RTU_POINT_MEASUREMENTS"; }
    public static get EditRtuPointName() { return "EDIT_RTU_POINT_NAME"; }
    public static get EditRtuPointName2() { return "EDIT_RTU_POINT_NAME2"; }
    public static get EditRtuRemoteAccess() { return "EDIT_RTU_REMOTE_ACCESS"; }
    public static get EditRtuSel() { return "EDIT_RTU_SEL"; }
    public static get EditRtuSerialNumber() { return "EDIT_RTU_SERIAL_NUMBER"; }
    public static get EditRtuSetup() { return "EDIT_RTU_SETUP"; }
    public static get EditRtuSiSettings() { return "EDIT_RTU_SI_SETTINGS"; }
    public static get EditRtuSiValue() { return "EDIT_RTU_SI_VALUE"; }
    public static get EditRtuSmsEnabled() { return "EDIT_RTU_SMS_ENABLED"; }
    public static get EditRtuSmsLimits() { return "EDIT_RTU_SMS_LIMITS"; }
    public static get EditRtuSummaryStatConfig() { return "EDIT_RTU_SUMMARY_STAT_CONFIG"; }
    public static get EditRtuTemplateType() { return "EDIT_RTU_TEMPLATE_TYPE"; }
    public static get EditRtuTemplates() { return "EDIT_RTU_TEMPLATES"; }
    public static get EditRtuTemplateSync() { return "EDIT_RTU_TEMPLATE_SYNC"; }
    public static get EditRtuUserData() { return "EDIT_RTU_USER_DATA"; }
    public static get EditScadaChannel() { return "EDIT_SCADA_CHANNEL"; }
    public static get EditScadaConfig() { return "EDIT_SCADA_CONFIG"; }
    public static get EditServerCertificates() { return "EDIT_SERVER_CERTIFICATES"; }
    public static get EditShapeTemplates() { return "EDIT_SHAPE_TEMPLATES"; }
    public static get EditSystemCertificates() { return "EDIT_SYSTEM_CERTIFICATES"; }
    public static get EditSystemLogNotifications() { return "EDIT_SYSTEM_LOG_NOTIFICATIONS"; }
    public static get EditTmConfig() { return "EDIT_TM_CONFIG"; }
    public static get EditTrends() { return "EDIT_TRENDS"; }
    public static get EditUser() { return "EDIT_USER"; }
    public static get EditUserAccess() { return "EDIT_USER_ACCESS"; }
    public static get EditUserActiveApp() { return "EDIT_USER_ACTIVE_APP"; }
    public static get EditUserDefaultApp() { return "EDIT_USER_DEFAULT_APP"; }
    public static get EditUserEventPriority() { return "EDIT_USER_EVENT_PRIORITY"; }
    public static get EditUserGroup() { return "EDIT_USER_GROUP"; }
    public static get EditUserGroupMfaPolicy() { return "EDIT_USER_GROUP_MFA_POLICY"; }
    public static get EditUserMapSettings() { return "EDIT_USER_MAP_SETTINGS"; }
    public static get EditUserMfa() { return "EDIT_USER_MFA"; }
    public static get EditUserMfaOwn() { return "EDIT_USER_MFA_OWN"; }
    public static get EditUserOwn() { return "EDIT_USER_OWN"; }
    public static get EditUserPassword() { return "EDIT_USER_PASSWORD"; }
    public static get EditUserPasswordExpiry() { return "EDIT_USER_PASSWORD_EXPIRY"; }
    public static get EditUserPasswordOwn() { return "EDIT_USER_PASSWORD_OWN"; }
    public static get EditUserTopRtuGroup() { return "EDIT_USER_TOP_RTU_GROUP"; }
    public static get EditVirtualRtu() { return "EDIT_VIRTUAL_RTU"; }
    public static get ExportRtu() { return "EXPORT_RTU"; }
    public static get ExportRtuBaseTemplate() { return "EXPORT_RTU_BASE_TEMPLATE"; }
    public static get ExportRtuData() { return "EXPORT_RTU_DATA"; }
    public static get ExportRtuTemplate() { return "EXPORT_RTU_TEMPLATE"; }
    public static get ExportServerCertificates() { return "EXPORT_SERVER_CERTIFICATES"; }
    public static get ImpersonateUser() { return "IMPERSONATE_USER"; }
    public static get ImportReports() { return "IMPORT_REPORTS"; }
    public static get ImportRtu() { return "IMPORT_RTU"; }
    public static get ImportRtuBaseTemplate() { return "IMPORT_RTU_BASE_TEMPLATE"; }
    public static get ImportRtuData() { return "IMPORT_RTU_DATA"; }
    public static get ImportRtuFile() { return "IMPORT_RTU_FILE"; }
    public static get ImportRtuTemplate() { return "IMPORT_RTU_TEMPLATE"; }
    public static get LockRtu() { return "LOCK_RTU"; }
    public static get LoginWeb() { return "LOGIN_WEB"; }
    public static get LogoutUser() { return "LOGOUT_USER"; }
    public static get ManualOverrideInput() { return "MANUAL_OVERRIDE_INPUT"; }
    public static get ManualOverrideStatus() { return "MANUAL_OVERRIDE_STATUS"; }
    public static get MultiCustSelect() { return "MULTI_CUST_SELECT"; }
    public static get PerformMaintenanceTasks() { return "PERFORM_MAINTENANCE_TASKS"; }
    public static get PerformRtuTemplateMaintenance() { return "PERFORM_RTU_TEMPLATE_MAINTENANCE"; }
    public static get PerformSystemUpgrade() { return "PERFORM_SYSTEM_UPGRADE"; }
    public static get ResendRtuConfig() { return "RESEND_RTU_CONFIG"; }
    public static get RestoreSystemBackups() { return "RESTORE_SYSTEM_BACKUPS"; }
    public static get RtuAssignCsm() { return "RTU_ASSIGN_CSM"; }
    public static get RunReports() { return "RUN_REPORTS"; }
    public static get SaveData() { return "SAVE_DATA"; }
    public static get SelectFromAllCustomers() { return "SELECT_FROM_ALL_CUSTOMERS"; }
    public static get SendTestMessage() { return "SEND_TEST_MESSAGE"; }
    public static get TopMenuDownload() { return "TOP_MENU_DOWNLOAD"; }
    public static get TopMenuEventRecordingsViewer() { return "TOP_MENU_EVENT_RECORDINGS_VIEWER"; }
    public static get TopMenuEventView() { return "TOP_MENU_EVENT_VIEW"; }
    public static get TopMenuMaintenanceView() { return "TOP_MENU_MAINTENANCE_VIEW"; }
    public static get TopMenuMaps() { return "TOP_MENU_MAPS"; }
    public static get TopMenuPqTrends() { return "TOP_MENU_PQ_TRENDS"; }
    public static get TopMenuReports() { return "TOP_MENU_REPORTS"; }
    public static get TopMenuSingleLineDiagrams() { return "TOP_MENU_SINGLE_LINE_DIAGRAMS"; }
    public static get TopMenuSystemConfiguration() { return "TOP_MENU_SYSTEM_CONFIGURATION"; }
    public static get TopMenuSystemOverview() { return "TOP_MENU_SYSTEM_OVERVIEW"; }
    public static get TopMenuSystemStatus() { return "TOP_MENU_SYSTEM_STATUS"; }
    public static get UploadCustomerFiles() { return "UPLOAD_CUSTOMER_FILES"; }
    public static get UploadRtuFiles() { return "UPLOAD_RTU_FILES"; }
    public static get VerifyUser() { return "VERIFY_USER"; }
    public static get ViewAddressBook() { return "VIEW_ADDRESS_BOOK"; }
    public static get ViewAggressiveCallIn() { return "VIEW_AGGRESSIVE_CALL_IN"; }
    public static get ViewAllUsers() { return "VIEW_ALL_USERS"; }
    public static get ViewArchivedEvents() { return "VIEW_ARCHIVED_EVENTS"; }
    public static get ViewCsmConfig() { return "VIEW_CSM_CONFIG"; }
    public static get ViewCustomer() { return "VIEW_CUSTOMER"; }
    public static get ViewCustomerFiles() { return "VIEW_CUSTOMER_FILES"; }
    public static get ViewCustomerMetrics() { return "VIEW_CUSTOMER_METRICS"; }
    public static get ViewCustomerAnnotations() { return "VIEW_CUSTOMER_NETWORK_ANNOTATIONS"; }
    public static get ViewCustomerNotes() { return "VIEW_CUSTOMER_NOTES"; }
    public static get ViewCustomerNotesDeleted() { return "VIEW_CUSTOMER_NOTES_DELETED"; }
    public static get ViewCustomerNotificationFormat() { return "VIEW_CUSTOMER_NOTIFICATION_FORMAT"; }
    public static get ViewCustomerSchedules() { return "VIEW_CUSTOMER_SCHEDULES"; }
    public static get ViewCustomerUserDataLabels() { return "VIEW_CUSTOMER_USER_DATA_LABELS"; }
    public static get ViewDiagnosticEvents() { return "VIEW_DIAGNOSTIC_EVENTS"; }
    public static get ViewEventStreamingServiceConfig() { return "VIEW_ESS_CONFIG"; }
    public static get ViewEventQueues() { return "VIEW_EVENT_QUEUES"; }
    public static get ViewEventRecordings() { return "VIEW_EVENT_RECORDINGS"; }
    public static get ViewEventRecordingsCalculator() { return "VIEW_EVENT_RECORDINGS_CALCULATOR"; }
    public static get ViewEventView() { return "VIEW_EVENT_VIEW"; }
    public static get ViewFilePaths() { return "VIEW_FILE_PATHS"; }
    public static get ViewHeatMaps() { return "VIEW_HEAT_MAPS"; }
    public static get ViewIconSets() { return "VIEW_ICON_SETS"; }
    public static get ViewIdentityProvider() { return "VIEW_IDENTITY_PROVIDER"; }
    public static get ViewLoadSurveyStudies() { return "VIEW_LOAD_SURVEY_STUDIES"; }
    public static get ViewLogic() { return "VIEW_LOGIC"; }
    public static get ViewMaps() { return "VIEW_MAPS"; }
    public static get ViewMessageApi() { return "VIEW_MESSAGE_API"; }
    public static get ViewMessageBrokers() { return "VIEW_MESSAGE_BROKERS"; }
    public static get ViewMessageQueue() { return "VIEW_MESSAGE_QUEUE"; }
    public static get ViewNetworkFaultNotifications() { return "VIEW_NETWORK_FAULT_NOTIFICATIONS"; }
    public static get ViewNetworkFault() { return "VIEW_NETWORK_FAULT"; }
    public static get ViewNetworks() { return "VIEW_NETWORKS"; }
    public static get ViewNotificationHistory() { return "VIEW_NOTIFICATION_HISTORY"; }
    public static get ViewNxDiagEvts() { return "VIEW_NX_DIAG_EVTS"; }
    public static get ViewPhpInfo() { return "VIEW_PHP_INFO"; }
    public static get ViewPQHeatMaps() { return "VIEW_PQ_HEAT_MAPS"; }
    public static get ViewPQMaps() { return "VIEW_PQ_MAPS"; }
    public static get ViewPriorities() { return "VIEW_PRIORITIES"; }
    public static get ViewReleaseNotes() { return "VIEW_RELEASE_NOTES"; }
    public static get ViewReports() { return "VIEW_REPORTS"; }
    public static get ViewRtuAiSettings() { return "VIEW_RTU_AI_SETTINGS"; }
    public static get ViewRtuAoSettings() { return "VIEW_RTU_AO_SETTINGS"; }
    public static get ViewRtuApiPassword() { return "VIEW_RTU_API_PASSWORD"; }
    public static get ViewRtuApplications() { return "VIEW_RTU_APPLICATIONS"; }
    public static get ViewRtuAssignedFirmware() { return "VIEW_RTU_ASSIGNED_FIRMWARE"; }
    public static get ViewRtuAutoPoll() { return "VIEW_RTU_AUTO_POLL"; }
    public static get ViewRtuBiLatched() { return "VIEW_RTU_BI_LATCHED"; }
    public static get ViewRtuBiSettings() { return "VIEW_RTU_BI_SETTINGS"; }
    public static get ViewRtuBoSettings() { return "VIEW_RTU_BO_SETTINGS"; }
    public static get ViewRtuCertificates() { return "VIEW_RTU_CERTIFICATES"; }
    public static get ViewRtuCiSettings() { return "VIEW_RTU_CI_SETTINGS"; }
    public static get ViewRtuCommsSettings() { return "VIEW_RTU_COMMS_SETTINGS"; }
    public static get ViewRtuConfigQ() { return "VIEW_RTU_CONFIG_Q"; }
    public static get ViewRtuDiSettings() { return "VIEW_RTU_DI_SETTINGS"; }
    public static get ViewRtuDnp() { return "VIEW_RTU_DNP"; }
    public static get ViewRtuDnpAdvanced() { return "VIEW_RTU_DNP_ADVANCED"; }
    public static get ViewRtuDtvi() { return "VIEW_RTU_DTVI"; }
    public static get ViewRtuEventPriorities() { return "VIEW_RTU_EVENT_PRIORITIES"; }
    public static get ViewRtuEventStatus() { return "VIEW_RTU_EVENT_STATUS"; }
    public static get ViewRtuFileLocations() { return "VIEW_RTU_FILE_LOCATIONS"; }
    public static get ViewRtuFiles() { return "VIEW_RTU_FILES"; }
    public static get ViewRtuGroups() { return "VIEW_RTU_GROUPS"; }
    public static get ViewRtuHiddenPoints() { return "VIEW_RTU_HIDDEN_POINTS"; }
    public static get ViewRtuIec104() { return "VIEW_RTU_IEC104"; }
    public static get ViewRtuLimits() { return "VIEW_RTU_LIMITS"; }
    public static get ViewRtuLogic() { return "VIEW_RTU_LOGIC"; }
    public static get ViewRtuMimics() { return "VIEW_RTU_MIMICS"; }
    public static get ViewRtuModbus() { return "VIEW_RTU_MODBUS"; }
    public static get ViewRtuMqtt() { return "VIEW_RTU_MQTT"; }
    public static get ViewRtuMqttPassword() { return "VIEW_RTU_MQTT_PASSWORD"; }
    public static get ViewRtuNetworkModelId() { return "VIEW_RTU_NETWORK_MODEL_ID"; }
    public static get ViewRtuNotes() { return "VIEW_RTU_NOTES"; }
    public static get ViewRtuNotesDeleted() { return "VIEW_RTU_NOTES_DELETED"; }
    public static get ViewRtuNotifications() { return "VIEW_RTU_NOTIFICATIONS"; }
    public static get ViewRtuOutputHistory() { return "VIEW_RTU_OUTPUT_HISTORY"; }
    public static get ViewRtuOverview() { return "VIEW_RTU_OVERVIEW"; }
    public static get ViewRtuPointGroups() { return "VIEW_RTU_POINT_GROUPS"; }
    public static get ViewRtuPointMeasurements() { return "VIEW_RTU_POINT_MEASUREMENTS"; }
    public static get ViewRtuRemoteAccess() { return "VIEW_RTU_REMOTE_ACCESS"; }
    public static get ViewRtuSel() { return "VIEW_RTU_SEL"; }
    public static get ViewRtuSetup() { return "VIEW_RTU_SETUP"; }
    public static get ViewRtuSiSettings() { return "VIEW_RTU_SI_SETTINGS"; }
    public static get ViewRtuSummaryStatConfig() { return "VIEW_RTU_SUMMARY_STAT_CONFIG"; }
    public static get ViewRtuTemplates() { return "VIEW_RTU_TEMPLATES"; }
    public static get ViewRtuTrafficMng() { return "VIEW_RTU_TRAFFIC_MNG"; }
    public static get ViewRtuUserData() { return "VIEW_RTU_USER_DATA"; }
    public static get ViewScadaChannel() { return "VIEW_SCADA_CHANNEL"; }
    public static get ViewScadaConfig() { return "VIEW_SCADA_CONFIG"; }
    public static get ViewShapeTemplates() { return "VIEW_SHAPE_TEMPLATES"; }
    public static get ViewSingleLineDiagrams() { return "VIEW_SINGLE_LINE_DIAGRAMS"; }
    public static get ViewSystemAlarm() { return "VIEW_SYSTEM_ALARM"; }
    public static get ViewSystemBackups() { return "VIEW_SYSTEM_BACKUPS"; }
    public static get ViewSystemCertificates() { return "VIEW_SYSTEM_CERTIFICATES"; }
    public static get ViewSystemConfigurationPage() { return "VIEW_SYSTEM_CONFIGURATION_PAGE"; }
    public static get ViewSystemLimits() { return "VIEW_SYSTEM_LIMITS"; }
    public static get ViewSystemLog() { return "VIEW_SYSTEM_LOG"; }
    public static get ViewSystemLogNotifications() { return "VIEW_SYSTEM_LOG_NOTIFICATIONS"; }
    public static get ViewSystemMetrics() { return "VIEW_SYSTEM_METRICS"; }
    public static get ViewSystemServiceLogs() { return "VIEW_SYSTEM_SERVICE_LOGS"; }
    public static get ViewSystemStatus() { return "VIEW_SYSTEM_STATUS"; }
    public static get ViewSystemSummary() { return "VIEW_SYSTEM_SUMMARY"; }
    public static get ViewTmConfig() { return "VIEW_TM_CONFIG"; }
    public static get ViewTrends() { return "VIEW_TRENDS"; }
    public static get ViewUserAccess() { return "VIEW_USER_ACCESS"; }
    public static get ViewUserAccessToken() { return "VIEW_USER_ACCESS_TOKEN"; }
    public static get ViewUserApps() { return "VIEW_USER_APPS"; }
    public static get ViewUserDetailsPage() { return "VIEW_USER_DETAILS_PAGE"; }
    public static get ViewUserGroup() { return "VIEW_USER_GROUP"; }
    public static get ViewUserLoginHistory() { return "VIEW_USER_LOGIN_HISTORY"; }
    public static get ViewUserOptionsPage() { return "VIEW_USER_OPTIONS_PAGE"; }
    public static get ViewUsersPage() { return "VIEW_USERS_PAGE"; }
    public static get ViewVirtualRtu() { return "VIEW_VIRTUAL_RTU"; }

    /**
     * Does the user have the given permission(s).
     * @param userPermissions The permissions the user has
     * @param permissions The permission(s) you want to check the user has
     * @returns True if user has all permissions requested
     */
    public static hasPermission(userPermissions: string[], permissions: string | string[]): boolean {
        if (userPermissions !== void 0 && userPermissions.length > 0 && permissions !== void 0) {
            if (typeof permissions === "string") {
                permissions = [permissions];
            }
            if (permissions.length > 0) {
                return permissions.every((permission) => {
                    return userPermissions.some((userPermission) => userPermission === permission);
                });
            }
        }

        return false;
    }

    /**
     * Does the user have any of given permission(s).
     * @param userPermissions The permissions the user has
     * @param permissions The permission(s) you want to check the user has
     * @returns True if user has any permission requested
     */
    public static hasAnyPermission(userPermissions: string[], permissions: string[]): boolean {
        return userPermissions?.length > 0 && permissions?.length > 0 && permissions.some(x => userPermissions.some(y => x === y));
    }
}
